import { HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiArrowsClockwise } from 'react-icons/pi'
import { Link } from 'react-router-dom'

import RecurringReportConfigurationDelete from './recurringReportConfigurationDelete'

import RecurringReportConfigurationRunButton from '@app/pages/reports/recurring/components/recurringReportConfigurationRunButton'
import type { RecurringReportConfiguration } from '@graphql/types'

type Config = Pick<RecurringReportConfiguration, 'id' | 'name' | 'scheduleDisplay'>

type ListItemProps = {
  recurringReportConfiguration: Config
}

type ListProps = {
  recurringReportConfigurations: Config[]
}

const DrawerListItem: FC<ListItemProps> = ({ recurringReportConfiguration }) => {
  const { id, name, scheduleDisplay } = recurringReportConfiguration

  return (
    <Stack px={4} py={3} spacing={1}>
      <HStack>
        <Icon as={PiArrowsClockwise} />
        <Stack spacing={0}>
          <Text as={Link} to={`recurring/${id}/edit`} variant="section-header">
            {name}
          </Text>
          <Text color="subtle" fontSize="sm">
            {scheduleDisplay}
          </Text>
        </Stack>
        <Spacer />
        <Menu>
          <MenuButton fontSize="sm" aria-label="Additional actions">
            ...
          </MenuButton>
          <MenuList>
            <RecurringReportConfigurationRunButton recurringReportConfigurationId={id} />
            <MenuItem>
              <Link to={`recurring/${id}`}>View recurring report...</Link>
            </MenuItem>
            <MenuItem as={Link} to={`recurring/${id}/edit`}>
              Edit recurring report...
            </MenuItem>
            <MenuItem>
              <RecurringReportConfigurationDelete recurringReportConfigurationId={id} />
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Stack>
  )
}

const DrawerList: FC<ListProps> = ({ recurringReportConfigurations }) => (
  <Stack>
    {recurringReportConfigurations.map((config) => (
      <DrawerListItem key={`recurring-report-list-item-${config.id}`} recurringReportConfiguration={config} />
    ))}
  </Stack>
)

export default DrawerList
