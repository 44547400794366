import type { FC } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

type Props = Partial<SelectInputProps>

const ServiceSelect: FC<Props> = (props) => (
  <SelectInput label="Action" name="service" placeholder={null} {...props}>
    <option value={RecurringReportConfigurationServiceEnum.EmitReport}>Create a report</option>
    <option value={RecurringReportConfigurationServiceEnum.SendChangelog}>Send a changelog</option>
    <option value={RecurringReportConfigurationServiceEnum.SendGoals}>Send key results</option>
  </SelectInput>
)

export default ServiceSelect
