import { Show, Stack, VStack, Link as ChakraLink, Text, useColorModeValue } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import pluralize from 'pluralize'
import type { FC } from 'react'
import { memo, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import ContainedEntityCard from '@app/pages/roadmaps/cards/containedEntityCard'
import { MIN_COLUMN_WIDTH } from '@app/pages/roadmaps/shared'
import { UPDATE_TASK_STATE_FORMAT } from '@app/pages/roadmaps/useInteractions'
import { useStore } from '@app/store'
import type { DomainObject, Entity } from '@graphql/types'

interface TaskColumnProps {
  roadmapItemId: string
  container: DomainObject
  tasks: Partial<Entity>[]
  state: string
  expanded?: boolean
}

const TaskColumn: FC<TaskColumnProps> = ({ roadmapItemId, container, tasks, state, expanded = true }) => {
  const { roadmapItemId: roadmapItemIdFromParams } = useParams()
  const { user } = useStoreCurrentUser()
  // const { onDragOver, onDrop, onDragEnter, onDragLeave, onDragEnd } = useInteractions()
  const editor = ['admin', 'editor'].includes(user?.role)
  const rowHighlightColor = useColorModeValue('blue.50', 'blue.800')
  const updateObject = useStore.use.updateObject()

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    if (event.dataTransfer.getData(UPDATE_TASK_STATE_FORMAT)) {
      const task = JSON.parse(event.dataTransfer.getData(UPDATE_TASK_STATE_FORMAT))
      // @ts-expect-error - TS doesn't know about closest
      const workflowState = event.target.closest('.task-column')?.getAttribute('data-state')

      // Workflow state is what we use on the frontend, foreignState is what we use on the backend
      // @ts-expect-error - Workflow state v.s. foreignState issue
      updateObject({ entity: { id: task.id, workflowState } }, true)
      updateObject({ entity: { id: task.id, foreignState: workflowState } })
    }
  }, [])

  const onDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  const onDragEnd = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  if (!expanded) {
    return (
      <Stack
        mr={1}
        p={4}
        borderColor="border.emphasized"
        bgColor={roadmapItemIdFromParams === roadmapItemId ? rowHighlightColor : 'bg.surface'}
      >
        <Text color="fg.subtle" fontSize="sm" fontWeight="semibold">
          {tasks.length} work {pluralize('item', tasks.length)}
        </Text>
      </Stack>
    )
  }

  return (
    <VStack
      className="task-column"
      align="start"
      minW={MIN_COLUMN_WIDTH}
      mr={1}
      p={4}
      borderColor="border.emphasized"
      bgColor={roadmapItemIdFromParams === roadmapItemId ? rowHighlightColor : 'bg.surface'}
      data-state={state}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      spacing={3}
    >
      <Show below="lg">
        <Text fontSize="md" fontWeight="semibold">
          {capitalize(state)}
        </Text>
      </Show>
      {tasks.map((task) => (
        <ContainedEntityCard
          container={container}
          entity={task}
          key={task.id}
          _hover={{ cursor: editor ? 'grab' : 'default' }}
          _grabbed={{ cursor: editor ? 'grabbing' : 'default' }}
          draggable={editor}
          zIndex={1}
          onDragStart={(event) => {
            event.dataTransfer.setData(UPDATE_TASK_STATE_FORMAT, JSON.stringify(task))
            // eslint-disable-next-line no-param-reassign
            event.dataTransfer.effectAllowed = 'move'
          }}
        />
      ))}
      {editor && (
        <ChakraLink
          as={Link}
          color="fg.subtle"
          fontSize="sm"
          fontWeight="semibold"
          _hover={{ color: 'fg.muted' }}
          to={`${roadmapItemId}/add/entity`}
        >
          + Add a work items
        </ChakraLink>
      )}
    </VStack>
  )
}

export default memo(TaskColumn)
