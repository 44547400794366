import { Box, Divider, Flex, IconButton, Spacer, Stack, Tooltip } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useMemo, useEffect, useRef } from 'react'
import { PiArrowLeftBold } from 'react-icons/pi'
import { Link, useParams } from 'react-router-dom'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import MessageContent from '@app/pages/maps/components/aiChat/components/messageContent'
import MessageGroup from '@app/pages/maps/components/aiChat/components/messageGroup'
import PromptForm from '@app/pages/maps/components/aiChat/components/promptForm'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import type { MapDomainMessage } from '@app/types'
import type { AiAssistantRoleEnum, PipelineEnum } from '@graphql/types'

type Props = {
  pipelineId: PipelineEnum
  chatId?: string
  strategyId?: string
}

type MessageGrouping = [
  string,
  string,
  AiAssistantRoleEnum,
  Pick<MapDomainMessage, 'id' | 'classType' | 'content' | 'role' | 'userId' | 'createdAt'>[]
]
const Show: FC<Props> = ({ pipelineId, chatId: propChatId = null, strategyId: propStrategyId = null }) => {
  const { chatId: paramChatId, strategyId: paramStrategyId } = useParams()
  const chatId = propChatId || paramChatId
  const strategyId = propStrategyId || paramStrategyId
  const storeMessages = useGetObjectsByProperties('message', { chatId })
  const messages = useMemo(
    () => sortBy(storeMessages, 'createdAt').filter((message) => message.content),
    [storeMessages]
  )
  const scrollRef = useRef(null)

  // condense messages from the same user
  const messageGroups = useMemo(
    () =>
      messages.reduce<MessageGrouping[]>((acc, message) => {
        const [lastKey, lastUserId, lastRole, lastMessages] = acc[acc.length - 1] || []

        if (
          lastMessages &&
          ((lastRole === 'user' && message.role === 'user' && lastUserId === message.userId) ||
            (lastRole === 'assistant' && message.role === 'assistant'))
        ) {
          acc[acc.length - 1] = [lastKey, lastUserId, lastRole, [...lastMessages, message]]
        } else {
          acc.push([message.id, message.userId, message.role, [message]])
        }

        return acc
      }, []),
    [messages]
  )

  // scroll to the bottom of the list when the messages list changes
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [messages.length])

  return (
    <Flex pos="relative" direction="column" overflow="hidden" h="100vh" bg="bg.canvas">
      <Flex p={2}>
        <Tooltip label="Go back">
          <IconButton
            as={Link}
            aria-label="Go back"
            colorScheme="gray"
            icon={<PiArrowLeftBold />}
            to=".."
            variant="ghost"
          />
        </Tooltip>
        <Spacer />
        <DrawerCloseButton variant="ghost" />
      </Flex>
      <Box ref={scrollRef} overflowY="auto" pt="0" pb="40">
        <Stack
          maxW="prose"
          mx="auto"
          px={{ base: '4', md: '2' }}
          divider={
            <Box ml="14!">
              <Divider />
            </Box>
          }
          spacing="10"
        >
          {messageGroups?.map(([groupKey, userId, role, contents]) => (
            <MessageGroup
              key={groupKey}
              userId={userId}
              role={role}
              messages={contents.map((message) => [
                message.id,
                message,
                <MessageContent key={message.id} message={message} />
              ])}
            />
          ))}
        </Stack>
      </Box>

      <Box pos="absolute" bottom="0" mx="4" py="8" bgGradient="linear(to-t, bg.canvas 80%, rgba(0,0,0,0))" insetX="0">
        <Stack maxW="prose" mx="auto">
          <PromptForm pipelineId={pipelineId} chatId={chatId} strategyId={strategyId} />
        </Stack>
      </Box>
    </Flex>
  )
}

export default Show
