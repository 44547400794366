import type { GridItemProps } from '@chakra-ui/react'
import {
  Collapse,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  useColorModeValue
} from '@chakra-ui/react'
import camelCase from 'lodash/camelCase'
import type { FC } from 'react'
import { memo, useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { PiDotsThreeVerticalBold } from 'react-icons/pi'
import { useParams } from 'react-router-dom'

import MetricsColumn from './metricsColumn'

import EntityContainmentsFetcher from '@app/fetchers/entityContainmentsFetcher'
import useGetNeighbors from '@app/hooks/useGetNeighbors'
import useGetNode from '@app/hooks/useGetNode'
import useGetObject from '@app/hooks/useGetObject'
import useGetObjectsByIds from '@app/hooks/useGetObjectsByIds'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import BasicCard from '@app/pages/roadmaps/cards/basicCard'
import EntityCard from '@app/pages/roadmaps/cards/entityCard'
import MetricCard from '@app/pages/roadmaps/cards/metricCard'
import StrategyCard from '@app/pages/roadmaps/cards/strategyCard'
import RoadmapItemDeleteButton from '@app/pages/roadmaps/roadmapItemDeleteButton'
import { MIN_COLUMN_WIDTH } from '@app/pages/roadmaps/shared'
import { REORDER_ROADMAP_ITEM_FORMAT } from '@app/pages/roadmaps/useInteractions'
import WorkColumns from '@app/pages/roadmaps/workColumns'
import type { BoundedStateShared, StorageState } from '@app/store/types'
import type {
  BasicCard as BasicCardType,
  Entity,
  Metric,
  RoadmapItem,
  RoadmapItemDomainObject,
  Strategy
} from '@graphql/types'

interface ItemActionsProps {
  roadmapItemId: string
  expanded?: boolean
  setExpanded?: (expanded: boolean) => void
}

const ItemActions: FC<ItemActionsProps> = ({ roadmapItemId, expanded = true, setExpanded = () => {} }) => {
  const { strategyId } = useParams()

  return (
    <HStack spacing={0}>
      <Menu>
        <MenuButton as={IconButton} icon={<Icon as={PiDotsThreeVerticalBold} />} variant="ghost" />
        <Portal>
          <MenuList>
            <RoadmapItemDeleteButton strategyId={strategyId} roadmapItemId={roadmapItemId} />
          </MenuList>
        </Portal>
      </Menu>
      <IconButton
        aria-label="expand/collapse"
        icon={<Icon as={expanded ? FiChevronUp : FiChevronDown} />}
        onClick={() => setExpanded(!expanded)}
        size="xs"
        variant="ghost"
      />
    </HStack>
  )
}

interface ItemProps extends GridItemProps {
  domainObject: RoadmapItemDomainObject
  roadmapItemId: string
  expanded?: boolean
  setExpanded?: (expanded: boolean) => void
}

const Item: FC<ItemProps> = ({ domainObject, roadmapItemId, expanded = true, setExpanded = () => {}, ...rest }) => {
  const { user } = useStoreCurrentUser()
  const editor = ['admin', 'editor'].includes(user?.role)

  const actionsChildren = editor ? (
    <ItemActions roadmapItemId={roadmapItemId} expanded={expanded} setExpanded={setExpanded} />
  ) : null

  switch (domainObject.classType) {
    case 'basicCard':
      return <BasicCard domainObject={domainObject as BasicCardType} actionsChildren={actionsChildren} {...rest} />
    case 'entity':
      return <EntityCard domainObject={domainObject as Entity} actionsChildren={actionsChildren} {...rest} />
    case 'metric':
      return <MetricCard domainObject={domainObject as Metric} actionsChildren={actionsChildren} {...rest} />
    case 'strategy':
      return <StrategyCard domainObject={domainObject as Strategy} actionsChildren={actionsChildren} {...rest} />
    default:
      return null
  }
}

interface RoadmapItemRowProps {
  roadmapItem: RoadmapItem
}

const onDragStart = (event, object) => {
  event.dataTransfer.setData(REORDER_ROADMAP_ITEM_FORMAT, JSON.stringify(object))
  // eslint-disable-next-line no-param-reassign
  event.dataTransfer.effectAllowed = 'move'
}

// RoadmapItemRow component to display a single roadmap item row
const RoadmapItemRow: FC<RoadmapItemRowProps> = ({ roadmapItem }) => {
  const { user } = useStoreCurrentUser()
  const [expanded, setExpanded] = useState(true)
  const { roadmapItemId } = useParams<{ roadmapItemId: string }>()
  const { domainObjectId, domainObjectType } = roadmapItem
  const nodeType = camelCase(domainObjectType) as keyof BoundedStateShared | keyof StorageState
  const domainObject = useGetObject(domainObjectId, nodeType) as RoadmapItemDomainObject
  const rowHighlightColor = useColorModeValue('blue.50', 'blue.800')
  const editor = ['admin', 'editor'].includes(user?.role)

  const node = useGetNode(domainObject.rfId)
  const neighbors = useGetNeighbors(node)
  const metricNodes = neighbors.filter((n) => n.type === 'metric')
  const metricIds = metricNodes.map((metricNode) => metricNode.id.substring(1))
  const metrics = useGetObjectsByIds('metric', metricIds)

  return (
    <Grid
      className="roadmap-item-row"
      templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(6, 1fr)' }}
      data-roadmap-item-class-type={roadmapItem.domainObjectType}
      data-roadmap-item-id={roadmapItem.domainObjectId}
      data-roadmap-item-position={roadmapItem.position}
    >
      <GridItem
        zIndex={1}
        minW={MIN_COLUMN_WIDTH}
        p={4}
        _hover={{ cursor: editor ? 'grab' : 'default' }}
        _grabbed={{ cursor: editor ? 'grabbing' : 'default' }}
        bgColor={roadmapItemId === roadmapItem.id ? rowHighlightColor : 'bg.subtle'}
        draggable={editor}
        onDragStart={(e) => onDragStart(e, roadmapItem)}
      >
        <Collapse in={expanded} startingHeight={76}>
          <Item
            domainObject={domainObject}
            roadmapItemId={roadmapItem.id}
            colSpan={1}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        </Collapse>
      </GridItem>
      <EntityContainmentsFetcher nodeId={domainObjectId} nodeType={nodeType}>
        {(_collection, _metadata) => <WorkColumns expanded={expanded} roadmapItem={roadmapItem} />}
      </EntityContainmentsFetcher>
      <MetricsColumn expanded={expanded} metrics={metrics} />
    </Grid>
  )
}

export default memo(RoadmapItemRow)
