import { Box, Center, Grid, GridItem, Stack, Text, Hide, Divider } from '@chakra-ui/react'
import type { FC } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import RoadmapItemRow from './roadmapItemRow'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import AddRoadmapItemMenu from '@app/pages/roadmaps/addRoadmapItemMenu'
import { MIN_COLUMN_WIDTH } from '@app/pages/roadmaps/shared'
import useInteractions from '@app/pages/roadmaps/useInteractions'
import EmptyState from '@app/shared/emptyState/emptyState'
import { COLOR_MAP } from '@app/utils/iconMap'

type HeaderStates = 'Unstarted' | 'Started' | 'Reviewing' | 'Finished' | 'Metrics / Inputs'

interface StateHeaderGridItemProps {
  state: HeaderStates
  color: string
}

const StateHeaderGridItem: FC<StateHeaderGridItemProps> = ({ state, color }) => (
  <GridItem minW={MIN_COLUMN_WIDTH} mr={1} p={2} bg={color} borderTopRadius="md" colSpan={1}>
    <Text color="white" fontWeight="semibold">
      {state}
    </Text>
  </GridItem>
)

const Home: FC = () => {
  const { strategyId } = useParams()
  const { user } = useStoreCurrentUser()
  const roadmapItems = useGetObjectsByProperties('roadmapItem', { strategyId })
  const { onDragOver, onDrop, onDragEnter, onDragLeave, onDragEnd } = useInteractions()

  const editor = ['admin', 'editor'].includes(user?.role)
  const stickyTop = editor ? '72px' : '0'
  const stickyPadding = editor ? '0' : '4'

  return (
    <Box overflow="hidden" w="100%" h="100%" bgColor="bg.muted">
      <Box
        overflow="auto"
        minW="100%"
        h="100%"
        px={4}
        pb={4}
        onDragEnd={onDragEnd}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        {editor && (
          <Center pos="sticky" zIndex="sticky" top={0} py={4} bgColor="bg.muted">
            <Stack direction={{ base: 'column', md: 'row' }}>
              <Text pt={1} color="fg.muted" fontSize="lg" fontWeight="semibold">
                Add new swimlane:
              </Text>
              <AddRoadmapItemMenu />
            </Stack>
          </Center>
        )}
        <Hide below="lg">
          <Grid pos="sticky" zIndex="sticky" top={stickyTop} templateColumns="repeat(6, 1fr)" pt={stickyPadding}>
            <GridItem minW={MIN_COLUMN_WIDTH} colSpan={1} />
            <StateHeaderGridItem state="Unstarted" color={`${COLOR_MAP.WorkflowStateUnstarted}.500`} />
            <StateHeaderGridItem state="Started" color={`${COLOR_MAP.WorkflowStateStarted}.500`} />
            <StateHeaderGridItem state="Reviewing" color={`${COLOR_MAP.WorkflowStateReviewing}.500`} />
            <StateHeaderGridItem state="Finished" color={`${COLOR_MAP.WorkflowStateFinished}.500`} />
            <StateHeaderGridItem state="Metrics / Inputs" color="gray.500" />
          </Grid>
        </Hide>
        <Box>
          {roadmapItems.length === 0 && (
            <EmptyState
              mt={6}
              title="No roadmap items"
              description="Add or create a swimlane to start planning your strategy."
            />
          )}
          {roadmapItems
            .sort((a, b) => a.position - b.position)
            .map((roadmapItem, index) => (
              <Box key={roadmapItem.id}>
                {index > 0 && <Divider borderColor="border.emphasized" />}
                <RoadmapItemRow roadmapItem={roadmapItem} />
              </Box>
            ))}
        </Box>
      </Box>
      <Outlet />
    </Box>
  )
}

export default Home
