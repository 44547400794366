import { Box, Flex, Icon, IconButton, Stack, Text } from '@chakra-ui/react'
import type { UploadResult, UppyEventMap, UppyOptions } from '@uppy/core'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useMemo, useState, useCallback } from 'react'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { MAX_DOUBLELOOP_UPLOADER_FILE_SIZE } from '@app/lib/globals'
import useToast from '@app/shared/toast'
import type { RemoteFile } from '@app/shared/uploader/doubleloopUploader'
import DoubleloopUploaderModal from '@app/shared/uploader/doubleloopUploaderModal'
import type { FileTicketMeta } from '@app/shared/uploader/generateUploaderTicketPlugin'
import type { MapDomainStrategy } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import {
  useAiAssistantVectorStoreFilesAddMutation,
  useAiAssistantVectorStoreFilesRemoveMutation
} from '@graphql/queries'

const uppyOptions: UppyOptions = {
  restrictions: {
    maxFileSize: MAX_DOUBLELOOP_UPLOADER_FILE_SIZE,
    // https://platform.openai.com/docs/assistants/tools/file-search/supported-files
    allowedFileTypes: [
      '.c',
      '.cpp',
      '.cs',
      '.css',
      '.doc',
      '.docx',
      '.html',
      '.java',
      '.js',
      '.json',
      '.md',
      '.pdf',
      '.php',
      '.pptx',
      '.py',
      '.rb',
      '.sh',
      '.tex',
      '.ts',
      '.txt'
    ]
  }
}

const FileButton = ({ strategyId, file, ...rest }) => {
  const [, deleteFile] = useAiAssistantVectorStoreFilesRemoveMutation()
  const [isLoading, setIsLoading] = useState(false)
  const onClick = useCallback(async () => {
    setIsLoading(true)
    try {
      await deleteFile({ input: { strategyId, fileIds: [file.id] } })
    } finally {
      setIsLoading(false)
    }
  }, [file.id])

  return (
    <Flex align="center" display="inline-flex" {...rest}>
      <Text fontSize="sm" variant="outline">
        {file.name}
      </Text>
      <IconButton
        ms={2}
        aria-label={`Delete ${file.name}`}
        icon={<Icon as={ICON_MAP.Delete} />}
        isLoading={isLoading}
        onClick={onClick}
        size="sm"
        variant="outline"
      />
    </Flex>
  )
}

type Props = {
  strategy: MapDomainStrategy
}

const AiAssistantFiles: FC<Props> = ({ strategy }) => {
  const toast = useToast()
  const files = useGetObjectsByProperties('aiAssistantFile', {
    strategyId: strategy.id,
    uploadedToStrategyContext: true
  })
  const [, addFiles] = useAiAssistantVectorStoreFilesAddMutation()
  const onComplete: UppyEventMap<FileTicketMeta>['complete'] = useCallback(
    async (result: UploadResult<FileTicketMeta, RemoteFile>) => {
      try {
        addFiles({
          input: {
            strategyId: strategy.id,
            fileIds: result.successful.map((file) => file.response.body.id)
          }
        })
      } catch (e) {
        toast({
          status: 'error',
          title: 'Failed to upload files',
          description: e.message
        })
      }
    },
    []
  )

  const sortedFiles = useMemo(() => sortBy(files, 'name'), [files])

  return (
    <Stack spacing={2}>
      <Box>
        {sortedFiles.map((file) => (
          <FileButton key={file.id} file={file} strategyId={strategy.id} me={2} mb={2} />
        ))}
      </Box>
      <DoubleloopUploaderModal onComplete={onComplete} uppyOptions={uppyOptions}>
        <IconButton
          aria-label="Upload files"
          colorScheme="gray"
          icon={<Icon as={ICON_MAP.Upload} />}
          size="sm"
          type="button"
          variant="text"
        />
      </DoubleloopUploaderModal>
    </Stack>
  )
}

export default AiAssistantFiles
