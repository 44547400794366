import { Box, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react'
import { Panel } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'

import ButtonWrapper from './nav/buttonWrapper'

import AiChatMenu from '@app/pages/maps/components/aiChat/components/aiChatMenu'
import PopulateFromPlaybookTemplatePopover from '@app/pages/maps/components/map/ai/populateFromPlaybookTemplatePopover'
import CopyMapToDoubleLoopButton from '@app/pages/maps/components/map/copyMapToDoubleLoopButton'
import MapCardFilters from '@app/pages/maps/components/map/filters/cardFilters/mapCardFilters'
import MapDateFilters from '@app/pages/maps/components/map/filters/dateFilters/mapDateFilters'
import MapSegmentFilters from '@app/pages/maps/components/map/filters/segmentFilters/mapSegmentFilters'
import MapExpandAllNodes from '@app/pages/maps/components/map/mapExpandAllNodes'
import ZoomButtons from '@app/pages/maps/components/map/nav/zoomButtons'
import ToggleMetricData from '@app/pages/maps/components/map/toggleMetricData'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import Can from '@app/shared/authorization/can'
import ICON_MAP from '@app/utils/iconMap'

type Props = {
  strategyId: string
}
const MapFiltering: FC<Props> = ({ strategyId }) => {
  const { canEdit } = usePermissionsContext()

  return (
    <Panel position="bottom-right">
      <ButtonWrapper>
        <HStack p={1}>
          <Can I="view" a="beta">
            <Tooltip hasArrow label={<Text>AI Assistants</Text>}>
              <Box>
                <AiChatMenu strategyId={strategyId} />
              </Box>
            </Tooltip>
          </Can>
          <Can I="view" an="admin">
            <Tooltip hasArrow label={<Text>Apply Playbook Template</Text>}>
              <Box>
                <PopulateFromPlaybookTemplatePopover strategyId={strategyId}>
                  <IconButton
                    aria-label="Apply Playbook Template"
                    colorScheme="gray"
                    icon={<ICON_MAP.PlaybookTemplate />}
                    size="2xs"
                    variant="ghost"
                  />
                </PopulateFromPlaybookTemplatePopover>
              </Box>
            </Tooltip>
            <Tooltip hasArrow label={<Text>Copy map to DoubleLoop Organization</Text>}>
              <CopyMapToDoubleLoopButton strategyId={strategyId} colorScheme="gray" variant="ghost" size="2xs" />
            </Tooltip>
          </Can>
          <MapSegmentFilters strategyId={strategyId} canEdit={canEdit} />
          <MapCardFilters canEdit={canEdit} />
          <MapExpandAllNodes />
          <MapDateFilters strategyId={strategyId} canEdit={canEdit} />
          <ToggleMetricData strategyId={strategyId} />
          <ZoomButtons />
        </HStack>
      </ButtonWrapper>
    </Panel>
  )
}

MapFiltering.displayName = 'MapFiltering'

export default memo(MapFiltering)
