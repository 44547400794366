import type { StackProps } from '@chakra-ui/react'
import { HStack, Spacer, Stack } from '@chakra-ui/react'
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown'
import type { LexicalEditor } from 'lexical'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import RichTextInput from '@app/next/forms/inline/richTextInput'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import TextAreaInput from '@app/next/forms/inline/textAreaInput'
import type { MapDomainReport } from '@app/types'

type Props = StackProps & {
  report: MapDomainReport
}

const ReportContent: FC<Props> = ({ report, ...rest }) => {
  const summary = report?.summary
  const summaryRef = useRef<LexicalEditor>(null)

  // Update the summary when it changes in the background, so AI pipelines can update it.
  useEffect(() => {
    if (
      summary?.length > 0 &&
      summaryRef.current?.isEditable() &&
      // do not update if the editor is focused, as it makes editing it inline nearly impossible.
      summaryRef.current.getRootElement() !== document.activeElement
    ) {
      summaryRef.current.update(() => {
        $convertFromMarkdownString(summary, TRANSFORMERS)
      })
    }
  }, [summary])

  return (
    <Stack px={8} pb={4} bgColor="bg.surface" {...rest}>
      <Stack mb={3} pb={2} borderBottom="1px" borderBottomColor="border.default">
        <HStack>
          <TextAreaInput
            domainObject={report}
            name="name"
            title={report.name}
            fontWeight="bold"
            wordBreak="break-word"
            fontSize="2xl"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur()
              }
            }}
          />
          <Spacer />
        </HStack>

        <ViewerFieldWrapper value={report?.summary}>
          <RichTextInput domainObject={report} name="summary" placeholder="Summary..." ref={summaryRef} />
        </ViewerFieldWrapper>
      </Stack>
    </Stack>
  )
}

export default ReportContent
