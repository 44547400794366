import { Container, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Navbar from '@app/shared/layout/shells/components/navbar'
import Sidebar from '@app/shared/layout/shells/components/sidebar'
import GraphqlSubscriptionShell from '@app/shared/layout/shells/graphqlSubscriptionShell'
import MultiAccountBanner from '@app/shared/multiAccountBanner'

interface AppShellProps {
  children?: ReactNode
}

const AppShell = ({ children }: AppShellProps) => {
  const { user } = useStoreCurrentUser()
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false })
  const accountsCount = user?.accountsCount || 0

  let nav = <Navbar />
  let breakpointColumns = '1fr'
  let breakpointRows = '1fr'
  let breakpointTemplateAreas = accountsCount > 1 ? '"header" "nav" "main"' : '"nav" "main"'

  if (user?.accountsCount > 1) {
    breakpointRows = '40px 1fr'
  }

  if (isDesktop) {
    nav = <Sidebar />
    breakpointColumns = '320px 1fr'
    breakpointTemplateAreas = accountsCount > 1 ? '"header header" "nav main"' : '"nav main"'
  }

  return (
    <GraphqlSubscriptionShell strategyId={null}>
      <Grid
        rowGap={0}
        columnGap={0}
        templateRows={breakpointRows}
        templateColumns={breakpointColumns}
        templateAreas={breakpointTemplateAreas}
        overflow="hidden"
        h="100%"
        bg="bg.muted"
      >
        <GridItem area="header">
          <MultiAccountBanner />
        </GridItem>
        <GridItem area="nav" overflow="hidden">
          {user && nav}
        </GridItem>
        <GridItem area="main" overflow="auto" h="100%" pb={4} id="main">
          <Container>{children || <Outlet />}</Container>
        </GridItem>
      </Grid>
    </GraphqlSubscriptionShell>
  )
}

export default AppShell
