import { Avatar, Box, HStack, Stack, Text } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type { MapDomainMessage } from '@app/types'
import profileImage from '@app/utils/profileImage'

type Props = Pick<MapDomainMessage, 'role'> & {
  userId: string | null
  messages: [string, MapDomainMessage, ReactNode][]
}

const ASSISTANT = {
  name: 'Assistant',
  email: null
}

const MessageGroup: FC<Props> = ({ role, userId, messages }) => {
  const propUser = useGetObject(userId, 'user')
  const user = role === ('assistant' as const) ? ASSISTANT : propUser
  const timestamp = messages[messages.length - 1]?.[1]?.updatedAt
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()
  const dateTime = timestamp ? formatDateTimeInUserTimeZone(timestamp, 'MMMM do, yyy h:mm aaa') : null

  return (
    <HStack align="flex-start" gap="5">
      <Box pt="1">
        <Avatar name={user?.name} size="sm" src={profileImage(user?.email)} />
      </Box>
      <Stack spacing="1">
        <Text fontWeight="medium">{user?.name}</Text>
        <Stack spacing="2">
          {messages.map(([id, _, node]) => (
            <Box key={id} color="fg.muted" lineHeight="tall">
              {node}
            </Box>
          ))}
        </Stack>
        {dateTime && (
          <Text color="fg.subtle" fontSize="xs">
            {dateTime}
          </Text>
        )}
      </Stack>
    </HStack>
  )
}

export default MessageGroup
