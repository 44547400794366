import type { RouteObject } from 'react-router-dom'
import { defer } from 'react-router-dom'

import MapEducationDrawer from '@app/pages/maps/components/learn/mapEducationDrawer'
import PreviewModal from '@app/pages/maps/components/map/previewModal'
import AddBasicCardDrawer from '@app/pages/roadmaps/addBasicCardDrawer'
import AddCardDrawer from '@app/pages/roadmaps/addCardDrawer'
import AddMetricCardDrawer from '@app/pages/roadmaps/addMetricCardDrawer'
import Home from '@app/pages/roadmaps/home'
import RoadmapDrawer from '@app/pages/roadmaps/roadmapDrawer'
import { basicCardShowTabRoutes } from '@app/routes/basicCards'
import { entityShowTabRoutes, workShowTabRoutes } from '@app/routes/events'
import queryExistingObjects from '@app/routes/lib/queryExistingObjects'
import { metricShowTabRoutes } from '@app/routes/metrics'
import { useStore } from '@app/store'
import unpack from '@app/store/unpack'
import { requiresAuthorization } from '@app/utils/auth'
import { loaderQuery } from '@graphql/client'
import { Sources } from '@graphql/documents/integration.graphql'
import { RoadmapItems } from '@graphql/documents/roadmap.graphql'
import type { RoadmapItemsQuery, RoadmapItemsQueryVariables } from '@graphql/queries'

const loadRoadmap = async ({ params }) => {
  const { strategyId } = params
  const { bulkAdd } = useStore.getState()
  const variables = { strategyId }

  const result = await loaderQuery<RoadmapItemsQuery, RoadmapItemsQueryVariables>(RoadmapItems, variables)
  const roadmapItems = result?.data?.roadmapItems

  // This feels like it should be going through normalizer instead of unpack.
  // Currently the way normalize unpacks and the bulkAdd function are not compatible.
  const unpacked = roadmapItems.map((roadmapItem) => unpack(roadmapItem)).flat()
  bulkAdd(unpacked)

  return { roadmapItems }
}

// TODO: This should be moved to a shared location, it's shared here and maps.tsx
const loadIntegrationInfo = async () => {
  const IntegrationInfoPromise = loaderQuery(Sources)

  return defer({ integrationInfo: IntegrationInfoPromise })
}

const routes: RouteObject = {
  path: 'roadmap',
  element: <Home />,
  loader: async (loaderProps) => {
    await requiresAuthorization('view', 'legacy')

    return loadRoadmap(loaderProps)
  },
  children: [
    {
      path: 'learn',
      element: <MapEducationDrawer />
    },
    {
      path: ':roadmapItemId',
      children: [
        {
          path: 'add',
          action: ({ request }) => {
            switch (request.method) {
              case 'POST':
                return queryExistingObjects(request)
              default:
                return null
            }
          },
          children: [
            {
              path: ':type',
              loader: loadIntegrationInfo,
              element: <AddCardDrawer />
            }
          ]
        }
      ]
    },
    {
      path: 'add',
      action: ({ request }) => {
        switch (request.method) {
          case 'POST':
            return queryExistingObjects(request)
          default:
            return null
        }
      },
      children: [
        {
          path: 'basicCard/:cardTypeId?',
          loader: loadIntegrationInfo,
          element: <AddBasicCardDrawer />
        },
        {
          path: 'metric',
          loader: loadIntegrationInfo,
          element: <AddMetricCardDrawer />
        },
        {
          path: ':type',
          loader: loadIntegrationInfo,
          element: <AddCardDrawer />
        }
      ]
    },
    {
      path: 'metric',
      loader: () => ({
        nodeType: 'metric'
      }),
      element: <RoadmapDrawer />,
      children: metricShowTabRoutes('roadmap')
    },
    {
      path: 'entity',
      loader: () => ({
        nodeType: 'entity'
      }),
      element: <RoadmapDrawer />,
      children: entityShowTabRoutes('roadmap')
    },
    {
      path: 'work',
      loader: () => ({
        nodeType: 'work'
      }),
      element: <RoadmapDrawer />,
      children: workShowTabRoutes('roadmap')
    },
    {
      path: 'basicCard',
      loader: () => ({
        nodeType: 'basicCard'
      }),
      element: <RoadmapDrawer />,
      children: basicCardShowTabRoutes('roadmap')
    },
    {
      path: 'preview/:submapId',
      element: <PreviewModal />
    }
  ]
}

export default routes
