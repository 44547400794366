import type { BoxProps } from '@chakra-ui/react'
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
  Stack,
  StackDivider,
  Portal
} from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { PiDotsThreeVerticalBold } from 'react-icons/pi'
import { useNavigate, useParams } from 'react-router-dom'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import TextAreaInput from '@app/next/forms/inline/textAreaInput'
import OwnerPopover from '@app/next/ownerPopover'
import ContainmentDeleteButton from '@app/pages/roadmaps/cards/containmentDeleteButton'
import type { MapDomainObject } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'
import type { DomainObject, Entity, EntityWorkflowStateEnum } from '@graphql/types'

interface Props extends BoxProps {
  container: DomainObject
  entity: Partial<Entity>
}

const borderColors: Record<EntityWorkflowStateEnum, string> = {
  not_started: `${COLOR_MAP.WorkflowStateUnstarted}.500`,
  started: `${COLOR_MAP.WorkflowStateStarted}.500`,
  reviewing: `${COLOR_MAP.WorkflowStateReviewing}.500`,
  finished: `${COLOR_MAP.WorkflowStateFinished}.500`
}

const ContainedEntityCard: FC<Props> = ({ container, entity, ...rest }) => {
  const { user } = useStoreCurrentUser()
  const { strategyId } = useParams()
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const navigate = useNavigate()
  const route = `/strategy/${strategyId}/roadmap/entity/${entity.id}`
  const editor = ['admin', 'editor'].includes(user?.role)

  return (
    <Stack
      key={entity.id}
      borderWidth={2}
      borderColor="border.default"
      borderBottomColor={borderColors[entity.workflowState]}
      borderRadius="md"
      shadow="md"
      divider={<StackDivider borderColor="border.default" />}
      {...rest}
      w="100%"
      p={2}
    >
      <HStack alignItems="flex-start">
        <TextAreaInput
          domainObject={entity as MapDomainObject}
          name="name"
          title={entity.name}
          fontWeight="semibold"
          wordBreak="break-word"
          pt={1}
          pb={0}
          fontSize="sm"
          color="fg.muted"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur()
            }
          }}
        />
        {editor && (
          <>
            <Spacer />
            <Menu>
              <MenuButton as={IconButton} icon={<Icon as={PiDotsThreeVerticalBold} />} variant="ghost" />
              <Portal>
                <MenuList>
                  <ContainmentDeleteButton container={container} entityId={entity.id} />
                </MenuList>
              </Portal>
            </Menu>
          </>
        )}
      </HStack>
      <HStack>
        <OwnerPopover object={entity as DomainObject} />
        {!isAnonymousUser && (
          <>
            <Spacer />
            <Button
              color="fg.subtle"
              onClick={() => navigate(route)}
              rightIcon={<Icon as={ICON_MAP.ExternalLink} boxSize={4} color="gray.500" />}
              size="xs"
              variant="ghost"
            >
              Details
            </Button>
          </>
        )}
      </HStack>
    </Stack>
  )
}

export default memo(ContainedEntityCard)
