import { Box, type ChakraProps, StylesProvider, useColorModeValue, useMultiStyleConfig } from '@chakra-ui/react'
import type { Node as NodeType } from '@xyflow/react'
import { type FC, memo, useCallback, useEffect, useRef, useState } from 'react'

import useGetNode from '@app/hooks/useGetNode'
import NodeResizer from '@app/pages/maps/components/nodes/components/nodeResizer'
import Can from '@app/shared/authorization/can'
import { Card } from '@app/shared/cards/components'
import { CardProvider } from '@app/shared/cards/contexts/cardContext'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import { RichInput } from '@app/shared/rawForms'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import { useStore } from '@app/store'

const NoteNode: FC<NodeType> = (rfNode) => {
  const { id, selected: nodeSelected } = rfNode

  const selected = nodeSelected === undefined ? false : nodeSelected
  const node = useGetNode(id)

  const nodeRef = useRef()
  const notesRef = useRef()

  const styles = useMultiStyleConfig('Form', {})
  const updateObject = useStore.use.updateObject()

  const [editingWithoutRef, setEditingWithoutRef] = useState(false)
  const editingRef = useRef(editingWithoutRef)
  const editing = editingRef.current
  const setEditing = (data: boolean) => {
    editingRef.current = data
    setEditingWithoutRef(data)
  }

  const backgroundColorWithoutEditing = useColorModeValue('yellow.50', 'yellow.500')
  const borderColorWithoutEditing = useColorModeValue('yellow.400', 'yellow.700')

  // used to focus the input when the node is double clicked and place the caret at the end
  const focusInputAndPlaceCaretAtEnd = (element: HTMLElement) => {
    element.focus()
    const range = document.createRange()
    range.selectNodeContents(element)
    range.collapse(false)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  }

  const toggleEditMode = useCallback((enabled: boolean) => {
    setEditing(enabled)
  }, [])

  const saveChanges = useCallback(
    (text: string) => {
      toggleEditMode(false)

      if (notesRef.current) {
        updateObject({ note: { id: node.data.id, text } })
      }

      //! This is a hack to make sure the node is resized to fit the text
      // setTimeout(() => {
      //   updateNode(id, {
      //     height: (notesRef.current as HTMLElement).clientHeight + 40
      //   })
      // }, 0)
    },
    [node?.data?.id, toggleEditMode, updateObject]
  )

  const handleInputBlur = (event: KeyboardEventInit) => {
    if (event.key === 'Enter' && !event.shiftKey && editingRef.current) {
      const textAreaValue =
        nodeRef.current && (nodeRef.current as HTMLElement).getElementsByTagName('textarea')[0].value

      saveChanges(textAreaValue)
    }
  }

  useEffect(() => {
    const editor = document.getElementsByClassName('public-DraftEditor-content')[0]

    if (editor) {
      focusInputAndPlaceCaretAtEnd(editor as HTMLElement)
    }
  }, [editing])

  useEffect(() => {
    document.addEventListener('keydown', handleInputBlur)

    return () => {
      document.removeEventListener('keydown', handleInputBlur)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!selected && editing) {
      const textAreaValue =
        nodeRef.current && (nodeRef.current as HTMLElement).getElementsByTagName('textarea')[0].value

      saveChanges(textAreaValue)
    }
  }, [editing, id, selected, toggleEditMode, notesRef, saveChanges])

  if (!node || !node.data) {
    return null
  }

  const { data: note, height, width } = node

  // const { width, height } = useStoreRF((s) => {
  //   const nodeInternal = s.nodeInternals.get(id)
  //
  //   return {
  //     width: nodeInternal.width,
  //     height: nodeInternal.height
  //   }
  // })

  const backgroundColor = !editing ? backgroundColorWithoutEditing : 'transparent'
  const clearStyleOnEditMode = editing ? { boxShadow: 'none', padding: 0 } : {}

  const borderWidth = '2px'
  const borderColor = selected && !editing ? 'blue.500' : borderColorWithoutEditing
  const hoverBorder = !editing && { borderColor: 'blue.500' }
  const placeholderText = 'Double click to edit'
  const widthPadding = 53

  const cardStyle = {
    backgroundColor,
    style: clearStyleOnEditMode,
    borderWidth,
    borderColor,
    _hover: hoverBorder,
    width,
    height,
    borderRadius: 0,
    boxShadow: 'lg'
  }
  const markdownStyle: ChakraProps = {
    pos: 'absolute',
    px: 4,
    py: 2,
    maxW: width - widthPadding,
    opacity: note.text?.length > 0 ? 1 : 0.5,
    visibility: editing ? 'hidden' : 'visible',
    color: '#333'
  }

  return (
    <>
      <Can I="update" a="strategy">
        {!editing && (
          <NodeResizer strategyId={node.strategyId} nodeId={id} isVisible={selected} baseZoom={15} maxZoom={25} />
        )}
      </Can>
      <Card
        onClick={() => {}}
        onDoubleClick={() => {
          toggleEditMode(true)
        }}
        {...cardStyle}
      >
        <Box ref={notesRef} {...markdownStyle}>
          <MarkdownDisplay text={note.text === '' ? placeholderText : note.text} />
        </Box>
        {editing && (
          <StylesProvider value={styles}>
            <Box border="2px" borderColor="blue.500" borderRadius="md">
              <Box
                ref={nodeRef}
                color="black"
                border="5px"
                borderColor="yellow.100"
                borderRadius="md"
                bgColor="yellow.50"
              >
                <RichInput name="text" defaultValue={note.text || ''} _hover={{ cursor: 'text' }} className="nodrag" />
              </Box>
            </Box>
          </StylesProvider>
        )}
      </Card>
    </>
  )
}

export default memo(withBasicProvider(CardProvider)(NoteNode))
