import { Box, ButtonGroup, Button, Divider, Hide, HStack, Icon, Text, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { Link, NavLink } from 'react-router-dom'

import Settings from './nav/settings'

import useGetObject from '@app/hooks/useGetObject'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import PlaybookCreateButton from '@app/pages/maps/components/playbookCreateButton'
import { useDrawerContext } from '@app/pages/maps/drawerContext'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import Can from '@app/shared/authorization/can'
import FavoriteToggleButton from '@app/shared/favorites/favoriteToggleButton'
import { ToggleButton } from '@app/shared/layout/shells/components/toggleButton'
import ICON_MAP from '@app/utils/iconMap'
import type { Strategy } from '@graphql/types'

interface Props {
  strategyId: string
  embedded?: boolean
}

const Nav: FC<Props> = ({ strategyId, embedded = false }) => {
  const { canEdit } = usePermissionsContext()
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const { isDrawerOpen, onDrawerToggle } = useDrawerContext()
  const strategy = useGetObject(strategyId, 'strategy')

  if (!strategy) {
    return null
  }

  const { id, favoriteId } = strategy

  const stackSpacing = { base: 2, md: 4 }

  const viewButtonProps = {
    as: NavLink,
    color: 'border.active',
    fontWeight: '500',
    _activeLink: { color: 'fg.muted' }
  }

  return (
    <Box zIndex="modal" px={{ base: 2, md: 3 }} py={{ base: 1, md: 2 }} bg="bg.surface" borderRadius="md" shadow="sm">
      <HStack spacing={stackSpacing}>
        <HStack flexGrow={0} spacing={1}>
          <ToggleButton
            fontSize="lg"
            variant="ghost"
            isOpen={isDrawerOpen}
            aria-label="Open Menu"
            onClick={onDrawerToggle}
            show={!isAnonymousUser}
          />
          <Text as={Link} defaultValue={strategy?.name} size="sm" to="map/settings">
            {strategy?.name}
          </Text>
        </HStack>
        {!embedded && (
          <HStack flexGrow={1} flexShrink={0}>
            <Divider h="1.5rem" mx={2} orientation="vertical" />
            <ButtonGroup size="sm" spacing={{ base: 3, md: 6 }} variant="text">
              <Button
                {...viewButtonProps}
                aria-label="Map canvas"
                leftIcon={<Icon as={ICON_MAP.StrategyMap} />}
                to="map"
              >
                Canvas
              </Button>
              <Button
                {...viewButtonProps}
                aria-label="Key results"
                leftIcon={<Icon as={ICON_MAP.OKR} />}
                to="key-results"
              >
                Key results
              </Button>
              <Button
                {...viewButtonProps}
                aria-label="Changelog"
                leftIcon={<Icon as={ICON_MAP.Changelog} />}
                to="changelog"
              >
                Changelog
              </Button>
              <Can I="view" a="beta">
                <Button
                  {...viewButtonProps}
                  aria-label="Reports"
                  leftIcon={<Icon as={ICON_MAP.StrategyReport} />}
                  to="map/reports"
                >
                  Reports
                </Button>
              </Can>
              <Can I="view" a="legacy">
                <Tooltip label="Roadmap">
                  <Button
                    {...viewButtonProps}
                    aria-label="Roadmap"
                    leftIcon={<Icon as={ICON_MAP.Roadmap} />}
                    to="roadmap"
                  >
                    Roadmap
                  </Button>
                </Tooltip>
              </Can>
            </ButtonGroup>
          </HStack>
        )}
        <HStack spacing={1}>
          <Hide below="md">
            {/*  <IconButton */}
            {/*    {...viewButtonProps} */}
            {/*    aria-label="Map Education" */}
            {/*    colorScheme="gray" */}
            {/*    icon={<Icon as={ICON_MAP.MapEducation} />} */}
            {/*    to={location.pathname.endsWith('/learn') ? location.pathname : `${location.pathname}/learn`} */}
            {/*    variant="ghost" */}
            {/*  /> */}
            <FavoriteToggleButton
              favoritableType="Strategy"
              favoritableId={id}
              favoriteId={favoriteId}
              iconBoxSize={5}
              show={!isAnonymousUser}
            />
            <PlaybookCreateButton strategy={strategy} show={canEdit} />
            <Settings strategy={strategy as Strategy} show={canEdit} />
          </Hide>
        </HStack>
      </HStack>
    </Box>
  )
}

export default memo(Nav)
