export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * Represents non-fractional signed whole numeric values. Since the value may
   * exceed the size of a 32-bit integer, it's encoded as a string.
   */
  BigInt: { input: any; output: any; }
  /** A boolean that can be casted-to from multiple types */
  CastableBoolean: { input: any; output: any; }
  /**
   * Handle conversion from comma-separated string to array. Intended for input
   * convenience, to avoid having to map HTML input values into arrays.
   */
  CommaSeparatedString: { input: any; output: any; }
  /** An recurrence schedule in ICal format */
  ICalSchedule: { input: any; output: any; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  /** An ISO 8601-encoded duration */
  ISO8601Duration: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
  /** Convenience type to handle Integers that are passed as Strings */
  ToInt: { input: any; output: any; }
};

/** A single account object */
export type Account = ClassTypeInterface & {
  __typename?: 'Account';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** If applicable, date and time user was disabled at */
  disabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** ID of object */
  id: Scalars['ID']['output'];
  /** Name of the account user */
  name?: Maybe<Scalars['String']['output']>;
  /** Associated Organization */
  organization?: Maybe<Organization>;
  /** ID of the associated organization */
  organizationId: Scalars['String']['output'];
  /** Name of the associated organization */
  organizationName: Scalars['String']['output'];
  /** Account role */
  role: RoleEnum;
  /** Object last updated date */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Associated User */
  user?: Maybe<User>;
  /** ID of the associated user */
  userId: Scalars['String']['output'];
};

/** Autogenerated input type of AccountDelete */
export type AccountDeleteInput = {
  /** Account to delete */
  accountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AccountDelete. */
export type AccountDeletePayload = ClassTypeInterface & {
  __typename?: 'AccountDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** CRUD operation wrapper */
  crudOperation: TypesAccountTypeGraphqlCrudOperation;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of AccountSwitch */
export type AccountSwitchInput = {
  /** Account to switch to */
  accountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AccountSwitch. */
export type AccountSwitchPayload = ClassTypeInterface & {
  __typename?: 'AccountSwitchPayload';
  /** Updated account */
  account?: Maybe<Account>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated User */
  user?: Maybe<User>;
};

/** Autogenerated input type of AccountUpdate */
export type AccountUpdateInput = {
  /** Account to update */
  accountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Set a users disabled flag */
  disabled?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Set a users role */
  role?: InputMaybe<RoleEnum>;
};

/** Autogenerated return type of AccountUpdate. */
export type AccountUpdatePayload = ClassTypeInterface & {
  __typename?: 'AccountUpdatePayload';
  /** Updated account */
  account?: Maybe<Account>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated User */
  user?: Maybe<User>;
};

/** AI Assistant File */
export type AiAssistantFile = ClassTypeInterface & {
  __typename?: 'AiAssistantFile';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Our local ID for the file */
  id: Scalars['ID']['output'];
  /** Name of the file */
  name: Scalars['String']['output'];
  /** OpenAI remote ID */
  remoteId: Scalars['String']['output'];
  /** ID of Strategy whose data the file is based on */
  strategyId: Scalars['ID']['output'];
  /** Whether the file has been uploaded to the strategy context */
  uploadedToStrategyContext: Scalars['Boolean']['output'];
};

/** List of possible Role values on User */
export enum AiAssistantRoleEnum {
  /** Assistant - The User is an AI Assistant */
  Assistant = 'assistant',
  /** System role */
  System = 'system',
  /** User - The User is a regular User */
  User = 'user'
}

/** Autogenerated input type of AiAssistantVectorStoreFilesAdd */
export type AiAssistantVectorStoreFilesAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** OpenAI remote IDs to add */
  fileIds: Array<Scalars['ID']['input']>;
  /** ID of Strategy whose file cache is updated */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of AiAssistantVectorStoreFilesAdd. */
export type AiAssistantVectorStoreFilesAddPayload = ClassTypeInterface & {
  __typename?: 'AiAssistantVectorStoreFilesAddPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The strategy */
  strategy?: Maybe<Strategy>;
};

/** Autogenerated input type of AiAssistantVectorStoreFilesRemove */
export type AiAssistantVectorStoreFilesRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Files IDs to remove */
  fileIds: Array<Scalars['ID']['input']>;
  /** ID of Strategy whose file cache is updated */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of AiAssistantVectorStoreFilesRemove. */
export type AiAssistantVectorStoreFilesRemovePayload = ClassTypeInterface & {
  __typename?: 'AiAssistantVectorStoreFilesRemovePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The strategy */
  strategy?: Maybe<Strategy>;
};

/** An AI conversation */
export type AiConversation = ClassTypeInterface & {
  __typename?: 'AiConversation';
  /** The messages in the conversation */
  aiConversationMessages: Array<AiConversationMessage>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** The ID */
  id: Scalars['ID']['output'];
  /** The strategy for this conversation */
  strategy: Strategy;
};

/** A request & response pair, in an AI conversation */
export type AiConversationMessage = ClassTypeInterface & {
  __typename?: 'AiConversationMessage';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** The ID */
  id: Scalars['ID']['output'];
  /** The content of the request */
  requestContent: Scalars['String']['output'];
  /** The speaker's role for this message */
  requestRole: Scalars['String']['output'];
  /** The response content for the request */
  responseContent: Scalars['String']['output'];
  /** The responder's role for this message */
  responseRole: Scalars['String']['output'];
  /** Whether the response was successful */
  success: Scalars['Boolean']['output'];
};

/** List of Amplitude data storage regions */
export enum AmplitudeRegionEnum {
  /** EU - European Union */
  Eu = 'eu',
  /** US - United States */
  Us = 'us'
}

/** EntityAttachments */
export type Attachment = ClassTypeInterface & {
  __typename?: 'Attachment';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Associated Entity */
  entity: Entity;
  /** Associated entity ID (performs no additional queries) */
  entityId: Scalars['ID']['output'];
  /** File extension */
  extension: Scalars['String']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** File mime type */
  mimeType: Scalars['String']['output'];
  /** Original file name  */
  originalFilename: Scalars['String']['output'];
  /** URL to full attachment */
  url: Scalars['String']['output'];
};

/** Basic Card */
export type BasicCard = ClassTypeInterface & CommentableInterface & DomainObjectInterface & RfNodeInterface & {
  __typename?: 'BasicCard';
  /** Whether the Metric was generated by AI */
  aiGenerated?: Maybe<Scalars['Boolean']['output']>;
  /** Card Type of the Basic Card */
  cardType?: Maybe<CardType>;
  /** Card Type ID of the Basic Card */
  cardTypeId?: Maybe<Scalars['ID']['output']>;
  /** Card Type Name of the Basic Card */
  cardTypeName?: Maybe<Scalars['String']['output']>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Comments left on the object */
  comments?: Maybe<Array<Comment>>;
  /** Number of comments left on the object */
  commentsCount: Scalars['Int']['output'];
  /** Confidence rating of the object */
  confidenceRating?: Maybe<ConfidenceRatingEnum>;
  /** Number of work items contained by this Basic Card */
  containeeCount?: Maybe<Scalars['Int']['output']>;
  /** IDs of Basic Cards contained by this Basic Card */
  containeeIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description of the card */
  description?: Maybe<Scalars['String']['output']>;
  /** Direct collapsed incomers */
  directIncomers?: Maybe<Array<NodeObject>>;
  /** Due date for the Card */
  dueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** All of the upstream Entities */
  entities: EntityCollection;
  /** External URL of the Card */
  externalUrl?: Maybe<Scalars['String']['output']>;
  /** Text to display for the external URL */
  externalUrlTitle?: Maybe<Scalars['String']['output']>;
  /** Goal Hypothesis of the Card */
  goalHypothesis?: Maybe<Scalars['String']['output']>;
  /** ID of object */
  id: Scalars['ID']['output'];
  /** Labels */
  labels?: Maybe<Array<Label>>;
  /** Name for the Card Type */
  name?: Maybe<Scalars['String']['output']>;
  /** Organization owning the Basic Card */
  organization?: Maybe<Organization>;
  /** Owner of this Basic Card */
  owner?: Maybe<User>;
  /** Owner ID of this Basic Card */
  ownerId?: Maybe<Scalars['ID']['output']>;
  /** Whether the Basic Card has recent activity */
  recentActivity?: Maybe<Scalars['Boolean']['output']>;
  /** Results of the Card */
  results?: Maybe<Scalars['String']['output']>;
  /** Strategy node id */
  rfId: Scalars['String']['output'];
  /** Whether to show the description field */
  showDescription?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the goal_hypothesis field */
  showGoalHypothesis?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the results field */
  showResults?: Maybe<Scalars['Boolean']['output']>;
  /** Status of the Basic Card */
  status: DomainObjectStatusEnum;
  /** Strategies that the Basic Card is associated with */
  strategies: Array<Strategy>;
  /** Type of Metric relative to the Strategy.  */
  teamType?: Maybe<TeamTypeEnum>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Basic Card */
export type BasicCardDirectIncomersArgs = {
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Basic Card */
export type BasicCardEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** BasicCardCollection type */
export type BasicCardCollection = {
  __typename?: 'BasicCardCollection';
  /** A collection of paginated BasicCardCollection */
  collection: Array<BasicCard>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Input Type for Basic Card */
export type BasicCardCreateInput = {
  /** Whether the Basic Card was generated by AI */
  aiGenerated?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** ID of the CardType for this Basic Card */
  cardTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Basic Card description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The due_date for the card */
  dueDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Card external url */
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  /** Card external url text */
  externalUrlTitle?: InputMaybe<Scalars['String']['input']>;
  /** The expected result or hypothesis for the card */
  goalHypothesis?: InputMaybe<Scalars['String']['input']>;
  /** Basic Card ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Optional labels to set on the Basic Card */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Basic Card name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Basic Card */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** What result happened with the card */
  results?: InputMaybe<Scalars['String']['input']>;
  /** Whether to show the description field */
  showDescription?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the goal_hypothesis field */
  showGoalHypothesis?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the results field */
  showResults?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Status of the Basic Card */
  status?: InputMaybe<DomainObjectStatusEnum>;
  /** The type of card as it relates to Strategy. This is a terrible name */
  teamType?: InputMaybe<TeamTypeEnum>;
};

/** Autogenerated return type of BasicCardCreate. */
export type BasicCardCreatePayload = ClassTypeInterface & {
  __typename?: 'BasicCardCreatePayload';
  /** Newly created Basic Card */
  basicCard?: Maybe<BasicCard>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of BasicCardDelete */
export type BasicCardDeleteInput = {
  /** Basic Card ID to delete */
  basicCardId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BasicCardDelete. */
export type BasicCardDeletePayload = ClassTypeInterface & {
  __typename?: 'BasicCardDeletePayload';
  /** Deleted Basic Card */
  basicCard?: Maybe<BasicCard>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Input Type for Basic Card */
export type BasicCardInput = {
  /** Whether the Basic Card was generated by AI */
  aiGenerated?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** ID of the CardType for this Basic Card */
  cardTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Basic Card description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The due_date for the card */
  dueDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Card external url */
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  /** Card external url text */
  externalUrlTitle?: InputMaybe<Scalars['String']['input']>;
  /** The expected result or hypothesis for the card */
  goalHypothesis?: InputMaybe<Scalars['String']['input']>;
  /** Basic Card ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Optional labels to set on the Basic Card */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Basic Card name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Basic Card */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** What result happened with the card */
  results?: InputMaybe<Scalars['String']['input']>;
  /** Whether to show the description field */
  showDescription?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the goal_hypothesis field */
  showGoalHypothesis?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the results field */
  showResults?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Status of the Basic Card */
  status?: InputMaybe<DomainObjectStatusEnum>;
  /** The type of card as it relates to Strategy. This is a terrible name */
  teamType?: InputMaybe<TeamTypeEnum>;
};

export type BasicCardOrderingInput = {
  /** Sort by cardTypeName */
  cardTypeName?: InputMaybe<SortDirectionEnum>;
  /** Sort by name */
  name?: InputMaybe<SortDirectionEnum>;
};

/** Autogenerated input type of BillingCreateSubscription */
export type BillingCreateSubscriptionInput = {
  /** Billing period string */
  billingPeriod: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Billing plan id string */
  planId: Scalars['String']['input'];
};

/** Autogenerated return type of BillingCreateSubscription. */
export type BillingCreateSubscriptionPayload = ClassTypeInterface & {
  __typename?: 'BillingCreateSubscriptionPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Response from the billing provider */
  response: Scalars['JSON']['output'];
};

/** Autogenerated input type of BillingProvisionSubscription */
export type BillingProvisionSubscriptionInput = {
  /** Billing period string */
  billingPeriod: Scalars['String']['input'];
  /** URL to return to on checkout cancel */
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Billing plan id string */
  planId: Scalars['String']['input'];
  /** Number of seats */
  priceUnitAmount?: InputMaybe<Scalars['Float']['input']>;
  /** URL to return to on checkout success */
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BillingProvisionSubscription. */
export type BillingProvisionSubscriptionPayload = ClassTypeInterface & {
  __typename?: 'BillingProvisionSubscriptionPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Response from the billing provider */
  response: Scalars['JSON']['output'];
};

/** List of broadcast event names */
export enum BroadcastEventsEnum {
  /** DOMAIN_OBJECT_UPDATE */
  DomainObjectUpdate = 'DOMAIN_OBJECT_UPDATE',
  /** REVALIDATE */
  Revalidate = 'REVALIDATE'
}

/** Calculated Metric Input */
export type CalculatedMetricInput = ClassTypeInterface & {
  __typename?: 'CalculatedMetricInput';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Associated Metric */
  metric: Metric;
  /** Associated Metric ID */
  metricId: Scalars['ID']['output'];
  /** Associated MetricSource */
  metricSourceId?: Maybe<Scalars['ID']['output']>;
  /** Position in the calculation, 0 based */
  position: Scalars['Int']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Attributes for Calculated Metric Inputs */
export type CalculatedMetricInputInput = {
  /** Object unique ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Associated Metric */
  metricId: Scalars['ID']['input'];
  /** Associated MetricSource */
  metricSourceId?: InputMaybe<Scalars['ID']['input']>;
  /** Position in the calculation, 0 based */
  position: Scalars['ToInt']['input'];
};

/** Organization wide Card Types */
export type CardType = ClassTypeInterface & {
  __typename?: 'CardType';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Color for the Card Type */
  color?: Maybe<Scalars['String']['output']>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Icon for the Card Type */
  icon?: Maybe<Scalars['String']['output']>;
  /** ID of Card Type */
  id: Scalars['ID']['output'];
  /** Name of the Card Type */
  name?: Maybe<Scalars['String']['output']>;
  /** Organization owning the Card Type */
  organization?: Maybe<Organization>;
  /** Whether to show on the map menu or not */
  showInMenu: Scalars['Boolean']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Input for basic card types */
export type CardTypeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Card Type color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Card Type icon */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Card Type ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Card Type name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether to show on the map menu or not */
  showInMenu?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CardTypeCreate. */
export type CardTypeCreatePayload = ClassTypeInterface & {
  __typename?: 'CardTypeCreatePayload';
  /** Newly created CardType */
  cardType?: Maybe<CardType>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of CardTypeDelete */
export type CardTypeDeleteInput = {
  /** Card Type ID to delete */
  cardTypeId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CardTypeDelete. */
export type CardTypeDeletePayload = ClassTypeInterface & {
  __typename?: 'CardTypeDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** CRUD operation wrapper */
  crudOperation: TypesCardTypeTypeGraphqlCrudOperation;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Input for basic card types */
export type CardTypeInput = {
  /** Card Type color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Card Type icon */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Card Type ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Card Type name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether to show on the map menu or not */
  showInMenu?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input for basic card types */
export type CardTypeUpdateInput = {
  /** Card Type ID to update */
  cardTypeId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Card Type color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Card Type icon */
  icon?: InputMaybe<Scalars['String']['input']>;
  /** Card Type ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Card Type name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether to show on the map menu or not */
  showInMenu?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CardTypeUpdate. */
export type CardTypeUpdatePayload = ClassTypeInterface & {
  __typename?: 'CardTypeUpdatePayload';
  /** Updated Card Type */
  cardType?: Maybe<CardType>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** A chat thread, used as communication / memory / context in pipelines */
export type Chat = ClassTypeInterface & {
  __typename?: 'Chat';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Error message if the chat is in an error state */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** ID of the chat */
  id: Scalars['ID']['output'];
  /** Messages in the chat. System messages are not included. */
  messages: Array<Message>;
  /** Most recent user message */
  mostRecentPrompt?: Maybe<Message>;
  /** Slug of the pipeline that created the chat */
  pipelineSlug: Scalars['String']['output'];
  /** Current state of the chat */
  processedState: ChatProcessedStateEnum;
  /** ID of the strategy associated with the chat */
  strategyId?: Maybe<Scalars['ID']['output']>;
  /** Last updated timestamp */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of ChatCancel */
export type ChatCancelInput = {
  /** ID of the chat to cancel runs on. */
  chatId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ChatCancel. */
export type ChatCancelPayload = ClassTypeInterface & {
  __typename?: 'ChatCancelPayload';
  /** The chat */
  chat?: Maybe<Chat>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** AI Assistant Chat processing states */
export enum ChatProcessedStateEnum {
  /** Error - An error occurred during processing */
  Error = 'error',
  /** Idle - No processing is happening */
  Idle = 'idle',
  /** Processing - Chat is being processed */
  Processing = 'processing',
  /** Waiting - Chat is waiting on the system */
  Waiting = 'waiting'
}

/** Interface for storable objects */
export type ClassTypeInterface = {
  /** The class type of the field */
  classType: Scalars['String']['output'];
};

/** Classification */
export type Classification = ClassTypeInterface & {
  __typename?: 'Classification';
  /** Category name ex: Project */
  category?: Maybe<Scalars['String']['output']>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Classification name ex: Improve Performance */
  name?: Maybe<Scalars['String']['output']>;
  /** Associated Organization */
  organization: Organization;
  /** Source the classification is associated to */
  sourceName?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Type for CollectionMetadataType */
export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  /** Current Page of loaded data */
  currentPage: Scalars['Int']['output'];
  /** The number of items per page */
  limitValue: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  /** The total number of items to be paginated */
  totalCount: Scalars['Int']['output'];
  /** The total number of pages in the pagination */
  totalPages: Scalars['Int']['output'];
};

/** Comment */
export type Comment = ClassTypeInterface & {
  __typename?: 'Comment';
  /** Comment body */
  body: Scalars['String']['output'];
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Commentable ID */
  commentableId: Scalars['ID']['output'];
  /** Commentable type */
  commentableType: Scalars['String']['output'];
  /** Comment created at */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Comment creator */
  creator?: Maybe<User>;
  /** ID of the user that created the comment */
  creatorId?: Maybe<Scalars['ID']['output']>;
  /** Comment ID */
  id: Scalars['ID']['output'];
};

/** Input Type for Comments */
export type CommentCreateInput = {
  /** Comment body */
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Commentable ID */
  commentableId: Scalars['ID']['input'];
  /** Commentable type */
  commentableType: CommentableTypeEnum;
  /** Comment ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CommentCreate. */
export type CommentCreatePayload = ClassTypeInterface & {
  __typename?: 'CommentCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Newly created Comment */
  comment?: Maybe<Comment>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of CommentDelete */
export type CommentDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the comment */
  commentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CommentDelete. */
export type CommentDeletePayload = ClassTypeInterface & {
  __typename?: 'CommentDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Deleted Comment */
  comment?: Maybe<Comment>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Input Type for Comments */
export type CommentInput = {
  /** Comment body */
  body: Scalars['String']['input'];
  /** Commentable ID */
  commentableId: Scalars['ID']['input'];
  /** Commentable type */
  commentableType: CommentableTypeEnum;
  /** Comment ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Comment Thread */
export type CommentThread = ClassTypeInterface & CommentableInterface & DomainObjectInterface & RfNodeInterface & {
  __typename?: 'CommentThread';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Comments left on the object */
  comments?: Maybe<Array<Comment>>;
  /** Number of comments left on the object */
  commentsCount: Scalars['Int']['output'];
  /** Confidence rating of the object */
  confidenceRating?: Maybe<ConfidenceRatingEnum>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** User that created the comment */
  creator: User;
  /** ID of the user that created the comment */
  creatorId: Scalars['ID']['output'];
  /** Object global id */
  gid: Scalars['ID']['output'];
  /** ID of object */
  id: Scalars['ID']['output'];
  /** Organization owning the comment */
  organization: Organization;
  /** Strategy node id */
  rfId: Scalars['String']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Input Type for Comment Threads */
export type CommentThreadInput = {
  /** Comment Thread ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Objects that can be commented on */
export type CommentableInterface = {
  /** Comments left on the object */
  comments?: Maybe<Array<Comment>>;
  /** Number of comments left on the object */
  commentsCount: Scalars['Int']['output'];
};

/** Commentable types */
export enum CommentableTypeEnum {
  /** BasicCard */
  BasicCard = 'BasicCard',
  /** CommentThread */
  CommentThread = 'CommentThread',
  /** Entity */
  Entity = 'Entity',
  /** Metric */
  Metric = 'Metric',
  /** Strategy */
  Strategy = 'Strategy'
}

/** List of confidence ratings */
export enum ConfidenceRatingEnum {
  /** At Risk */
  AtRisk = 'at_risk',
  /** Off Track */
  OffTrack = 'off_track',
  /** On Track */
  OnTrack = 'on_track'
}

/** Contributor Types */
export type Contributor = SourceIdentity | User;

/** Correlation information between a pair of Metrics */
export type CorrelationPair = ClassTypeInterface & {
  __typename?: 'CorrelationPair';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** An ending date to limit the correlation to */
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** An ID based on the metrics, used in the frontend */
  id: Scalars['String']['output'];
  /** A symbolic date range to limit the data. Only applied if neither start_date nor end_date are set. */
  range?: Maybe<Scalars['String']['output']>;
  /** Correlation score */
  score?: Maybe<Scalars['Float']['output']>;
  /** Source metric */
  source: Metric;
  /** Source metric ID */
  sourceId: Scalars['ID']['output'];
  /** Source metric lag days */
  sourceLagDays: Scalars['Int']['output'];
  /** A starting date to limit the correlation to */
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Strategy ID */
  strategyId?: Maybe<Scalars['ID']['output']>;
  /** Target metric */
  target: Metric;
  /** Target metric ID */
  targetId: Scalars['ID']['output'];
  /** Target metric lag days */
  targetLagDays: Scalars['Int']['output'];
};

/** Input for Correlation Pair source / target IDs */
export type CorrelationPairIdInput = {
  /** ID of the source Metric */
  sourceId: Scalars['ID']['input'];
  /** ID of the target Metric */
  targetId: Scalars['ID']['input'];
};

/** Autogenerated input type of CorrelationPairUpdate */
export type CorrelationPairUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** An ending date to limit the correlation to */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A symbolic date range to limit the data. Only applied if neither start_date nor end_date are set. */
  range?: InputMaybe<Scalars['String']['input']>;
  /** ID of the source Metric */
  sourceId: Scalars['ID']['input'];
  /** number of days to lag the source Metric when correlating */
  sourceLagDays?: InputMaybe<Scalars['Int']['input']>;
  /** A starting date to limit the correlation to */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** ID of the target Metric */
  targetId: Scalars['ID']['input'];
  /** number of days to lag the target Metric when correlating */
  targetLagDays?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of CorrelationPairUpdate. */
export type CorrelationPairUpdatePayload = ClassTypeInterface & {
  __typename?: 'CorrelationPairUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated correlation pair */
  correlationPair?: Maybe<CorrelationPair>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Credential */
export type Credential = ClassTypeInterface & {
  __typename?: 'Credential';
  /** API Key for the Credential */
  apiKey?: Maybe<Scalars['String']['output']>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Client ID for the Credential */
  clientId?: Maybe<Scalars['String']['output']>;
  /** When the Credential was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Database name for the Credential */
  database?: Maybe<Scalars['String']['output']>;
  /** Display name for the Credential */
  displayName?: Maybe<Scalars['String']['output']>;
  /** File attached to the Credential */
  file?: Maybe<FileAttachment>;
  /** File's Shrine Hash for the Credential */
  fileData?: Maybe<Scalars['JSON']['output']>;
  /** Hostname for the Credential */
  host?: Maybe<Scalars['String']['output']>;
  /** Credential ID */
  id: Scalars['ID']['output'];
  /** Label for this Credential */
  label?: Maybe<Scalars['String']['output']>;
  /** Metrics associated with the Credential */
  metrics?: Maybe<Array<Metric>>;
  /** Port for the Credential */
  port?: Maybe<Scalars['String']['output']>;
  /** Project ID */
  projectId?: Maybe<Scalars['ID']['output']>;
  /** Role for the Credential */
  role?: Maybe<Scalars['String']['output']>;
  /** Schema name for the Credential */
  schema?: Maybe<Scalars['String']['output']>;
  /** Credential source */
  sourceName?: Maybe<Scalars['String']['output']>;
  /** When the Credential was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Username for the Credential */
  username?: Maybe<Scalars['String']['output']>;
  /** Warehouse name for the Credential */
  warehouse?: Maybe<Scalars['String']['output']>;
  /** Workspace ID */
  workspaceId?: Maybe<Scalars['ID']['output']>;
};

/** Input for Credentials */
export type CredentialAttributesTestInput = {
  /** API Key */
  apiKey?: InputMaybe<Scalars['String']['input']>;
  /** Client ID */
  clientId?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Secret */
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the credential to pull secret_fields from, for fields that are specified as such */
  credentialId?: InputMaybe<Scalars['ID']['input']>;
  /** Database name */
  database?: InputMaybe<Scalars['String']['input']>;
  /** File Data Hash */
  file?: InputMaybe<Scalars['JSON']['input']>;
  /** Host name */
  host?: InputMaybe<Scalars['String']['input']>;
  /** A label that can be used when displaying in a list etc. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Port */
  port?: InputMaybe<Scalars['String']['input']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['String']['input']>;
  /** Role */
  role?: InputMaybe<Scalars['String']['input']>;
  /** Schema name */
  schema?: InputMaybe<Scalars['String']['input']>;
  /**
   * Comma-separated list of fields to pull from the credential, if they are not
   * included / blank in the input. Implies a non-nil credential_id. When
   * specifying, use the snake_case variant of the field name
   */
  secretFields?: InputMaybe<Scalars['String']['input']>;
  /** Secret Key */
  secretKey?: InputMaybe<Scalars['String']['input']>;
  /** The name of the source */
  sourceName: Scalars['String']['input'];
  /** Username */
  username?: InputMaybe<Scalars['String']['input']>;
  /** Warehouse name */
  warehouse?: InputMaybe<Scalars['String']['input']>;
  /** Workspace ID */
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CredentialAttributesTest. */
export type CredentialAttributesTestPayload = ClassTypeInterface & {
  __typename?: 'CredentialAttributesTestPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The array of errors */
  errors?: Maybe<Array<Error>>;
  /** Whether the test was successful */
  success: Scalars['Boolean']['output'];
};

/** Input for Credentials */
export type CredentialCreateInput = {
  /** API Key */
  apiKey?: InputMaybe<Scalars['String']['input']>;
  /** Client ID */
  clientId?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Secret */
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  /** Database name */
  database?: InputMaybe<Scalars['String']['input']>;
  /** File Data Hash */
  file?: InputMaybe<Scalars['JSON']['input']>;
  /** Host name */
  host?: InputMaybe<Scalars['String']['input']>;
  /** A label that can be used when displaying in a list etc. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Port */
  port?: InputMaybe<Scalars['String']['input']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['String']['input']>;
  /** Role */
  role?: InputMaybe<Scalars['String']['input']>;
  /** Schema name */
  schema?: InputMaybe<Scalars['String']['input']>;
  /** Secret Key */
  secretKey?: InputMaybe<Scalars['String']['input']>;
  /** Name of the source */
  sourceName: Scalars['String']['input'];
  /** Username */
  username?: InputMaybe<Scalars['String']['input']>;
  /** Warehouse name */
  warehouse?: InputMaybe<Scalars['String']['input']>;
  /** Workspace ID */
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CredentialCreate. */
export type CredentialCreatePayload = ClassTypeInterface & {
  __typename?: 'CredentialCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The updated Credential */
  credential?: Maybe<Credential>;
  /** Errors encountered while updating the Credential */
  errors: Array<Error>;
};

/** Autogenerated input type of CredentialDelete */
export type CredentialDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Credential to delete */
  credentialId: Scalars['ID']['input'];
};

/** Autogenerated return type of CredentialDelete. */
export type CredentialDeletePayload = ClassTypeInterface & {
  __typename?: 'CredentialDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Deleted Credential */
  credential: Credential;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of CredentialTest */
export type CredentialTestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Credential to test */
  credentialId: Scalars['ID']['input'];
};

/** Autogenerated return type of CredentialTest. */
export type CredentialTestPayload = ClassTypeInterface & {
  __typename?: 'CredentialTestPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The credential */
  credential: Credential;
  /** The array of errors */
  errors?: Maybe<Array<Error>>;
  /** Whether the test was successful */
  success: Scalars['Boolean']['output'];
};

/** Input for Credentials */
export type CredentialUpdateInput = {
  /** API Key */
  apiKey?: InputMaybe<Scalars['String']['input']>;
  /** Client ID */
  clientId?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Client Secret */
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  /** Credential ID to update */
  credentialId: Scalars['ID']['input'];
  /** Database name */
  database?: InputMaybe<Scalars['String']['input']>;
  /** File Data Hash */
  file?: InputMaybe<Scalars['JSON']['input']>;
  /** Host name */
  host?: InputMaybe<Scalars['String']['input']>;
  /** A label that can be used when displaying in a list etc. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Port */
  port?: InputMaybe<Scalars['String']['input']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['String']['input']>;
  /** Role */
  role?: InputMaybe<Scalars['String']['input']>;
  /** Schema name */
  schema?: InputMaybe<Scalars['String']['input']>;
  /** Secret Key */
  secretKey?: InputMaybe<Scalars['String']['input']>;
  /** Name of the source */
  sourceName?: InputMaybe<Scalars['String']['input']>;
  /** Username */
  username?: InputMaybe<Scalars['String']['input']>;
  /** Warehouse name */
  warehouse?: InputMaybe<Scalars['String']['input']>;
  /** Workspace ID */
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CredentialUpdate. */
export type CredentialUpdatePayload = ClassTypeInterface & {
  __typename?: 'CredentialUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The updated Credential */
  credential?: Maybe<Credential>;
  /** Errors encountered while updating the Credential */
  errors: Array<Error>;
};

/** enum of CRUD operations */
export enum CrudOperationEnum {
  /** Delete */
  Delete = 'delete',
  /** Upsert */
  Upsert = 'upsert'
}

/** Paid subscription data */
export type CustomerSubscription = ClassTypeInterface & {
  __typename?: 'CustomerSubscription';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** End Date of the Subscription */
  endDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Stigg ID of the Subscription */
  id: Scalars['ID']['output'];
  /** Start Date of the Subscription */
  startDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Status of the Subscription */
  status: CustomerSubscriptionStatusEnum;
  /** End Date of the free Trial period */
  trialEndDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Enumeration of possible statuses for Customer Subscriptions */
export enum CustomerSubscriptionStatusEnum {
  /** Active subscription */
  Active = 'ACTIVE',
  /** Canceled subscription */
  Canceled = 'CANCELED',
  /** Expired subscription */
  Expired = 'EXPIRED',
  /** Trial subscription */
  InTrial = 'IN_TRIAL',
  /** Unpaid subscription */
  NotStarted = 'NOT_STARTED',
  /** Pending subscription */
  PaymentPending = 'PAYMENT_PENDING'
}

/** Dimensions */
export type DimensionsInput = {
  /** Height */
  height: Scalars['Int']['input'];
  /** Width */
  width: Scalars['Int']['input'];
};

/** List of possible Display Format values on Metric */
export enum DisplayFormatEnum {
  /** eur formatting */
  Eur = 'eur',
  /** gbp formatting */
  Gbp = 'gbp',
  /** hours formatting */
  Hours = 'hours',
  /** minutes formatting */
  Minutes = 'minutes',
  /** number formatting */
  Number = 'number',
  /** percent formatting */
  Percent = 'percent',
  /** seconds formatting */
  Seconds = 'seconds',
  /** usd formatting */
  Usd = 'usd'
}

/** Domain Object Types */
export type DomainObject = AiAssistantFile | BasicCard | CardType | Chat | Comment | CommentThread | Edge | Entity | Favorite | GoogleIntegration | Integration | JiraIntegration | MapImage | Message | Metric | MetricSource | Node | Note | RecurringReportConfiguration | Report | Section | Strategy | User;

/** Input Type for Labeling Domain Objects */
export type DomainObjectBulkLabelInput =
  /** BasicCard */
  { basicCard: Array<Scalars['ID']['input']>; entity?: never; metric?: never; strategy?: never; }
  |  /** Entity */
  { basicCard?: never; entity: Array<Scalars['ID']['input']>; metric?: never; strategy?: never; }
  |  /** Metric */
  { basicCard?: never; entity?: never; metric: Array<Scalars['ID']['input']>; strategy?: never; }
  |  /** Strategy */
  { basicCard?: never; entity?: never; metric?: never; strategy: Array<Scalars['ID']['input']>; };

/** Input Type for Deleting Domain Objects */
export type DomainObjectDeleteInput =
  /** Basic Card */
  { basicCard: Array<Scalars['ID']['input']>; chat?: never; entity?: never; existing?: never; integration?: never; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Chat */
  { basicCard?: never; chat: Array<Scalars['ID']['input']>; entity?: never; existing?: never; integration?: never; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Entity */
  { basicCard?: never; chat?: never; entity: Array<Scalars['ID']['input']>; existing?: never; integration?: never; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Existing Node */
  { basicCard?: never; chat?: never; entity?: never; existing: Array<Scalars['ID']['input']>; integration?: never; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Existing Integration */
  { basicCard?: never; chat?: never; entity?: never; existing?: never; integration: Array<Scalars['ID']['input']>; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Map Image */
  { basicCard?: never; chat?: never; entity?: never; existing?: never; integration?: never; mapImage: Array<Scalars['ID']['input']>; metric?: never; note?: never; recurringReportConfiguration?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Metric */
  { basicCard?: never; chat?: never; entity?: never; existing?: never; integration?: never; mapImage?: never; metric: Array<Scalars['ID']['input']>; note?: never; recurringReportConfiguration?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Note */
  { basicCard?: never; chat?: never; entity?: never; existing?: never; integration?: never; mapImage?: never; metric?: never; note: Array<Scalars['ID']['input']>; recurringReportConfiguration?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Recurring Report Configuration */
  { basicCard?: never; chat?: never; entity?: never; existing?: never; integration?: never; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration: Array<Scalars['ID']['input']>; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Report */
  { basicCard?: never; chat?: never; entity?: never; existing?: never; integration?: never; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration?: never; report: Array<Scalars['ID']['input']>; section?: never; strategy?: never; work?: never; }
  |  /** Section */
  { basicCard?: never; chat?: never; entity?: never; existing?: never; integration?: never; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration?: never; report?: never; section: Array<Scalars['ID']['input']>; strategy?: never; work?: never; }
  |  /** Strategy */
  { basicCard?: never; chat?: never; entity?: never; existing?: never; integration?: never; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration?: never; report?: never; section?: never; strategy: Array<Scalars['ID']['input']>; work?: never; }
  |  /** Entity */
  { basicCard?: never; chat?: never; entity?: never; existing?: never; integration?: never; mapImage?: never; metric?: never; note?: never; recurringReportConfiguration?: never; report?: never; section?: never; strategy?: never; work: Array<Scalars['ID']['input']>; };

/** An object that has a standard interface of a react flow node on a strategy map */
export type DomainObjectInterface = {
  /** Confidence rating of the object */
  confidenceRating?: Maybe<ConfidenceRatingEnum>;
};

/** DomainObject status enum */
export enum DomainObjectStatusEnum {
  /** Active */
  Active = 'active',
  /** Done */
  Done = 'done',
  /** Idea */
  Idea = 'idea',
  /** Learning review */
  LearningReview = 'learning_review',
  /** Next */
  Next = 'next'
}

/** Autogenerated input type of DomainObjectUpdate */
export type DomainObjectUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The objects to update */
  objects: Array<NodeObjectInput>;
};

/** Autogenerated return type of DomainObjectUpdate. */
export type DomainObjectUpdatePayload = ClassTypeInterface & {
  __typename?: 'DomainObjectUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated objects */
  nodeObjects?: Maybe<Array<DomainObject>>;
};

/** Autogenerated input type of DomainObjectsBulkLabel */
export type DomainObjectsBulkLabelInput = {
  /** Labels to add */
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The objects to label */
  objects: Array<DomainObjectBulkLabelInput>;
  /** Labels to remove */
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of DomainObjectsBulkLabel. */
export type DomainObjectsBulkLabelPayload = ClassTypeInterface & {
  __typename?: 'DomainObjectsBulkLabelPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Labeled objects */
  domainObjects?: Maybe<Array<LabelableObject>>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of DomainObjectsDelete */
export type DomainObjectsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The objects to delete */
  objects: Array<DomainObjectDeleteInput>;
};

/** Autogenerated return type of DomainObjectsDelete. */
export type DomainObjectsDeletePayload = ClassTypeInterface & {
  __typename?: 'DomainObjectsDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** CRUD operation wrapper */
  crudOperation: TypesUnionsDomainObjectArrayGraphqlCrudOperation;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Edge */
export type Edge = ClassTypeInterface & {
  __typename?: 'Edge';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Manually set color for the edge */
  color?: Maybe<Scalars['String']['output']>;
  /** Correlation score if present */
  correlationScore?: Maybe<Scalars['String']['output']>;
  /** Object data */
  data: Scalars['JSON']['output'];
  /** The actual edge id for mutations */
  edgeId: Scalars['ID']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** AI Insights if present */
  insights?: Maybe<Scalars['String']['output']>;
  /** Expanded AI Insights on edges */
  insightsDetails?: Maybe<Scalars['String']['output']>;
  /** Current insights details chat */
  insightsDetailsChat?: Maybe<Chat>;
  /** Current insights details chat id */
  insightsDetailsChatId?: Maybe<Scalars['ID']['output']>;
  /** Object React Flow ID */
  rfId: Scalars['String']['output'];
  /** Whether the edge is selected or not */
  selected?: Maybe<Scalars['Boolean']['output']>;
  /** React flow source name */
  source: Scalars['String']['output'];
  /** Source position on the Strategy Map */
  sourceHandle: Scalars['String']['output'];
  /** Source domain object id */
  sourceId?: Maybe<Scalars['ID']['output']>;
  /** Source domain object type */
  sourceType: Scalars['String']['output'];
  /** associated Strategy */
  strategy?: Maybe<Strategy>;
  /** Strategy ID */
  strategyId: Scalars['ID']['output'];
  /** React flow target name */
  target: Scalars['String']['output'];
  /** Target position on the Strategy Map */
  targetHandle: Scalars['String']['output'];
  /** Target domain object id */
  targetId?: Maybe<Scalars['ID']['output']>;
  /** Source domain object type */
  targetType: Scalars['String']['output'];
  /** React flow type */
  type: Scalars['String']['output'];
  /** Z index for the edge */
  zIndex: Scalars['Int']['output'];
};

/** Autogenerated input type of EdgeCreate */
export type EdgeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The Position of the Source to associate */
  sourceHandle: Scalars['String']['input'];
  /** The ID of the Source to associate */
  sourceId: Scalars['ID']['input'];
  /** The Type of the Source to associate */
  sourceType: Scalars['String']['input'];
  /** The ID of the Strategy to associate */
  strategyId: Scalars['ID']['input'];
  /** The Position of the Target to associate */
  targetHandle: Scalars['String']['input'];
  /** The ID of the Target to associate */
  targetId: Scalars['ID']['input'];
  /** The Type of the Target to associate */
  targetType: Scalars['String']['input'];
};

/** Autogenerated return type of EdgeCreate. */
export type EdgeCreatePayload = ClassTypeInterface & {
  __typename?: 'EdgeCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Edge returned */
  edge: Edge;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of EdgeDelete */
export type EdgeDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Edge to destroy */
  edgeId: Scalars['ID']['input'];
};

/** Autogenerated return type of EdgeDelete. */
export type EdgeDeletePayload = ClassTypeInterface & {
  __typename?: 'EdgeDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Destroyed Edge returned */
  edge: Edge;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Input Type for Edges */
export type EdgeInput = {
  /** Color */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Edge ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Insights */
  insights?: InputMaybe<Scalars['String']['input']>;
  /** Expanded Insights */
  insightsDetails?: InputMaybe<Scalars['String']['input']>;
  /** The Position of the Source to associate */
  sourceHandle?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Strategy to associate */
  strategyId?: InputMaybe<Scalars['ID']['input']>;
  /** The Position of the Target to associate */
  targetHandle?: InputMaybe<Scalars['String']['input']>;
};

/** Entity */
export type Entity = ClassTypeInterface & CommentableInterface & DomainObjectInterface & RfNodeInterface & {
  __typename?: 'Entity';
  /** Activity that created this entity */
  activityId?: Maybe<Scalars['Int']['output']>;
  /** Whether the Metric was generated by AI */
  aiGenerated?: Maybe<Scalars['Boolean']['output']>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** List of entity classifications */
  classifications?: Maybe<Array<Classification>>;
  /** Comments left on the object */
  comments?: Maybe<Array<Comment>>;
  /** Number of comments left on the object */
  commentsCount: Scalars['Int']['output'];
  /** Confidence rating of the object */
  confidenceRating?: Maybe<ConfidenceRatingEnum>;
  /** Number of work items contained by this Work */
  containeeCount?: Maybe<Scalars['Int']['output']>;
  /** IDs of Entities contained by this Entity */
  containeeIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** workflow_state counts for Entities contained by this Entity */
  containeeStats?: Maybe<Scalars['JSON']['output']>;
  /** Entity containments */
  containerEntityContainments?: Maybe<Array<EntityContainment>>;
  /** Object creation date */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Entity summary text, intended for end-user display */
  displaySummary?: Maybe<Scalars['String']['output']>;
  /** Due date for the Entity */
  dueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Entity file attachments */
  entityAttachments?: Maybe<Array<Attachment>>;
  /** Number of file attachments to entity */
  entityAttachmentsCount: Scalars['Int']['output'];
  /** Entity Contributors */
  entityContributions?: Maybe<Array<EntityContribution>>;
  /** This is a poorly named field. Some form of first level descriptor like `chore`, `feature` */
  entityType?: Maybe<Scalars['String']['output']>;
  /** Primary ID in 3rd party Source like a Jira issue ID */
  foreignId?: Maybe<Scalars['String']['output']>;
  /** Foreign state like  Jira issue State */
  foreignState?: Maybe<Scalars['String']['output']>;
  /** Foreign type like  Jira issue */
  foreignType?: Maybe<Scalars['String']['output']>;
  /** What is the goal or hypothesis for this entity? */
  goalHypothesis?: Maybe<Scalars['String']['output']>;
  /** When the entity last had an event occur to it that we want to expose to the user */
  happenedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Unix timestamp of the week this entity happened_at. Used primarily for grouping */
  happenedOnWeek?: Maybe<Scalars['BigInt']['output']>;
  /** ID of object */
  id: Scalars['ID']['output'];
  /** Impact of either `positive` or `negative` */
  impact?: Maybe<ImpactEnum>;
  /** Importance of: `major`, `medium`, `minor` */
  importance?: Maybe<ImportanceEnum>;
  /** Entity labels */
  labels?: Maybe<Array<Label>>;
  /** First order link that will display on the Changelogs */
  linkTitle?: Maybe<Scalars['String']['output']>;
  /** First order link that will be used on the Changelog pages */
  linkUrl?: Maybe<Scalars['String']['output']>;
  /** the name of this entity */
  name?: Maybe<Scalars['String']['output']>;
  /** Associated Organization */
  organization: Organization;
  /** Organization ID */
  organizationId: Scalars['ID']['output'];
  /** Associated User */
  owner?: Maybe<User>;
  /** Owner ID */
  ownerId?: Maybe<Scalars['ID']['output']>;
  /** Whether the entity has recent activity */
  recentActivity?: Maybe<Scalars['Boolean']['output']>;
  /** Recorded results for the entity. Usually related to Goal / Hypothesis */
  results?: Maybe<Scalars['String']['output']>;
  /** Strategy node id */
  rfId: Scalars['String']['output'];
  /** Secondary ID in 3rd party Source like a Jira issue ID */
  secondaryForeignId?: Maybe<Scalars['String']['output']>;
  /** Whether to show the goal_hypothesis field */
  showGoalHypothesis?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the results field */
  showResults?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the summary field */
  showSummary?: Maybe<Scalars['Boolean']['output']>;
  /** Source name ex: Google or Jira */
  sourceName?: Maybe<Scalars['String']['output']>;
  /** Summary / Description on the Foreign Entity */
  sourceSummary?: Maybe<Scalars['String']['output']>;
  /** URL to entity on foreign service */
  sourceUrl?: Maybe<Scalars['String']['output']>;
  /** Strategies that the Work is associated with */
  strategies: Array<Strategy>;
  /** Entity summary */
  summary?: Maybe<Scalars['String']['output']>;
  /** Entity full summary */
  summaryFull?: Maybe<Scalars['String']['output']>;
  /** Entity shortened summary */
  summaryShort?: Maybe<Scalars['String']['output']>;
  /** Entity thumbnail */
  thumbnail?: Maybe<Attachment>;
  /** Object updated date */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Workflow state of the entity */
  workflowState?: Maybe<EntityWorkflowStateEnum>;
};

/** EntityCollection type */
export type EntityCollection = {
  __typename?: 'EntityCollection';
  /** A collection of paginated EntityCollection */
  collection: Array<Entity>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Entity Containment */
export type EntityContainment = ClassTypeInterface & {
  __typename?: 'EntityContainment';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** ID of the contained Entity */
  containeeId: Scalars['ID']['output'];
  /** Class name of the containing domain object */
  containerClassName: Scalars['String']['output'];
  /** ID of the containing domain object */
  containerId: Scalars['ID']['output'];
  /** Type of the containing domain object */
  containerType: Scalars['String']['output'];
  /** ID of object */
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of EntityContainmentCreate */
export type EntityContainmentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the containment to create */
  containerId: Scalars['ID']['input'];
  /** Type of containment to create */
  containerType: Scalars['String']['input'];
  /** ID of the entity to create the containment from */
  entityId: Scalars['ID']['input'];
};

/** Autogenerated return type of EntityContainmentCreate. */
export type EntityContainmentCreatePayload = ClassTypeInterface & {
  __typename?: 'EntityContainmentCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Deleted entity */
  entity?: Maybe<Entity>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of EntityContainmentDelete */
export type EntityContainmentDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the containment to delete */
  containerId: Scalars['ID']['input'];
  /** Type of containment to delete */
  containerType: Scalars['String']['input'];
  /** ID of the entity to delete the containment from */
  entityId: Scalars['ID']['input'];
};

/** Autogenerated return type of EntityContainmentDelete. */
export type EntityContainmentDeletePayload = ClassTypeInterface & {
  __typename?: 'EntityContainmentDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Deleted entity */
  entity?: Maybe<Entity>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** A relationship between an Entity and a User or a SourceIdentity */
export type EntityContribution = ClassTypeInterface & {
  __typename?: 'EntityContribution';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Associated Contributor / User */
  contributor: Contributor;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Associated Entity */
  entity: Entity;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of EntityCreateAttachment */
export type EntityCreateAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Entity ID to attach to */
  entityId: Scalars['ID']['input'];
  /** File Data Hash */
  shrineHash: Scalars['JSON']['input'];
};

/** Autogenerated return type of EntityCreateAttachment. */
export type EntityCreateAttachmentPayload = ClassTypeInterface & {
  __typename?: 'EntityCreateAttachmentPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated entity */
  entity?: Maybe<Entity>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of EntityCreateContributor */
export type EntityCreateContributorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the Contributor to add */
  contributorId?: InputMaybe<Scalars['ID']['input']>;
  /** Email of the Contributor to add */
  email?: InputMaybe<Scalars['String']['input']>;
  /** ID of the entity to add the label to */
  entityId: Scalars['ID']['input'];
  /** Source Name of the Contributor to add */
  sourceName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EntityCreateContributor. */
export type EntityCreateContributorPayload = ClassTypeInterface & {
  __typename?: 'EntityCreateContributorPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Entity the Label was added to */
  entity: Entity;
  /** Newly created Contributor */
  entityContribution?: Maybe<EntityContribution>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Input Type for Entity */
export type EntityCreateInput = {
  /** Whether the Entity was generated by AI */
  aiGenerated?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Optional attachment Shrine hashes, to add to the Entity */
  attachmentHashes?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Optional container to add to the Entity */
  containerId?: InputMaybe<Scalars['ID']['input']>;
  /** Optional container node ids to add to the Entity */
  containerNodeIds?: InputMaybe<Scalars['CommaSeparatedString']['input']>;
  /** Optional container type to add to the Entity */
  containerType?: InputMaybe<Scalars['String']['input']>;
  /** Optional contributors to add to the entity */
  contributors?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** The due_date for the Work */
  dueDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The type of the entity (chore, feature, fix, etc.) */
  entityType?: InputMaybe<Scalars['String']['input']>;
  /** The state of the Work in the source */
  foreignState?: InputMaybe<EntityWorkflowStateEnum>;
  /** The type of the Work in the source */
  foreignType?: InputMaybe<Scalars['String']['input']>;
  /** The expected result or hypothesis for the entity */
  goalHypothesis?: InputMaybe<Scalars['String']['input']>;
  /** When the entity / event occurred */
  happenedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Entity ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** One of positive, negative, or null */
  impact?: InputMaybe<Scalars['String']['input']>;
  /** One of major, medium, minor */
  importance?: InputMaybe<Scalars['String']['input']>;
  /** Optional labels to add to the Entity */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Title of the link on the Changelog */
  linkTitle?: InputMaybe<Scalars['String']['input']>;
  /** URL of the link on the Changelog */
  linkUrl?: InputMaybe<Scalars['String']['input']>;
  /** The name of the Work */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Work */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** What result happened with the entity */
  results?: InputMaybe<Scalars['String']['input']>;
  /** Whether to show the goal_hypothesis field */
  showGoalHypothesis?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the results field */
  showResults?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the summary field */
  showSummary?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** The source of the Work */
  sourceName?: InputMaybe<Scalars['String']['input']>;
  /** Entity long-form summary */
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EntityCreate. */
export type EntityCreatePayload = ClassTypeInterface & {
  __typename?: 'EntityCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Newly created entity */
  entity?: Maybe<Entity>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of EntityDeleteAttachment */
export type EntityDeleteAttachmentInput = {
  /** ID of attachment to destroy */
  attachmentId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Entity ID to remove from */
  entityId: Scalars['ID']['input'];
};

/** Autogenerated return type of EntityDeleteAttachment. */
export type EntityDeleteAttachmentPayload = ClassTypeInterface & {
  __typename?: 'EntityDeleteAttachmentPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated entity */
  entity?: Maybe<Entity>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of EntityDeleteContributor */
export type EntityDeleteContributorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Contributor ID to delete */
  contributorId: Scalars['ID']['input'];
  /** ID of Entity to remove the label from */
  entityId: Scalars['ID']['input'];
  /** Source name of Contributor type to delete */
  sourceName: Scalars['String']['input'];
};

/** Autogenerated return type of EntityDeleteContributor. */
export type EntityDeleteContributorPayload = ClassTypeInterface & {
  __typename?: 'EntityDeleteContributorPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Removed Label */
  contributor?: Maybe<Label>;
  /** Entity the Label was added to */
  entity: Entity;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of EntityDelete */
export type EntityDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Entity ID to update */
  entityId: Scalars['ID']['input'];
};

/** Autogenerated return type of EntityDelete. */
export type EntityDeletePayload = ClassTypeInterface & {
  __typename?: 'EntityDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Deleted entity */
  entity?: Maybe<Entity>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Input Type for Entity */
export type EntityInput = {
  /** Whether the Entity was generated by AI */
  aiGenerated?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** The due_date for the Work */
  dueDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The state of the Work in the source */
  foreignState?: InputMaybe<EntityWorkflowStateEnum>;
  /** The type of the Work in the source */
  foreignType?: InputMaybe<Scalars['String']['input']>;
  /** The expected result or hypothesis for the entity */
  goalHypothesis?: InputMaybe<Scalars['String']['input']>;
  /** When the entity / event occurred */
  happenedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Entity ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** One of positive, negative, or null */
  impact?: InputMaybe<Scalars['String']['input']>;
  /** One of major, medium, minor */
  importance?: InputMaybe<Scalars['String']['input']>;
  /** Optional labels to add to the Entity */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Title of the link on the Changelog */
  linkTitle?: InputMaybe<Scalars['String']['input']>;
  /** URL of the link on the Changelog */
  linkUrl?: InputMaybe<Scalars['String']['input']>;
  /** The name of the Work */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Work */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** What result happened with the entity */
  results?: InputMaybe<Scalars['String']['input']>;
  /** Whether to show the goal_hypothesis field */
  showGoalHypothesis?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the results field */
  showResults?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the summary field */
  showSummary?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** The source of the Work */
  sourceName?: InputMaybe<Scalars['String']['input']>;
  /** A summary of the Work */
  summary?: InputMaybe<Scalars['String']['input']>;
};

export type EntityOrderingInput = {
  /** Sort by foreignType */
  foreignType?: InputMaybe<SortDirectionEnum>;
  /** Sort by name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Sort by sourceName */
  sourceName?: InputMaybe<SortDirectionEnum>;
};

/** Autogenerated input type of EntitySlack */
export type EntitySlackInput = {
  /** SlackChannel ID to send the message to */
  channelId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Entity ID of the event to send. It will use the "latest" event as context */
  entityId: Scalars['ID']['input'];
  /** Strategy ID of the context to send the event within. Will link to the strategy changelog */
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of EntitySlack. */
export type EntitySlackPayload = ClassTypeInterface & {
  __typename?: 'EntitySlackPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** True if the Slack message sent */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Input Type for Entity */
export type EntityUpdateInput = {
  /** Whether the Entity was generated by AI */
  aiGenerated?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Optional contributors to add to the entity */
  contributors?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** The due_date for the Work */
  dueDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Entity ID to update */
  entityId: Scalars['ID']['input'];
  /** The type of the entity (chore, feature, fix, etc.) */
  entityType?: InputMaybe<Scalars['String']['input']>;
  /** The state of the Work in the source */
  foreignState?: InputMaybe<EntityWorkflowStateEnum>;
  /** The type of the Work in the source */
  foreignType?: InputMaybe<Scalars['String']['input']>;
  /** The expected result or hypothesis for the entity */
  goalHypothesis?: InputMaybe<Scalars['String']['input']>;
  /** When the entity / event occurred */
  happenedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Entity ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** One of positive, negative, or null */
  impact?: InputMaybe<Scalars['String']['input']>;
  /** One of major, medium, minor */
  importance?: InputMaybe<Scalars['String']['input']>;
  /** Optional labels to add to the Entity */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Title of the link on the Changelog */
  linkTitle?: InputMaybe<Scalars['String']['input']>;
  /** URL of the link on the Changelog */
  linkUrl?: InputMaybe<Scalars['String']['input']>;
  /** The name of the Work */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Work */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** What result happened with the entity */
  results?: InputMaybe<Scalars['String']['input']>;
  /** Whether to show the goal_hypothesis field */
  showGoalHypothesis?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the results field */
  showResults?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the summary field */
  showSummary?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** The source of the Work */
  sourceName?: InputMaybe<Scalars['String']['input']>;
  /** Event long-form summary that came from a source */
  sourceSummary?: InputMaybe<Scalars['String']['input']>;
  /** Event long-form summary */
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EntityUpdate. */
export type EntityUpdatePayload = ClassTypeInterface & {
  __typename?: 'EntityUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated entity */
  entity?: Maybe<Entity>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** List of possible values for an Entity's workflow_state */
export enum EntityWorkflowStateEnum {
  /** The work has been completed */
  Finished = 'finished',
  /** The work has not yet started */
  NotStarted = 'not_started',
  /** The work is currently being reviewed */
  Reviewing = 'reviewing',
  /** The work has started */
  Started = 'started'
}

/** Base error object */
export type Error = ClassTypeInterface & {
  __typename?: 'Error';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Error description */
  message: Scalars['String']['output'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']['output']>>;
};

/** Input Type for Existing Nodes */
export type ExistingDomainObjectInput = {
  /** ID of the existing object */
  id: Scalars['ID']['input'];
  /** Type of the existing object */
  type: Scalars['String']['input'];
};

/** An object that can be favorited */
export type FavoritableInterface = {
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** The ID of the current user's Favorite of this favoritable, if favorited */
  favoriteId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the favoritable */
  id: Scalars['ID']['output'];
  /** The name of the favoritable */
  name?: Maybe<Scalars['String']['output']>;
};

/** A User favorite */
export type Favorite = ClassTypeInterface & {
  __typename?: 'Favorite';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** The date and time the favorite was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The favoritable */
  favoritable: FavoritableInterface;
  /** The ID of the favoritable */
  favoritableId: Scalars['ID']['output'];
  /** The type of the favoritable */
  favoritableType: Scalars['String']['output'];
  /** The ID of the favorite */
  id: Scalars['ID']['output'];
  /** The organizaiton the favorite belongs to */
  organization?: Maybe<Organization>;
  /** The date and time the favorite was last updated */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** The polymorphic url of the favoritable */
  url: Scalars['String']['output'];
  /** The user who favorited the favoritable */
  user: User;
};

/** Autogenerated input type of FavoriteCreate */
export type FavoriteCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the favoritable */
  favoritableId: Scalars['ID']['input'];
  /** The type of the favoritable */
  favoritableType: Scalars['String']['input'];
};

/** Autogenerated return type of FavoriteCreate. */
export type FavoriteCreatePayload = ClassTypeInterface & {
  __typename?: 'FavoriteCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Created favorite */
  favorite: Favorite;
};

/** Autogenerated input type of FavoriteDelete */
export type FavoriteDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the favoritable */
  favoritableId: Scalars['ID']['input'];
  /** The type of the favoritable */
  favoritableType: Scalars['String']['input'];
};

/** Autogenerated return type of FavoriteDelete. */
export type FavoriteDeletePayload = ClassTypeInterface & {
  __typename?: 'FavoriteDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Deleted Favorite */
  favorite?: Maybe<Favorite>;
};

/** File data */
export type FileAttachment = ClassTypeInterface & {
  __typename?: 'FileAttachment';
  /** CDN URL */
  cdnUrl: Scalars['String']['output'];
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Relative storage path */
  id: Scalars['String']['output'];
  /** Metadata */
  metadata?: Maybe<FileAttachmentMetadata>;
  /** Signed URL */
  url: Scalars['String']['output'];
};

/** File metadata */
export type FileAttachmentMetadata = ClassTypeInterface & {
  __typename?: 'FileAttachmentMetadata';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Original name of the file */
  filename: Scalars['String']['output'];
  /** File mime type */
  mimeType: Scalars['String']['output'];
  /** File size */
  size: Scalars['Int']['output'];
};

/** Goals */
export type Goal = ClassTypeInterface & {
  __typename?: 'Goal';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Current Value of the Goal */
  currentValue: Scalars['Float']['output'];
  /** End Date of the Goal */
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** ID of the Goal */
  id: Scalars['ID']['output'];
  /** Displayable label of the Goal */
  label: Scalars['String']['output'];
  /** Metric of the Goal */
  metric: Metric;
  /** ID of the Metric of the Goal */
  metricId: Scalars['ID']['output'];
  /** Date of the newest metric data point */
  newestMetricDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Date of the oldest metric data point */
  oldestMetricDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Percentage Complete of the Goal */
  percentageComplete: Scalars['Float']['output'];
  /** Period Month / Quarter of the Goal */
  periodNumber?: Maybe<Scalars['Int']['output']>;
  /** Period Unit of the Goal */
  periodUnit: PeriodUnitEnum;
  /** Period Year of the Goal */
  periodYear?: Maybe<Scalars['Int']['output']>;
  /** Start Date of the Goal */
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Start Value of the Goal */
  startValue: Scalars['Float']['output'];
  /** Status of the Goal */
  status: GoalStatusEnum;
  /** Target Value of the Goal */
  targetValue: Scalars['Float']['output'];
};

/** Autogenerated input type of GoalCreate */
export type GoalCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** End Date of the Goal */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The ID of the Metric that the Goal is for */
  metricId: Scalars['ID']['input'];
  /** The Period Number of the Goal */
  periodNumber?: InputMaybe<Scalars['Int']['input']>;
  /** The Period Unit of the Goal */
  periodUnit: PeriodUnitEnum;
  /** The Period Year of the Goal */
  periodYear?: InputMaybe<Scalars['Int']['input']>;
  /** Start Date of the Goal */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The Start Value of the Goal */
  startValue?: InputMaybe<Scalars['Float']['input']>;
  /** The Target Value of the Goal */
  targetValue: Scalars['Float']['input'];
};

/** Autogenerated return type of GoalCreate. */
export type GoalCreatePayload = ClassTypeInterface & {
  __typename?: 'GoalCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Goal */
  goal?: Maybe<Goal>;
};

/** Autogenerated input type of GoalDelete */
export type GoalDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Goal to delete */
  goalId: Scalars['ID']['input'];
};

/** Autogenerated return type of GoalDelete. */
export type GoalDeletePayload = ClassTypeInterface & {
  __typename?: 'GoalDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Deleted Goal */
  goal: Goal;
};

/** List of possible status values on Goals */
export enum GoalStatusEnum {
  /** The Goal is currently active */
  Current = 'current',
  /** The Goal starts in the future */
  Future = 'future',
  /** The Goal is in the past */
  Past = 'past',
  /** The Goal is in unknown status due to corrupt data */
  Unknown = 'unknown'
}

/** Autogenerated input type of GoalUpdate */
export type GoalUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** End Date of the Goal */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The ID of the Goal to update */
  goalId: Scalars['ID']['input'];
  /** The period number of the Goal */
  periodNumber?: InputMaybe<Scalars['Int']['input']>;
  /** The period unit of the Goal */
  periodUnit?: InputMaybe<PeriodUnitEnum>;
  /** The period year of the Goal */
  periodYear?: InputMaybe<Scalars['Int']['input']>;
  /** Start Date of the Goal */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** The starting value of the Goal */
  startValue?: InputMaybe<Scalars['Float']['input']>;
  /** The target value of the Goal */
  targetValue?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated return type of GoalUpdate. */
export type GoalUpdatePayload = ClassTypeInterface & {
  __typename?: 'GoalUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Goal */
  goal: Goal;
};

/** Autogenerated input type of GoogleAnalyticsPropertyImport */
export type GoogleAnalyticsPropertyImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Google Analytics integration to use */
  integrationId: Scalars['ID']['input'];
  /** The ID of the GA4 property to import */
  propertyId: Scalars['String']['input'];
  /** The name of the GA4 property to import. Used for labeling */
  propertyName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of GoogleAnalyticsPropertyImport. */
export type GoogleAnalyticsPropertyImportPayload = ClassTypeInterface & {
  __typename?: 'GoogleAnalyticsPropertyImportPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The integration */
  integration: Integration;
};

/** Google Integration */
export type GoogleIntegration = ClassTypeInterface & IntegrationInterface & {
  __typename?: 'GoogleIntegration';
  /** Number of activities association to this integration */
  activitiesCount: Scalars['Int']['output'];
  /** Class type of the integration */
  classType: Scalars['String']['output'];
  /** Any custom configuration for the integration */
  configuration?: Maybe<Scalars['JSON']['output']>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** User who performed the integration */
  creator: User;
  /** Number of entities associated ot the integration */
  entitiesCount: Scalars['Int']['output'];
  /** Types of entities associated to the integration */
  entityTypes: Array<Scalars['String']['output']>;
  /** The list of available Google Analytics goals */
  goals?: Maybe<Scalars['JSON']['output']>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Whether or not the integration has imported historical data */
  importedHistory: Scalars['Boolean']['output'];
  /** Last time the importer was run */
  importerLastImportAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Number of entities to import */
  importerTotalEntitiesCount?: Maybe<Scalars['Int']['output']>;
  /** Any metadata we received during integration */
  installPayload?: Maybe<Scalars['JSON']['output']>;
  /** Associated Organization */
  organization: Organization;
  /** The list of available Google Analytics profiles */
  profiles?: Maybe<Scalars['JSON']['output']>;
  /** The list of available Google Analytics properties (UA) */
  properties?: Maybe<Scalars['JSON']['output']>;
  /** The list of available Google Analytics properties (V4) */
  propertiesV4?: Maybe<Scalars['JSON']['output']>;
  /** The hash of previously-imported GA4 properties. */
  propertyImports?: Maybe<Scalars['JSON']['output']>;
  /** The list of available Google Analytics segments */
  segments?: Maybe<Scalars['JSON']['output']>;
  /** Unique identifier for the integration */
  sourceId?: Maybe<Scalars['String']['output']>;
  /** Source name ex: Jira or Shortcut */
  sourceName: IntegrationSourceNamesEnum;
  /** STI column to denote which source. Superfluous with source_name in a lot of ways */
  type?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A Google Sheet */
export type GoogleSheet = ClassTypeInterface & {
  __typename?: 'GoogleSheet';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** ID of the Google Sheet */
  id: Scalars['ID']['output'];
  /** Whether the Google Sheet is the full multiple-metric organization-level sheet */
  organizationLevel: Scalars['Boolean']['output'];
  /** The URL of the Google Sheet */
  spreadsheetUrl: Scalars['String']['output'];
  /** The status of the Google Sheet */
  status: GoogleSheetStatusEnum;
};

/** Autogenerated input type of GoogleSheetDelete */
export type GoogleSheetDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Google Sheet to delete */
  googleSheetId: Scalars['ID']['input'];
};

/** Autogenerated return type of GoogleSheetDelete. */
export type GoogleSheetDeletePayload = ClassTypeInterface & {
  __typename?: 'GoogleSheetDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Deleted Google Sheet */
  googleSheet: GoogleSheet;
};

/** Autogenerated input type of GoogleSheetImport */
export type GoogleSheetImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Google Sheet to import */
  googleSheetId: Scalars['ID']['input'];
};

/** Autogenerated return type of GoogleSheetImport. */
export type GoogleSheetImportPayload = ClassTypeInterface & {
  __typename?: 'GoogleSheetImportPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Google Sheet that was imported */
  googleSheet: GoogleSheet;
};

/** List of possible status values on Google Sheets */
export enum GoogleSheetStatusEnum {
  /** The Sheet is available for use */
  Active = 'active',
  /** The Sheet has encountered an error */
  Error = 'error',
  /** The Sheet is currently being exported */
  Exporting = 'exporting',
  /** The Sheet is currently being imported */
  Importing = 'importing',
  /** The Sheet is newly-created, but has not yet begun processing */
  New = 'new',
  /** The Sheet is queued for processing */
  Queued = 'queued'
}

/** List of possible Impact values on Entity */
export enum ImpactEnum {
  /** Negative - The entity caused a negative change */
  Negative = 'negative',
  /** Positive - The entity caused a positive change */
  Positive = 'positive'
}

/** List of entity importances */
export enum ImportanceEnum {
  /** Major - Very important, large changes */
  Major = 'major',
  /** Medium - Important enough to note */
  Medium = 'medium',
  /** Minor - Small changes, text updates */
  Minor = 'minor'
}

/** Base Integration */
export type Integration = ClassTypeInterface & IntegrationInterface & {
  __typename?: 'Integration';
  /** Number of activities association to this integration */
  activitiesCount: Scalars['Int']['output'];
  /** Class type of the integration */
  classType: Scalars['String']['output'];
  /** Any custom configuration for the integration */
  configuration?: Maybe<Scalars['JSON']['output']>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** User who performed the integration */
  creator: User;
  /** Number of entities associated ot the integration */
  entitiesCount: Scalars['Int']['output'];
  /** Types of entities associated to the integration */
  entityTypes: Array<Scalars['String']['output']>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Whether or not the integration has imported historical data */
  importedHistory: Scalars['Boolean']['output'];
  /** Last time the importer was run */
  importerLastImportAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Number of entities to import */
  importerTotalEntitiesCount?: Maybe<Scalars['Int']['output']>;
  /** Any metadata we received during integration */
  installPayload?: Maybe<Scalars['JSON']['output']>;
  /** Associated Organization */
  organization: Organization;
  /** Unique identifier for the integration */
  sourceId?: Maybe<Scalars['String']['output']>;
  /** Source name ex: Jira or Shortcut */
  sourceName: IntegrationSourceNamesEnum;
  /** STI column to denote which source. Superfluous with source_name in a lot of ways */
  type?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of IntegrationCreate */
export type IntegrationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the source being created */
  sourceId: Scalars['String']['input'];
  /** The name of the source being connected.  */
  sourceName: Scalars['String']['input'];
};

/** Autogenerated return type of IntegrationCreate. */
export type IntegrationCreatePayload = ClassTypeInterface & {
  __typename?: 'IntegrationCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Integration */
  integration?: Maybe<Integration>;
  /** Associated Organization */
  organization: Organization;
};

/** Autogenerated input type of IntegrationImportHistory */
export type IntegrationImportHistoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Integration */
  integrationId: Scalars['ID']['input'];
};

/** Autogenerated return type of IntegrationImportHistory. */
export type IntegrationImportHistoryPayload = ClassTypeInterface & {
  __typename?: 'IntegrationImportHistoryPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The errors */
  errors: Array<Error>;
  /** The integration */
  integration: Integration;
};

/** Base interface for all Integration types. Use when an Integration needs non-standard fields like GoogleIntegrationType */
export type IntegrationInterface = {
  /** Number of activities association to this integration */
  activitiesCount: Scalars['Int']['output'];
  /** Class type of the integration */
  classType: Scalars['String']['output'];
  /** Any custom configuration for the integration */
  configuration?: Maybe<Scalars['JSON']['output']>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** User who performed the integration */
  creator: User;
  /** Number of entities associated ot the integration */
  entitiesCount: Scalars['Int']['output'];
  /** Types of entities associated to the integration */
  entityTypes: Array<Scalars['String']['output']>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Whether or not the integration has imported historical data */
  importedHistory: Scalars['Boolean']['output'];
  /** Last time the importer was run */
  importerLastImportAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Number of entities to import */
  importerTotalEntitiesCount?: Maybe<Scalars['Int']['output']>;
  /** Any metadata we received during integration */
  installPayload?: Maybe<Scalars['JSON']['output']>;
  /** Associated Organization */
  organization: Organization;
  /** Unique identifier for the integration */
  sourceId?: Maybe<Scalars['String']['output']>;
  /** Source name ex: Jira or Shortcut */
  sourceName: IntegrationSourceNamesEnum;
  /** STI column to denote which source. Superfluous with source_name in a lot of ways */
  type?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Source names of Integrations */
export enum IntegrationSourceNamesEnum {
  /** Asana */
  Asana = 'asana',
  /** Google */
  Google = 'google',
  /** Jira */
  Jira = 'jira',
  /** Linear */
  Linear = 'linear',
  /** Productboard */
  Productboard = 'productboard',
  /** Shortcut */
  Shortcut = 'shortcut',
  /** Slack */
  Slack = 'slack'
}

/** Union of Integration types */
export type IntegrationUnion = GoogleIntegration | Integration | JiraIntegration;

/** Input Type for an Integration */
export type IntegrationUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Integration */
  integrationId: Scalars['ID']['input'];
  /** Jira project keys to skip importing */
  restrictedProjects?: InputMaybe<Scalars['CommaSeparatedString']['input']>;
};

/** Autogenerated return type of IntegrationUpdate. */
export type IntegrationUpdatePayload = ClassTypeInterface & {
  __typename?: 'IntegrationUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The errors */
  errors: Array<Error>;
  /** The updated integration */
  integration: IntegrationUnion;
};

/** List of intervals */
export enum IntervalEnum {
  /** Day */
  Day = 'day',
  /** Month */
  Month = 'month',
  /** Quarter */
  Quarter = 'quarter',
  /** Week */
  Week = 'week',
  /** Year */
  Year = 'year'
}

/** Invitation */
export type Invitation = ClassTypeInterface & {
  __typename?: 'Invitation';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Email of person invited */
  email: Scalars['String']['output'];
  /** ID of object */
  id?: Maybe<Scalars['ID']['output']>;
  /** Invitation code */
  inviteCode?: Maybe<Scalars['String']['output']>;
  /** User who invited the person */
  invitedBy?: Maybe<User>;
  /** Last date we sent an invitation */
  lastSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Associated Organization */
  organization: Organization;
  /** Role the user will be created with */
  role?: Maybe<RoleEnum>;
  /** Object updated date */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** User created from this invitation */
  user?: Maybe<User>;
};

/** Autogenerated input type of InvitationCreate */
export type InvitationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email for the user to invite */
  email: Scalars['String']['input'];
  /** The role for the newly created user */
  role: RoleEnum;
};

/** Autogenerated return type of InvitationCreate. */
export type InvitationCreatePayload = ClassTypeInterface & {
  __typename?: 'InvitationCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Invitation */
  invitation?: Maybe<Invitation>;
};

/** Autogenerated input type of InvitationDelete */
export type InvitationDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invitation to delete */
  invitationId: Scalars['ID']['input'];
};

/** Autogenerated return type of InvitationDelete. */
export type InvitationDeletePayload = ClassTypeInterface & {
  __typename?: 'InvitationDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** CRUD operation wrapper */
  crudOperation: TypesInvitationTypeGraphqlCrudOperation;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of InvitationResend */
export type InvitationResendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invitation ID to update */
  invitationId: Scalars['ID']['input'];
};

/** Autogenerated return type of InvitationResend. */
export type InvitationResendPayload = ClassTypeInterface & {
  __typename?: 'InvitationResendPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Resent Invitation */
  invitation?: Maybe<Invitation>;
};

/** Jira Integration */
export type JiraIntegration = ClassTypeInterface & IntegrationInterface & {
  __typename?: 'JiraIntegration';
  /** Number of activities association to this integration */
  activitiesCount: Scalars['Int']['output'];
  /** Class type of the integration */
  classType: Scalars['String']['output'];
  /** Any custom configuration for the integration */
  configuration?: Maybe<Scalars['JSON']['output']>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** User who performed the integration */
  creator: User;
  /** Number of entities associated ot the integration */
  entitiesCount: Scalars['Int']['output'];
  /** Types of entities associated to the integration */
  entityTypes: Array<Scalars['String']['output']>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Whether or not the integration has imported historical data */
  importedHistory: Scalars['Boolean']['output'];
  /** Last time the importer was run */
  importerLastImportAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Number of entities to import */
  importerTotalEntitiesCount?: Maybe<Scalars['Int']['output']>;
  /** Any metadata we received during integration */
  installPayload?: Maybe<Scalars['JSON']['output']>;
  /** Associated Organization */
  organization: Organization;
  /** The list of project keys to skip imports for */
  restrictedProjects: Array<Scalars['String']['output']>;
  /** Unique identifier for the integration */
  sourceId?: Maybe<Scalars['String']['output']>;
  /** Source name ex: Jira or Shortcut */
  sourceName: IntegrationSourceNamesEnum;
  /** STI column to denote which source. Superfluous with source_name in a lot of ways */
  type?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Label */
export type Label = ClassTypeInterface & {
  __typename?: 'Label';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** List of entities associated to Label */
  entities?: Maybe<Array<Entity>>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Label name */
  name: Scalars['String']['output'];
  /** Associated Organization */
  organization: Organization;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of LabelCreate */
export type LabelCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** domain object to add the label to */
  domainObject: LabelableObjectInput;
  /** Name of the Label */
  name: Scalars['String']['input'];
};

/** Autogenerated return type of LabelCreate. */
export type LabelCreatePayload = ClassTypeInterface & {
  __typename?: 'LabelCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Object the Label was added to */
  domainObject: LabelableObject;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly-created Label */
  label?: Maybe<Label>;
};

/** Autogenerated input type of LabelDelete */
export type LabelDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The domain object to remove the label from */
  domainObject: LabelableObjectInput;
  /** Label ID to delete */
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of LabelDelete. */
export type LabelDeletePayload = ClassTypeInterface & {
  __typename?: 'LabelDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The domain object the Label was removed from */
  domainObject: LabelableObject;
  /** Array of Errors */
  errors: Array<Error>;
  /** Removed Label */
  label?: Maybe<Label>;
};

/** Objects that can have labels applied to them */
export type LabelableObject = BasicCard | Entity | Metric | Strategy;

/** Input Type for Labeling domain objects */
export type LabelableObjectInput =
  /** Basic Card */
  { basicCardId: Scalars['ID']['input']; entityId?: never; metricId?: never; strategyId?: never; }
  |  /** Entity */
  { basicCardId?: never; entityId: Scalars['ID']['input']; metricId?: never; strategyId?: never; }
  |  /** Metric */
  { basicCardId?: never; entityId?: never; metricId: Scalars['ID']['input']; strategyId?: never; }
  |  /** Strategy */
  { basicCardId?: never; entityId?: never; metricId?: never; strategyId: Scalars['ID']['input']; };

/** Autogenerated input type of MapClone */
export type MapCloneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Strategy */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of MapClone. */
export type MapClonePayload = ClassTypeInterface & {
  __typename?: 'MapClonePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The errors */
  errors: Array<Error>;
  /** The newly-created strategy */
  strategy: Strategy;
};

/** Autogenerated input type of MapCloneToOrganization */
export type MapCloneToOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the organization to duplicate the strategy into */
  organizationId: Scalars['ID']['input'];
  /** The id of the strategy to duplicate */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of MapCloneToOrganization. */
export type MapCloneToOrganizationPayload = ClassTypeInterface & {
  __typename?: 'MapCloneToOrganizationPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** The ID of the duplicated strategy. We avoid using the Type, to avoid permissions issues with non-org members */
  strategyId?: Maybe<Scalars['ID']['output']>;
  /** The name of the duplicated strategy */
  strategyName?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MapCreateFromExisting */
export type MapCreateFromExistingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The IDs of the edges to move */
  edgeIds: Array<Scalars['ID']['input']>;
  /** The IDs of the nodes to move */
  nodeIds: Array<Scalars['ID']['input']>;
  /** The position of the submap card */
  position: PositionInput;
  /** The ID of the Strategy to associate */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of MapCreateFromExisting. */
export type MapCreateFromExistingPayload = ClassTypeInterface & {
  __typename?: 'MapCreateFromExistingPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** The newly created map */
  map: Strategy;
  /** The newly created node */
  node: Node;
};

/** Map image */
export type MapImage = ClassTypeInterface & RfNodeInterface & {
  __typename?: 'MapImage';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** File extension */
  extension: Scalars['String']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** File mime type */
  mimeType: Scalars['String']['output'];
  /** Original file name  */
  originalFilename: Scalars['String']['output'];
  /** Strategy node id */
  rfId: Scalars['String']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** URL to full attachment */
  url: Scalars['String']['output'];
};

/** Input Type for MapImage */
export type MapImageInput =
  /** The Shrine Hash of the file */
  { file: ShrineInput; fileDataUri?: never; id?: never; }
  |  /** The data URI of the file */
  { file?: never; fileDataUri: Scalars['String']['input']; id?: never; }
  |  /** MapImage ID */
  { file?: never; fileDataUri?: never; id: Scalars['ID']['input']; };

/** Autogenerated return type of MapStructureChanged. */
export type MapStructureChangedPayload = ClassTypeInterface & {
  __typename?: 'MapStructureChangedPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** The node or edge that was affected */
  data: MapStructureElement;
  /** The operation that was performed */
  operation: CrudOperationEnum;
};

/** Map structural elements */
export type MapStructureElement = Edge | Node;

/** A message in a chat thread */
export type Message = ClassTypeInterface & {
  __typename?: 'Message';
  /** ID of the chat */
  chatId: Scalars['ID']['output'];
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Content of the message */
  content: Scalars['String']['output'];
  /** Creation date of the message */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** ID of the chat */
  id: Scalars['ID']['output'];
  /** Role of the message */
  role: AiAssistantRoleEnum;
  /** Last updated timestamp */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** User who sent the message */
  user?: Maybe<User>;
  /** ID of the user who sent the message */
  userId?: Maybe<Scalars['ID']['output']>;
};

/** Metric */
export type Metric = ClassTypeInterface & CommentableInterface & DomainObjectInterface & FavoritableInterface & RfNodeInterface & {
  __typename?: 'Metric';
  /** Whether the Metric was generated by AI */
  aiGenerated?: Maybe<Scalars['Boolean']['output']>;
  /** True if the Metric is calculated */
  calculated?: Maybe<Scalars['Boolean']['output']>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Comments left on the object */
  comments?: Maybe<Array<Comment>>;
  /** Number of comments left on the object */
  commentsCount: Scalars['Int']['output'];
  /** Whether the Metric stat values should be displayed in a compact format */
  compactDisplay: Scalars['Boolean']['output'];
  /** Confidence rating of the object */
  confidenceRating?: Maybe<ConfidenceRatingEnum>;
  /** IDs of Metrics contained by this Metric */
  containeeIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Whether the data in this Metric is considered placeholder data */
  containsPlaceholderData: Scalars['Boolean']['output'];
  /** Correlation information between this metric, and other organization metrics */
  correlations: Array<CorrelationPair>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Credential for the metric source, if any */
  credential?: Maybe<Credential>;
  /** Metric description */
  description?: Maybe<Scalars['String']['output']>;
  /** Metric display type */
  displayFormat: DisplayFormatEnum;
  /** The entities for the metric */
  entities: EntityCollection;
  /** Whether the Metric should exclude weekends when calculating stats */
  excludeWeekends: Scalars['Boolean']['output'];
  /** External URL to where metric data is viewable */
  externalUrl?: Maybe<Scalars['String']['output']>;
  /** Text to display for the external URL */
  externalUrlTitle?: Maybe<Scalars['String']['output']>;
  /** The ID of the current user's Favorite of this favoritable, if favorited */
  favoriteId?: Maybe<Scalars['ID']['output']>;
  /** Goals associated with this Metric */
  goals: Array<Goal>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Number of processes currently running that want to show an indicator in the frontend */
  indicatorCounter: Scalars['Int']['output'];
  /** Labels */
  labels?: Maybe<Array<Label>>;
  /** Maximum precision to use when displaying the stats */
  maximumDisplayPrecision: Scalars['Int']['output'];
  /** Metric data points */
  metricDataPoints: Array<MetricDataPoint>;
  /** Number of metric data points */
  metricDataPointsCount: Scalars['Int']['output'];
  /** Associated MetricSource */
  metricSource?: Maybe<MetricSource>;
  /** Whether the MetricSource has errored */
  metricSourceErrored: Scalars['Boolean']['output'];
  /** Minimum precision to use when displaying the stats */
  minimumDisplayPrecision: Scalars['Int']['output'];
  /** Metric name */
  name?: Maybe<Scalars['String']['output']>;
  /** Associated Organization */
  organization: Organization;
  /** Owner of this Metric */
  owner?: Maybe<User>;
  /** Owner ID of this Metric */
  ownerId?: Maybe<Scalars['ID']['output']>;
  /** True if stats should be based on MTD, QTD, and YTD */
  periodToDate: Scalars['Boolean']['output'];
  /** Whether the metric going up or down indicates success */
  positiveDirection: MetricPositiveDirectionsEnum;
  /** Whether the Metric has recent activity */
  recentActivity?: Maybe<Scalars['Boolean']['output']>;
  /** Are `percentage` format stat values multiplied by 100 for display? */
  redecimalPercentages?: Maybe<Scalars['Boolean']['output']>;
  /** Strategy node id */
  rfId: Scalars['String']['output'];
  /** Metric roll up calculation type */
  rollUp: RollUpEnum;
  /** What operation to use to de-duplicate segmented data points for a single day */
  segmentedDataType: SegmentedDataTypeEnum;
  /** Whether this Metric sends a periodic digest email */
  sendsSubscriptions: Scalars['Boolean']['output'];
  /** Whether to show the description field */
  showDescription?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the technical_description field */
  showTechnicalDescription?: Maybe<Scalars['Boolean']['output']>;
  /** Slack Channel attached to this Metric */
  slackChannel?: Maybe<SlackChannel>;
  /** Slack Channel ID attached to this Metric */
  slackChannelId?: Maybe<Scalars['ID']['output']>;
  /** Immutable Slug for accessing the Metric */
  slug?: Maybe<Scalars['String']['output']>;
  /** Strategy associated source metrics */
  sourceMetrics?: Maybe<Array<Metric>>;
  /** Source name where Metric comes from. Ex: Looker */
  sourceName?: Maybe<Scalars['String']['output']>;
  /** Strategies that the Metric is associated with */
  strategies: Array<Strategy>;
  /** Strategy node data */
  strategyNodeData?: Maybe<StrategyNodeStats>;
  /** Array of Strategy Stats */
  strategyStats: Array<StrategyStat>;
  /** Type of Metric relative to the Strategy.  */
  strategyType?: Maybe<StrategyTypeEnum>;
  /** The recurrence rule for the periodic digest email */
  subscriptionSchedule?: Maybe<Scalars['ICalSchedule']['output']>;
  /** Strategy associated target metrics */
  targetMetrics?: Maybe<Array<Metric>>;
  /** Technical description of metric. How to implement */
  technicalDescription?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Metric */
export type MetricCorrelationsArgs = {
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Metric */
export type MetricEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


/** Metric */
export type MetricMetricDataPointsArgs = {
  cumulative?: InputMaybe<Scalars['CastableBoolean']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  forecast?: InputMaybe<Scalars['CastableBoolean']['input']>;
  interval?: InputMaybe<IntervalEnum>;
  lagDays?: InputMaybe<Scalars['Int']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


/** Metric */
export type MetricSourceMetricsArgs = {
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Metric */
export type MetricStrategyNodeDataArgs = {
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Metric */
export type MetricTargetMetricsArgs = {
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};

/** MetricCollection type */
export type MetricCollection = {
  __typename?: 'MetricCollection';
  /** A collection of paginated MetricCollection */
  collection: Array<Metric>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Input Type for Metric */
export type MetricCreateInput = {
  /** Whether the Metric was generated by AI */
  aiGenerated?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the Metric stat values should be displayed in a compact format */
  compactDisplay?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Whether the data in the Metric is considered a placeholder with no real data */
  containsPlaceholderData?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Metric display format */
  displayFormat?: InputMaybe<DisplayFormatEnum>;
  /** Whether to exclude weekends when calculating stats */
  excludeWeekends?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric external url */
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  /** Metric external url text */
  externalUrlTitle?: InputMaybe<Scalars['String']['input']>;
  /** Metric ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Optional labels to set on the Metric */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Maximum precision to use when displaying stats */
  maximumDisplayPrecision?: InputMaybe<Scalars['Int']['input']>;
  /** Minimum precision to use when displaying stats */
  minimumDisplayPrecision?: InputMaybe<Scalars['Int']['input']>;
  /** Metric name */
  name: Scalars['String']['input'];
  /** ID of the owner of this Metric */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** True if the stat periods should be MTD, QTD, YTD. */
  periodToDate?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Direction the metric should move to indicate success */
  positiveDirection?: InputMaybe<MetricPositiveDirectionsEnum>;
  /** Whether the metric values should be multiplied by 100 for display as percentages */
  redecimalPercentages?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric roll up type */
  rollUp?: InputMaybe<RollUpEnum>;
  /** Segmented data type */
  segmentedDataType?: InputMaybe<SegmentedDataTypeEnum>;
  /** Whether this Metric sends a periodic digest email */
  sendsSubscriptions?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the description field */
  showDescription?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the technical_description field */
  showTechnicalDescription?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric Slack Channel */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
  /** The period for the first strategy stat */
  strategyStatPeriod0?: InputMaybe<Scalars['ISO8601Duration']['input']>;
  /** The period for the second strategy stat */
  strategyStatPeriod1?: InputMaybe<Scalars['ISO8601Duration']['input']>;
  /** The period for the third strategy stat */
  strategyStatPeriod2?: InputMaybe<Scalars['ISO8601Duration']['input']>;
  /** Metric strategy type */
  strategyType?: InputMaybe<Scalars['String']['input']>;
  /** The recurrence rule for the periodic digest email */
  subscriptionSchedule?: InputMaybe<Scalars['ICalSchedule']['input']>;
  /** Metric technical description */
  technicalDescription?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MetricCreate. */
export type MetricCreatePayload = ClassTypeInterface & {
  __typename?: 'MetricCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Metric */
  metric?: Maybe<Metric>;
};

/** Metric Data Point */
export type MetricDataPoint = ClassTypeInterface & SegmentFilterableInterface & {
  __typename?: 'MetricDataPoint';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Date of metric data point */
  date: Scalars['ISO8601Date']['output'];
  /** ID for used through the SegmentFilterable interface. Cannot be null. */
  filterableId: Scalars['ID']['output'];
  /** Is this a forecasted data point? */
  forecasted: Scalars['Boolean']['output'];
  /** ID of object */
  id: Scalars['ID']['output'];
  /** Associated Metric */
  metric: Metric;
  /** ID of associated Metric */
  metricId: Scalars['ID']['output'];
  /** Associated MetricSource */
  metricSource?: Maybe<MetricSource>;
  /** Segment filters for the Metric Data  */
  segmentFilters?: Maybe<Array<SegmentFilter>>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Metric data point Value */
  value: Scalars['Float']['output'];
};

/** MetricDataPointCollection type */
export type MetricDataPointCollection = {
  __typename?: 'MetricDataPointCollection';
  /** A collection of paginated MetricDataPointCollection */
  collection: Array<MetricDataPoint>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Autogenerated input type of MetricDataPointCreate */
export type MetricDataPointCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Data Point occurrence date */
  date: Scalars['String']['input'];
  /** Metric ID for the Data Point */
  metricId: Scalars['ID']['input'];
  /** The hash of segments to set. */
  segments?: InputMaybe<Scalars['JSON']['input']>;
  /** Value of the Data Point */
  value: Scalars['Float']['input'];
};

/** Autogenerated return type of MetricDataPointCreate. */
export type MetricDataPointCreatePayload = ClassTypeInterface & {
  __typename?: 'MetricDataPointCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Metric Data Point */
  metricDataPoint?: Maybe<MetricDataPoint>;
};

/** Autogenerated input type of MetricDataPointDelete */
export type MetricDataPointDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric Data Point ID to delete */
  metricDataPointId: Scalars['ID']['input'];
};

/** Autogenerated return type of MetricDataPointDelete. */
export type MetricDataPointDeletePayload = ClassTypeInterface & {
  __typename?: 'MetricDataPointDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** CRUD operation wrapper */
  crudOperation: TypesMetricDataPointTypeGraphqlCrudOperation;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of MetricDataPointUpdate */
export type MetricDataPointUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Data Point occurrence date */
  date?: InputMaybe<Scalars['String']['input']>;
  /** Metric Data Point ID to update */
  metricDataPointId: Scalars['ID']['input'];
  /** Metric ID */
  metricId?: InputMaybe<Scalars['ID']['input']>;
  /** The hash of segments to set. */
  segments?: InputMaybe<Scalars['JSON']['input']>;
  /** Value of the Data Point */
  value?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated return type of MetricDataPointUpdate. */
export type MetricDataPointUpdatePayload = ClassTypeInterface & {
  __typename?: 'MetricDataPointUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Metric Data Point */
  metricDataPoint?: Maybe<MetricDataPoint>;
};

/** Autogenerated input type of MetricDeleteAllMetricDataPoints */
export type MetricDeleteAllMetricDataPointsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric ID whose data points to delete */
  metricId: Scalars['ID']['input'];
};

/** Autogenerated return type of MetricDeleteAllMetricDataPoints. */
export type MetricDeleteAllMetricDataPointsPayload = ClassTypeInterface & {
  __typename?: 'MetricDeleteAllMetricDataPointsPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Metric that had points deleted */
  metric: Metric;
};

/** Autogenerated input type of MetricDelete */
export type MetricDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric ID to delete */
  metricId: Scalars['ID']['input'];
};

/** Autogenerated return type of MetricDelete. */
export type MetricDeletePayload = ClassTypeInterface & {
  __typename?: 'MetricDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Deleted Metric */
  metric?: Maybe<Metric>;
};

/** Autogenerated input type of MetricDescriptionSuggest */
export type MetricDescriptionSuggestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the metric to update */
  metricId: Scalars['ID']['input'];
};

/** Autogenerated return type of MetricDescriptionSuggest. */
export type MetricDescriptionSuggestPayload = ClassTypeInterface & {
  __typename?: 'MetricDescriptionSuggestPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The returned result */
  result: Scalars['String']['output'];
};

/** Input Type for Metric */
export type MetricInput = {
  /** Whether the Metric was generated by AI */
  aiGenerated?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether the Metric stat values should be displayed in a compact format */
  compactDisplay?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Whether the data in the Metric is considered a placeholder with no real data */
  containsPlaceholderData?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Metric display format */
  displayFormat?: InputMaybe<DisplayFormatEnum>;
  /** Whether to exclude weekends when calculating stats */
  excludeWeekends?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric external url */
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  /** Metric external url text */
  externalUrlTitle?: InputMaybe<Scalars['String']['input']>;
  /** Metric ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Optional labels to set on the Metric */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Maximum precision to use when displaying stats */
  maximumDisplayPrecision?: InputMaybe<Scalars['Int']['input']>;
  /** Minimum precision to use when displaying stats */
  minimumDisplayPrecision?: InputMaybe<Scalars['Int']['input']>;
  /** Metric name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Metric */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** True if the stat periods should be MTD, QTD, YTD. */
  periodToDate?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Direction the metric should move to indicate success */
  positiveDirection?: InputMaybe<MetricPositiveDirectionsEnum>;
  /** Whether the metric values should be multiplied by 100 for display as percentages */
  redecimalPercentages?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric roll up type */
  rollUp?: InputMaybe<RollUpEnum>;
  /** Segmented data type */
  segmentedDataType?: InputMaybe<SegmentedDataTypeEnum>;
  /** Whether this Metric sends a periodic digest email */
  sendsSubscriptions?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the description field */
  showDescription?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the technical_description field */
  showTechnicalDescription?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric Slack Channel */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
  /** The period for the first strategy stat */
  strategyStatPeriod0?: InputMaybe<Scalars['ISO8601Duration']['input']>;
  /** The period for the second strategy stat */
  strategyStatPeriod1?: InputMaybe<Scalars['ISO8601Duration']['input']>;
  /** The period for the third strategy stat */
  strategyStatPeriod2?: InputMaybe<Scalars['ISO8601Duration']['input']>;
  /** Metric strategy type */
  strategyType?: InputMaybe<Scalars['String']['input']>;
  /** The recurrence rule for the periodic digest email */
  subscriptionSchedule?: InputMaybe<Scalars['ICalSchedule']['input']>;
  /** Metric technical description */
  technicalDescription?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of MetricNodesGenerate */
export type MetricNodesGenerateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the upstream node to link the metrics to */
  nodeId: Scalars['ID']['input'];
};

/** Autogenerated return type of MetricNodesGenerate. */
export type MetricNodesGeneratePayload = ClassTypeInterface & {
  __typename?: 'MetricNodesGeneratePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Whether the result was successful */
  success: Scalars['Boolean']['output'];
};

export type MetricOrderingInput = {
  /** Sort by metric_data_points_count */
  metricDataPointsCount?: InputMaybe<SortDirectionEnum>;
  /** Sort by name */
  name?: InputMaybe<SortDirectionEnum>;
  /** Sort by source_name */
  sourceName?: InputMaybe<SortDirectionEnum>;
};

/** Autogenerated input type of MetricPopulateEmpty */
export type MetricPopulateEmptyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Standard deviation */
  stdDeviation?: InputMaybe<Scalars['Float']['input']>;
  /** Strategy id */
  strategyId: Scalars['ID']['input'];
  /** Upward move percentage */
  upwardMovePercentage?: InputMaybe<Scalars['Float']['input']>;
};

/** Autogenerated return type of MetricPopulateEmpty. */
export type MetricPopulateEmptyPayload = ClassTypeInterface & {
  __typename?: 'MetricPopulateEmptyPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Metric Sources */
  metricSources?: Maybe<Array<MetricSource>>;
  /** Updated metrics */
  metrics?: Maybe<Array<Metric>>;
};

/** Metric positive directions enum */
export enum MetricPositiveDirectionsEnum {
  /** Down - Uses when the value of the metric should go down to indicate success, like page load time */
  Down = 'down',
  /** Up - Used when the value of the metric should go up to indicate success, like customer count */
  Up = 'up'
}

/** MetricSource */
export type MetricSource = ClassTypeInterface & {
  __typename?: 'MetricSource';
  /** Inputs for Calculation MetricSources */
  calculatedMetricInputs?: Maybe<Array<CalculatedMetricInput>>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** MetricSource configuration */
  configuration?: Maybe<Scalars['JSON']['output']>;
  /** When the MetricSource was created */
  createdAt?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Associated Credential */
  credential?: Maybe<Credential>;
  /** ID of the attached credential */
  credentialId?: Maybe<Scalars['ID']['output']>;
  /** MetricSource description */
  description?: Maybe<Scalars['String']['output']>;
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** When the MetricSource was last errored */
  erroredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Event name */
  event?: Maybe<Scalars['String']['output']>;
  /** ID of an associated Google Sheet */
  googleSheet?: Maybe<GoogleSheet>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** The time that daily imports happen at */
  importTime: Scalars['String']['output'];
  /** Associated Integration */
  integration?: Maybe<Integration>;
  /** When the MetricSource was last run */
  lastRunAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Associated Metrics */
  metric: Metric;
  /** Associated Metric Data Points */
  metricDataPoints?: Maybe<Array<MetricDataPoint>>;
  /** ID of the associated Metric */
  metricId: Scalars['ID']['output'];
  /** MetricSource name */
  name?: Maybe<Scalars['String']['output']>;
  /** MetricSource Organization */
  organization: Organization;
  /** Source Region, if applicable */
  region?: Maybe<Scalars['String']['output']>;
  /** If the MetricSource updates nightly */
  scheduled: Scalars['Boolean']['output'];
  /** MetricSource slug */
  slug?: Maybe<Scalars['String']['output']>;
  /** MetricSource source name */
  sourceName?: Maybe<Scalars['String']['output']>;
  /** Associated Strategies */
  strategies?: Maybe<Array<Strategy>>;
  /** When the MetricSource was last updated */
  updatedAt?: Maybe<Scalars['ISO8601Date']['output']>;
};

/** Metric Source Activity */
export type MetricSourceActivity = ClassTypeInterface & {
  __typename?: 'MetricSourceActivity';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** When the MetricSourceActivity was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Associated Metric Source */
  metricSource?: Maybe<MetricSource>;
  /** MetricSourceActivity Organization */
  organization: Organization;
  /** MetricSourceActivity Payload */
  payload?: Maybe<Scalars['JSON']['output']>;
  /** MetricSourceActivity processed data points count */
  processedCount: Scalars['Int']['output'];
  /** MetricSourceActivity processed state */
  processedState?: Maybe<MetricSourceActivityProcessedStateEnum>;
  /** MetricSource source name */
  sourceName?: Maybe<Scalars['String']['output']>;
  /** MetricSource total data points count */
  totalCount: Scalars['Int']['output'];
  /** When the MetricSourceActivity was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** MetricSourceActivityCollection type */
export type MetricSourceActivityCollection = {
  __typename?: 'MetricSourceActivityCollection';
  /** A collection of paginated MetricSourceActivityCollection */
  collection: Array<MetricSourceActivity>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** List of metric source processed states */
export enum MetricSourceActivityProcessedStateEnum {
  /** Failed - Finished processing with errors */
  Failed = 'failed',
  /** No Integration - Could not process because there is no integration */
  NoIntegration = 'no_integration',
  /** Processed - Finished processing successfully */
  Processed = 'processed',
  /** Processing - Currently being processed */
  Processing = 'processing',
  /** Unprocessed - Waiting to be processed */
  Unprocessed = 'unprocessed'
}

/** Input Type for MetricSource */
export type MetricSourceCreateInput = {
  /** Calculated Metric Source inputs */
  calculatedMetricInputsAttributes?: InputMaybe<Array<CalculatedMetricInputInput>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric Source configuration JSON */
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  /** Associated metric source credential */
  credentialId?: InputMaybe<Scalars['ID']['input']>;
  /** Metric Source description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Event name */
  event?: InputMaybe<Scalars['String']['input']>;
  /** The time that daily imports happen at */
  importTime?: InputMaybe<Scalars['String']['input']>;
  /** Associated Integration */
  integrationId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of Metric to attach the source to */
  metricId: Scalars['ID']['input'];
  /** Metric Source name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Region (Mixpanel / Amplitude) */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Metric Source source name */
  sourceName?: InputMaybe<Scalars['String']['input']>;
  /** Where clause (Mixpanel) */
  where?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MetricSourceCreate. */
export type MetricSourceCreatePayload = ClassTypeInterface & {
  __typename?: 'MetricSourceCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Metric Source */
  metricSource?: Maybe<MetricSource>;
};

/** Autogenerated input type of MetricSourceDelete */
export type MetricSourceDeleteInput = {
  /** Whether to clear existing data for the source */
  clearExistingData: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric Source ID to delete */
  metricSourceId: Scalars['ID']['input'];
};

/** Autogenerated return type of MetricSourceDelete. */
export type MetricSourceDeletePayload = ClassTypeInterface & {
  __typename?: 'MetricSourceDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** CRUD operation wrapper */
  crudOperation: TypesMetricSourceTypeGraphqlCrudOperation;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Autogenerated input type of MetricSourceRun */
export type MetricSourceRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric Source ID to run */
  metricSourceId: Scalars['ID']['input'];
};

/** Autogenerated return type of MetricSourceRun. */
export type MetricSourceRunPayload = ClassTypeInterface & {
  __typename?: 'MetricSourceRunPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Metric Source the results belong to */
  metricSource?: Maybe<MetricSource>;
  /** Status of the run */
  status: Scalars['String']['output'];
  /** True if the job enqueued */
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MetricSourceTestAttributes */
export type MetricSourceTestAttributesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric Source attributes */
  input: Scalars['JSON']['input'];
};

/** Autogenerated return type of MetricSourceTestAttributes. */
export type MetricSourceTestAttributesPayload = ClassTypeInterface & {
  __typename?: 'MetricSourceTestAttributesPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Test results */
  results: Scalars['JSON']['output'];
};

/** Autogenerated input type of MetricSourceTest */
export type MetricSourceTestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric Source ID to test */
  metricSourceId: Scalars['ID']['input'];
};

/** Autogenerated return type of MetricSourceTest. */
export type MetricSourceTestPayload = ClassTypeInterface & {
  __typename?: 'MetricSourceTestPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Metric Source the results belong to */
  metricSource?: Maybe<MetricSource>;
  /** Test results */
  results: Scalars['JSON']['output'];
};

/** Input Type for MetricSource */
export type MetricSourceUpdateInput = {
  /** Calculated Metric Source inputs */
  calculatedMetricInputsAttributes?: InputMaybe<Array<CalculatedMetricInputInput>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric Source configuration JSON */
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  /** Associated metric source credential */
  credentialId?: InputMaybe<Scalars['ID']['input']>;
  /** Metric Source description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Event name */
  event?: InputMaybe<Scalars['String']['input']>;
  /** The time that daily imports happen at */
  importTime?: InputMaybe<Scalars['String']['input']>;
  /** Associated Integration */
  integrationId?: InputMaybe<Scalars['ID']['input']>;
  /** Metric Source ID to update */
  metricSourceId: Scalars['ID']['input'];
  /** Metric Source name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Region (Mixpanel / Amplitude) */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Metric Source source name */
  sourceName?: InputMaybe<Scalars['String']['input']>;
  /** Where clause (Mixpanel) */
  where?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MetricSourceUpdate. */
export type MetricSourceUpdatePayload = ClassTypeInterface & {
  __typename?: 'MetricSourceUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Metric Source */
  metricSource?: Maybe<MetricSource>;
};

/** Autogenerated input type of MetricTechnicalDescriptionSuggest */
export type MetricTechnicalDescriptionSuggestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the metric to update */
  metricId: Scalars['ID']['input'];
};

/** Autogenerated return type of MetricTechnicalDescriptionSuggest. */
export type MetricTechnicalDescriptionSuggestPayload = ClassTypeInterface & {
  __typename?: 'MetricTechnicalDescriptionSuggestPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The returned result */
  result: Scalars['String']['output'];
};

/** Input Type for Metric */
export type MetricUpdateInput = {
  /** Whether the Metric was generated by AI */
  aiGenerated?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the Metric stat values should be displayed in a compact format */
  compactDisplay?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Whether the data in the Metric is considered a placeholder with no real data */
  containsPlaceholderData?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Metric display format */
  displayFormat?: InputMaybe<DisplayFormatEnum>;
  /** Whether to exclude weekends when calculating stats */
  excludeWeekends?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric external url */
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  /** Metric external url text */
  externalUrlTitle?: InputMaybe<Scalars['String']['input']>;
  /** Metric ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Optional labels to set on the Metric */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Maximum precision to use when displaying stats */
  maximumDisplayPrecision?: InputMaybe<Scalars['Int']['input']>;
  /** Metric ID to update */
  metricId: Scalars['ID']['input'];
  /** Minimum precision to use when displaying stats */
  minimumDisplayPrecision?: InputMaybe<Scalars['Int']['input']>;
  /** Metric name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Metric */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** True if the stat periods should be MTD, QTD, YTD. */
  periodToDate?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Direction the metric should move to indicate success */
  positiveDirection?: InputMaybe<MetricPositiveDirectionsEnum>;
  /** Whether the metric values should be multiplied by 100 for display as percentages */
  redecimalPercentages?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric roll up type */
  rollUp?: InputMaybe<RollUpEnum>;
  /** Segmented data type */
  segmentedDataType?: InputMaybe<SegmentedDataTypeEnum>;
  /** Whether this Metric sends a periodic digest email */
  sendsSubscriptions?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the description field */
  showDescription?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Whether to show the technical_description field */
  showTechnicalDescription?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Metric Slack Channel */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
  /** The period for the first strategy stat */
  strategyStatPeriod0?: InputMaybe<Scalars['ISO8601Duration']['input']>;
  /** The period for the second strategy stat */
  strategyStatPeriod1?: InputMaybe<Scalars['ISO8601Duration']['input']>;
  /** The period for the third strategy stat */
  strategyStatPeriod2?: InputMaybe<Scalars['ISO8601Duration']['input']>;
  /** Metric strategy type */
  strategyType?: InputMaybe<Scalars['String']['input']>;
  /** The recurrence rule for the periodic digest email */
  subscriptionSchedule?: InputMaybe<Scalars['ICalSchedule']['input']>;
  /** Metric technical description */
  technicalDescription?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MetricUpdate. */
export type MetricUpdatePayload = ClassTypeInterface & {
  __typename?: 'MetricUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Metric */
  metric?: Maybe<Metric>;
};

/** Autogenerated input type of MetricUploadBatchFile */
export type MetricUploadBatchFileInput = {
  /** Clear existing data points before adding new ones? */
  clearExistingData?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** File Data Hash */
  shrineHash: Scalars['JSON']['input'];
};

/** Autogenerated return type of MetricUploadBatchFile. */
export type MetricUploadBatchFilePayload = ClassTypeInterface & {
  __typename?: 'MetricUploadBatchFilePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** True if the request succeeded. */
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MetricUploadFile */
export type MetricUploadFileInput = {
  /** Clear existing data points before adding new ones? */
  clearExistingData?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metric ID to add data points to */
  metricId: Scalars['ID']['input'];
  /** File Data Hash */
  shrineHash: Scalars['JSON']['input'];
};

/** Autogenerated return type of MetricUploadFile. */
export type MetricUploadFilePayload = ClassTypeInterface & {
  __typename?: 'MetricUploadFilePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Metric */
  metric?: Maybe<Metric>;
};

/** Multiple objects' autocomplete item types such as basic cards, metrics, strategies, and entities */
export type MultiObjectAutocompleteItem = BasicCard | Entity | Metric | Strategy;

/** Base Mutation Types for DoubleLoop */
export type Mutation = ClassTypeInterface & {
  __typename?: 'Mutation';
  /** Delete an Account */
  accountDelete?: Maybe<AccountDeletePayload>;
  /** Switch to a different Account */
  accountSwitch?: Maybe<AccountSwitchPayload>;
  /** Update an Account */
  accountUpdate?: Maybe<AccountUpdatePayload>;
  /** Add ancillary files to a file cache */
  aiAssistantVectorStoreFilesAdd?: Maybe<AiAssistantVectorStoreFilesAddPayload>;
  /** Remove ancillary files from a file cache */
  aiAssistantVectorStoreFilesRemove?: Maybe<AiAssistantVectorStoreFilesRemovePayload>;
  /** Create a Basic Card */
  basicCardCreate?: Maybe<BasicCardCreatePayload>;
  /** Delete an existing Basic Card */
  basicCardDelete?: Maybe<BasicCardDeletePayload>;
  /** Create a billing subscription */
  billingCreateSubscription?: Maybe<BillingCreateSubscriptionPayload>;
  /** Provision a billing subscription */
  billingProvisionSubscription?: Maybe<BillingProvisionSubscriptionPayload>;
  /** Create a Card Type */
  cardTypeCreate?: Maybe<CardTypeCreatePayload>;
  /** Delete a Card Type */
  cardTypeDelete?: Maybe<CardTypeDeletePayload>;
  /** Update a Card Type */
  cardTypeUpdate?: Maybe<CardTypeUpdatePayload>;
  /** Cancel a running AI chat thread */
  chatCancel?: Maybe<ChatCancelPayload>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Create a Comment */
  commentCreate?: Maybe<CommentCreatePayload>;
  /** Delete an existing Comment */
  commentDelete?: Maybe<CommentDeletePayload>;
  /** Create or update the configuration for correlating a pair of Metrics */
  correlationPairUpdate?: Maybe<CorrelationPairUpdatePayload>;
  /** Test a Credential for validity, via a set of attributes */
  credentialAttributesTest?: Maybe<CredentialAttributesTestPayload>;
  /** Create a Credential */
  credentialCreate?: Maybe<CredentialCreatePayload>;
  /** Delete a Credential */
  credentialDelete?: Maybe<CredentialDeletePayload>;
  /** Test a Credential for validity */
  credentialTest?: Maybe<CredentialTestPayload>;
  /** Update a Credential */
  credentialUpdate?: Maybe<CredentialUpdatePayload>;
  /** Update a Node */
  domainObjectUpdate?: Maybe<DomainObjectUpdatePayload>;
  /** Bulk add and remove labels from domain objects */
  domainObjectsBulkLabel?: Maybe<DomainObjectsBulkLabelPayload>;
  /** Delete domain objects */
  domainObjectsDelete?: Maybe<DomainObjectsDeletePayload>;
  /** Creating a relationship between two nodes for a strategy */
  edgeCreate?: Maybe<EdgeCreatePayload>;
  /** Destroying a relationship between two nodes for a strategy */
  edgeDelete?: Maybe<EdgeDeletePayload>;
  /** Create an entity containment */
  entityContainmentCreate?: Maybe<EntityContainmentCreatePayload>;
  /** Delete an entity containment */
  entityContainmentDelete?: Maybe<EntityContainmentDeletePayload>;
  /** Create an Entity + Event */
  entityCreate?: Maybe<EntityCreatePayload>;
  /** Adds an attachment to an entity */
  entityCreateAttachment?: Maybe<EntityCreateAttachmentPayload>;
  /** Adds a contributor to an entity */
  entityCreateContributor?: Maybe<EntityCreateContributorPayload>;
  /** Delete an existing Entity */
  entityDelete?: Maybe<EntityDeletePayload>;
  /** Removes an attachment from an entity */
  entityDeleteAttachment?: Maybe<EntityDeleteAttachmentPayload>;
  /** Removes a contributor from an entity */
  entityDeleteContributor?: Maybe<EntityDeleteContributorPayload>;
  /** Send an Event to Slack */
  entitySlack?: Maybe<EntitySlackPayload>;
  /** Updates an existing Entity */
  entityUpdate?: Maybe<EntityUpdatePayload>;
  /** Create a Favorite */
  favoriteCreate?: Maybe<FavoriteCreatePayload>;
  /** Delete a Favorite */
  favoriteDelete?: Maybe<FavoriteDeletePayload>;
  /** Create a Goal */
  goalCreate?: Maybe<GoalCreatePayload>;
  /** Delete a Goal */
  goalDelete?: Maybe<GoalDeletePayload>;
  /** Update a Goal */
  goalUpdate?: Maybe<GoalUpdatePayload>;
  /** Create metrics for all Google Analytics metrics of the given GA4 property */
  googleAnalyticsPropertyImport?: Maybe<GoogleAnalyticsPropertyImportPayload>;
  /** Delete a Google Sheet. This does not delete it from Google. */
  googleSheetDelete?: Maybe<GoogleSheetDeletePayload>;
  /** Import a Google Sheet into the current user's Organization */
  googleSheetImport?: Maybe<GoogleSheetImportPayload>;
  /** Create an Integration */
  integrationCreate?: Maybe<IntegrationCreatePayload>;
  /** Import history for an Integration */
  integrationImportHistory?: Maybe<IntegrationImportHistoryPayload>;
  /** Update an existing Integration */
  integrationUpdate?: Maybe<IntegrationUpdatePayload>;
  /** Create an Invitation */
  invitationCreate?: Maybe<InvitationCreatePayload>;
  /** Deletes an existing Invitation */
  invitationDelete?: Maybe<InvitationDeletePayload>;
  /** Resends an existing Invitation */
  invitationResend?: Maybe<InvitationResendPayload>;
  /** Add a Label to a domain object */
  labelCreate?: Maybe<LabelCreatePayload>;
  /** Remove a Label from a domain object */
  labelDelete?: Maybe<LabelDeletePayload>;
  /** Clone a Strategy Map */
  mapClone?: Maybe<MapClonePayload>;
  /** Clone a Strategy to another Organization */
  mapCloneToOrganization?: Maybe<MapCloneToOrganizationPayload>;
  /** Moves nodes to a new map */
  mapCreateFromExisting?: Maybe<MapCreateFromExistingPayload>;
  /** Create a Metric */
  metricCreate?: Maybe<MetricCreatePayload>;
  /** Create a Metric Data Point */
  metricDataPointCreate?: Maybe<MetricDataPointCreatePayload>;
  /** Delete a Metric Data Point */
  metricDataPointDelete?: Maybe<MetricDataPointDeletePayload>;
  /** Update a Metric Data Point */
  metricDataPointUpdate?: Maybe<MetricDataPointUpdatePayload>;
  /** Delete a Metric */
  metricDelete?: Maybe<MetricDeletePayload>;
  /** Delete all of a Metric's data points */
  metricDeleteAllMetricDataPoints?: Maybe<MetricDeleteAllMetricDataPointsPayload>;
  /** Suggest a Metric description, based on an AI prompt */
  metricDescriptionSuggest?: Maybe<MetricDescriptionSuggestPayload>;
  /** Generate Metric Nodes via AI prompt */
  metricNodesGenerate?: Maybe<MetricNodesGeneratePayload>;
  /** Populate empty metrics */
  metricPopulateEmpty?: Maybe<MetricPopulateEmptyPayload>;
  /** Create a Metric Source */
  metricSourceCreate?: Maybe<MetricSourceCreatePayload>;
  /** Delete a Metric Source */
  metricSourceDelete?: Maybe<MetricSourceDeletePayload>;
  /** Run a Metric Source */
  metricSourceRun?: Maybe<MetricSourceRunPayload>;
  /** Test a Metric Source */
  metricSourceTest?: Maybe<MetricSourceTestPayload>;
  /** Test a Metric Source via attributes */
  metricSourceTestAttributes?: Maybe<MetricSourceTestAttributesPayload>;
  /** Update a Metric Source */
  metricSourceUpdate?: Maybe<MetricSourceUpdatePayload>;
  /** Suggest a Metric technical_description, based on an AI prompt */
  metricTechnicalDescriptionSuggest?: Maybe<MetricTechnicalDescriptionSuggestPayload>;
  /** Update a Metric */
  metricUpdate?: Maybe<MetricUpdatePayload>;
  /** Add data points to multiple metric via CSV */
  metricUploadBatchFile?: Maybe<MetricUploadBatchFilePayload>;
  /** Add data points to a metric via CSV */
  metricUploadFile?: Maybe<MetricUploadFilePayload>;
  /** Create a Node */
  nodeCreate?: Maybe<NodeCreatePayload>;
  /** Duplicate one or more nodes, *AND* their domain objects */
  nodesDuplicate?: Maybe<NodesDuplicatePayload>;
  /** Create an Organization */
  organizationCreate?: Maybe<OrganizationCreatePayload>;
  /** Update an existing Organization */
  organizationUpdate?: Maybe<OrganizationUpdatePayload>;
  /** Run a pipeline */
  pipelineRun?: Maybe<PipelineRunPayload>;
  /** Apply a Playbook to the current Organization */
  playbookApply?: Maybe<PlaybookApplyPayload>;
  /** Apply a Playbook to an existing Strategy, without affecting the existing nodes */
  playbookApplyToStrategy?: Maybe<PlaybookApplyToStrategyPayload>;
  /** Create a Playbook from a Strategy */
  playbookCreateFromStrategy?: Maybe<PlaybookCreateFromStrategyPayload>;
  /** Submit a Playbook for review, to have it published */
  playbookSubmitForReview?: Maybe<PlaybookSubmitForReviewPayload>;
  /** Suggest Product Initiatives */
  productInitiativesSuggest?: Maybe<ProductInitiativesSuggestPayload>;
  /** Create a RecurringReportConfiguration */
  recurringReportConfigurationCreate?: Maybe<RecurringReportConfigurationCreatePayload>;
  /** Run a RecurringReportConfiguration */
  recurringReportConfigurationRun?: Maybe<RecurringReportConfigurationRunPayload>;
  /** Update a RecurringReportConfiguration */
  recurringReportConfigurationUpdate?: Maybe<RecurringReportConfigurationUpdatePayload>;
  /** Create a Report */
  reportCreate?: Maybe<ReportCreatePayload>;
  /** Send a Report */
  reportSend?: Maybe<ReportSendPayload>;
  /** Update a Report */
  reportUpdate?: Maybe<ReportUpdatePayload>;
  /** Create a Roadmap Item */
  roadmapItemCreate?: Maybe<RoadmapItemCreatePayload>;
  /** Delete a Roadmap Item */
  roadmapItemDelete?: Maybe<RoadmapItemDeletePayload>;
  /** Update a Roadmap Item */
  roadmapItemUpdate?: Maybe<RoadmapItemUpdatePayload>;
  /** Create one or more Segment Filters */
  segmentFiltersCreate?: Maybe<SegmentFiltersCreatePayload>;
  /** Delete one or more Segment Filters */
  segmentFiltersDelete?: Maybe<SegmentFiltersDeletePayload>;
  /** Create a Strategy */
  strategyCreate?: Maybe<StrategyCreatePayload>;
  /** Delete a Strategy */
  strategyDelete?: Maybe<StrategyDeletePayload>;
  /** Suggest Strategy Metrics */
  strategyMetricsSuggest?: Maybe<StrategyMetricsSuggestPayload>;
  /** Delete a Strategy Node */
  strategyNodeDelete?: Maybe<StrategyNodeDeletePayload>;
  /** Update a Strategy Node data, one or more. Useful for position updates. */
  strategyNodeUpdate?: Maybe<StrategyNodeUpdatePayload>;
  /** Use a playbook template to populate an existing strategy */
  strategyPopulateFromPlaybookTemplate?: Maybe<StrategyPopulateFromPlaybookTemplatePayload>;
  /** Send the Strategy digest to Slack */
  strategyReviewSend?: Maybe<StrategyReviewSendPayload>;
  /** Update a Strategy */
  strategyUpdate?: Maybe<StrategyUpdatePayload>;
  /** Generate a ticket to use the streaming upload proxy */
  uploadTicketGenerate?: Maybe<UploadTicketGeneratePayload>;
  /** Authenticate a User */
  userAuthenticate?: Maybe<UserAuthenticatePayload>;
  /** Send a new confirm email email */
  userConfirmEmail?: Maybe<UserConfirmEmailPayload>;
  /** Create a User */
  userCreate?: Maybe<UserCreatePayload>;
  /** Capture email to create a User via Identity */
  userEmailCapture?: Maybe<UserEmailCapturePayload>;
  /** Reset a user's password */
  userResetPassword?: Maybe<UserResetPasswordPayload>;
  /** Create a request to reset a user's password */
  userResetPasswordRequest?: Maybe<UserResetPasswordRequestPayload>;
  /** Updates a User */
  userUpdate?: Maybe<UserUpdatePayload>;
};


/** Base Mutation Types for DoubleLoop */
export type MutationAccountDeleteArgs = {
  input: AccountDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationAccountSwitchArgs = {
  input: AccountSwitchInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationAccountUpdateArgs = {
  input: AccountUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationAiAssistantVectorStoreFilesAddArgs = {
  input: AiAssistantVectorStoreFilesAddInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationAiAssistantVectorStoreFilesRemoveArgs = {
  input: AiAssistantVectorStoreFilesRemoveInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationBasicCardCreateArgs = {
  input: BasicCardCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationBasicCardDeleteArgs = {
  input: BasicCardDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationBillingCreateSubscriptionArgs = {
  input: BillingCreateSubscriptionInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationBillingProvisionSubscriptionArgs = {
  input: BillingProvisionSubscriptionInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCardTypeCreateArgs = {
  input: CardTypeCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCardTypeDeleteArgs = {
  input: CardTypeDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCardTypeUpdateArgs = {
  input: CardTypeUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationChatCancelArgs = {
  input: ChatCancelInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCommentCreateArgs = {
  input: CommentCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCommentDeleteArgs = {
  input: CommentDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCorrelationPairUpdateArgs = {
  input: CorrelationPairUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCredentialAttributesTestArgs = {
  input: CredentialAttributesTestInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCredentialCreateArgs = {
  input: CredentialCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCredentialDeleteArgs = {
  input: CredentialDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCredentialTestArgs = {
  input: CredentialTestInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationCredentialUpdateArgs = {
  input: CredentialUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationDomainObjectUpdateArgs = {
  input: DomainObjectUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationDomainObjectsBulkLabelArgs = {
  input: DomainObjectsBulkLabelInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationDomainObjectsDeleteArgs = {
  input: DomainObjectsDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEdgeCreateArgs = {
  input: EdgeCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEdgeDeleteArgs = {
  input: EdgeDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntityContainmentCreateArgs = {
  input: EntityContainmentCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntityContainmentDeleteArgs = {
  input: EntityContainmentDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntityCreateArgs = {
  input: EntityCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntityCreateAttachmentArgs = {
  input: EntityCreateAttachmentInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntityCreateContributorArgs = {
  input: EntityCreateContributorInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntityDeleteArgs = {
  input: EntityDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntityDeleteAttachmentArgs = {
  input: EntityDeleteAttachmentInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntityDeleteContributorArgs = {
  input: EntityDeleteContributorInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntitySlackArgs = {
  input: EntitySlackInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationEntityUpdateArgs = {
  input: EntityUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationFavoriteCreateArgs = {
  input: FavoriteCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationFavoriteDeleteArgs = {
  input: FavoriteDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationGoalCreateArgs = {
  input: GoalCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationGoalDeleteArgs = {
  input: GoalDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationGoalUpdateArgs = {
  input: GoalUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationGoogleAnalyticsPropertyImportArgs = {
  input: GoogleAnalyticsPropertyImportInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationGoogleSheetDeleteArgs = {
  input: GoogleSheetDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationGoogleSheetImportArgs = {
  input: GoogleSheetImportInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationIntegrationCreateArgs = {
  input: IntegrationCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationIntegrationImportHistoryArgs = {
  input: IntegrationImportHistoryInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationIntegrationUpdateArgs = {
  input: IntegrationUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationInvitationCreateArgs = {
  input: InvitationCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationInvitationDeleteArgs = {
  input: InvitationDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationInvitationResendArgs = {
  input: InvitationResendInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationLabelCreateArgs = {
  input: LabelCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationLabelDeleteArgs = {
  input: LabelDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMapCloneArgs = {
  input: MapCloneInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMapCloneToOrganizationArgs = {
  input: MapCloneToOrganizationInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMapCreateFromExistingArgs = {
  input: MapCreateFromExistingInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricCreateArgs = {
  input: MetricCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricDataPointCreateArgs = {
  input: MetricDataPointCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricDataPointDeleteArgs = {
  input: MetricDataPointDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricDataPointUpdateArgs = {
  input: MetricDataPointUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricDeleteArgs = {
  input: MetricDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricDeleteAllMetricDataPointsArgs = {
  input: MetricDeleteAllMetricDataPointsInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricDescriptionSuggestArgs = {
  input: MetricDescriptionSuggestInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricNodesGenerateArgs = {
  input: MetricNodesGenerateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricPopulateEmptyArgs = {
  input: MetricPopulateEmptyInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricSourceCreateArgs = {
  input: MetricSourceCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricSourceDeleteArgs = {
  input: MetricSourceDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricSourceRunArgs = {
  input: MetricSourceRunInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricSourceTestArgs = {
  input: MetricSourceTestInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricSourceTestAttributesArgs = {
  input: MetricSourceTestAttributesInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricSourceUpdateArgs = {
  input: MetricSourceUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricTechnicalDescriptionSuggestArgs = {
  input: MetricTechnicalDescriptionSuggestInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricUpdateArgs = {
  input: MetricUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricUploadBatchFileArgs = {
  input: MetricUploadBatchFileInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationMetricUploadFileArgs = {
  input: MetricUploadFileInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationNodeCreateArgs = {
  input: NodeCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationNodesDuplicateArgs = {
  input: NodesDuplicateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationOrganizationCreateArgs = {
  input: OrganizationCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationPipelineRunArgs = {
  input: PipelineRunInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationPlaybookApplyArgs = {
  input: PlaybookApplyInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationPlaybookApplyToStrategyArgs = {
  input: PlaybookApplyToStrategyInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationPlaybookCreateFromStrategyArgs = {
  input: PlaybookCreateFromStrategyInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationPlaybookSubmitForReviewArgs = {
  input: PlaybookSubmitForReviewInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationProductInitiativesSuggestArgs = {
  input: ProductInitiativesSuggestInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationRecurringReportConfigurationCreateArgs = {
  input: RecurringReportConfigurationCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationRecurringReportConfigurationRunArgs = {
  input: RecurringReportConfigurationRunInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationRecurringReportConfigurationUpdateArgs = {
  input: RecurringReportConfigurationUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationReportCreateArgs = {
  input: ReportCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationReportSendArgs = {
  input: ReportSendInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationReportUpdateArgs = {
  input: ReportUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationRoadmapItemCreateArgs = {
  input: RoadmapItemCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationRoadmapItemDeleteArgs = {
  input: RoadmapItemDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationRoadmapItemUpdateArgs = {
  input: RoadmapItemUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationSegmentFiltersCreateArgs = {
  input: SegmentFiltersCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationSegmentFiltersDeleteArgs = {
  input: SegmentFiltersDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationStrategyCreateArgs = {
  input: StrategyCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationStrategyDeleteArgs = {
  input: StrategyDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationStrategyMetricsSuggestArgs = {
  input: StrategyMetricsSuggestInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationStrategyNodeDeleteArgs = {
  input: StrategyNodeDeleteInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationStrategyNodeUpdateArgs = {
  input: StrategyNodeUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationStrategyPopulateFromPlaybookTemplateArgs = {
  input: StrategyPopulateFromPlaybookTemplateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationStrategyReviewSendArgs = {
  input: StrategyReviewSendInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationStrategyUpdateArgs = {
  input: StrategyUpdateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationUploadTicketGenerateArgs = {
  input: UploadTicketGenerateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationUserAuthenticateArgs = {
  input: UserAuthenticateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationUserConfirmEmailArgs = {
  input: UserConfirmEmailInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationUserEmailCaptureArgs = {
  input: UserEmailCaptureInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationUserResetPasswordArgs = {
  input: UserResetPasswordInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationUserResetPasswordRequestArgs = {
  input: UserResetPasswordRequestInput;
};


/** Base Mutation Types for DoubleLoop */
export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};

/** Node */
export type Node = ClassTypeInterface & {
  __typename?: 'Node';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object data */
  data: Scalars['JSON']['output'];
  /** Object height */
  height?: Maybe<Scalars['Int']['output']>;
  /** Whether the node is hidden */
  hidden: Scalars['Boolean']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Object metadata, such as colors */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** Node ID */
  nodeId?: Maybe<Scalars['ID']['output']>;
  /** Parent node in react flow structure */
  parentNode?: Maybe<Scalars['String']['output']>;
  /** Object position */
  position: Scalars['JSON']['output'];
  /** associated Strategy */
  strategy?: Maybe<Strategy>;
  /** Strategy ID */
  strategyId: Scalars['ID']['output'];
  /** Object type */
  type: Scalars['String']['output'];
  /** Object width */
  width?: Maybe<Scalars['Int']['output']>;
  /** Object z-index */
  zIndex: Scalars['Int']['output'];
};

/** Autogenerated input type of NodeCreate */
export type NodeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Dimensions of the object */
  dimensions?: InputMaybe<DimensionsInput>;
  /** The object to associate */
  object: NodeObjectInput;
  /** Position on the strategy */
  position?: InputMaybe<PositionInput>;
  /** The ID of the Strategy to associate */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of NodeCreate. */
export type NodeCreatePayload = ClassTypeInterface & {
  __typename?: 'NodeCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Created node */
  node?: Maybe<Node>;
  /** Created node_object */
  nodeObject?: Maybe<NodeObject>;
  /** Updated strategy */
  strategy: Strategy;
};

/** Input Type for Node Filters */
export type NodeFiltersInput = {
  /** Labels to filter Nodes on. Multiple labels will be handled as an "OR". */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Node name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input Type for Node */
export type NodeInput = {
  /** Dimensions of the object */
  dimensions?: InputMaybe<DimensionsInput>;
  /** Object unique ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Object metadata, such as colors */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Parent node in react flow structure */
  parentNodeId?: InputMaybe<Scalars['ID']['input']>;
  /** Position on the strategy */
  position?: InputMaybe<PositionInput>;
  /** Strategy ID */
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Node Object Types */
export type NodeObject = BasicCard | CommentThread | Entity | MapImage | Metric | Note | Section | Strategy;

/** Input Type for Node */
export type NodeObjectInput =
  /** Basic Card */
  { basicCard: BasicCardInput; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Card Type */
  { basicCard?: never; cardType: CardTypeInput; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Comment */
  { basicCard?: never; cardType?: never; comment: CommentInput; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Comment Thread */
  { basicCard?: never; cardType?: never; comment?: never; commentThread: CommentThreadInput; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Edge */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge: EdgeInput; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Entity */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity: EntityInput; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Existing Node */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing: ExistingDomainObjectInput; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Map Image */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage: MapImageInput; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Metric */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric: MetricInput; node?: never; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Node */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node: NodeInput; note?: never; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Note */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note: NoteInput; report?: never; section?: never; strategy?: never; work?: never; }
  |  /** Report */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report: ReportInput; section?: never; strategy?: never; work?: never; }
  |  /** Section */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section: SectionInput; strategy?: never; work?: never; }
  |  /** Strategy */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy: StrategyInput; work?: never; }
  |  /** Entity */
  { basicCard?: never; cardType?: never; comment?: never; commentThread?: never; edge?: never; entity?: never; existing?: never; mapImage?: never; metric?: never; node?: never; note?: never; report?: never; section?: never; strategy?: never; work: EntityInput; };

/** Autogenerated input type of NodesDuplicate */
export type NodesDuplicateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ids of the nodes to duplicate */
  nodeIds: Array<Scalars['ID']['input']>;
  /** The x offset for the duplicated nodes */
  offsetX?: InputMaybe<Scalars['Int']['input']>;
  /** The y offset for the duplicated nodes */
  offsetY?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the strategy to duplicate the nodes into */
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of NodesDuplicate. */
export type NodesDuplicatePayload = ClassTypeInterface & {
  __typename?: 'NodesDuplicatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The duplicated edges */
  edges?: Maybe<Array<Edge>>;
  /** Array of Errors */
  errors: Array<Error>;
  /** The duplicated nodes */
  nodes?: Maybe<Array<Node>>;
};

/** Note */
export type Note = ClassTypeInterface & RfNodeInterface & {
  __typename?: 'Note';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Strategy node id */
  rfId: Scalars['String']['output'];
  /** Label name */
  text?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Input Type for Notes */
export type NoteInput = {
  /** Note ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The text of the note */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Label / Value Option Pair */
export type Option = ClassTypeInterface & {
  __typename?: 'Option';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Label of the Option */
  label: Scalars['String']['output'];
  /** Value of the Option */
  value: Scalars['String']['output'];
};

/** Organization */
export type Organization = ClassTypeInterface & {
  __typename?: 'Organization';
  /** Customer Subscription */
  activeSubscription?: Maybe<CustomerSubscription>;
  /** API Token for sending webhooks */
  apiToken?: Maybe<Scalars['String']['output']>;
  /** Automatically import Google Sheet data */
  autoImportGoogleSheet: Scalars['Boolean']['output'];
  /** Organization wide Card Types */
  cardTypes?: Maybe<Array<CardType>>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Get correlation information for a pair of Organization Metrics */
  correlate?: Maybe<CorrelationPair>;
  /** Correlation scores for each pair of Metrics in the Organization */
  correlationPairs: Array<CorrelationPair>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** User who created Organization */
  creator?: Maybe<User>;
  /** The default time that new Metric Sources import_time is set to */
  defaultImportTime: Scalars['String']['output'];
  /** List of sources we have entities for */
  eventSources?: Maybe<Array<Scalars['String']['output']>>;
  /** Google Sheet associated with the organization */
  googleSheet?: Maybe<GoogleSheet>;
  /** Organization homepage URL */
  homepageUrl?: Maybe<Scalars['String']['output']>;
  /** ID of object */
  id?: Maybe<Scalars['ID']['output']>;
  /** SSO x.509 IdP Certification */
  idpCert?: Maybe<Scalars['String']['output']>;
  /** SSO Entity ID */
  idpEntityId?: Maybe<Scalars['String']['output']>;
  /** SSO Service URL */
  idpSsoServiceUrl?: Maybe<Scalars['String']['output']>;
  /** Organizations integration records */
  integrations?: Maybe<Array<Integration>>;
  /** SSO Issuer */
  issuer?: Maybe<Scalars['String']['output']>;
  /** Count of Jira entities */
  jiraCount?: Maybe<Scalars['Int']['output']>;
  /** URL of Logo */
  logo?: Maybe<Scalars['String']['output']>;
  /** List of metric sources associated with the organization */
  metricSources?: Maybe<Array<MetricSource>>;
  /** Organization name */
  name?: Maybe<Scalars['String']['output']>;
  /** Organization primary color for changelog customizations */
  primaryColor?: Maybe<Scalars['String']['output']>;
  /** Count of Shortcut entities */
  shortcutCount?: Maybe<Scalars['Int']['output']>;
  /** Slack integration present */
  slackable: Scalars['Boolean']['output'];
  /** SSO Identifier for logging in */
  ssoIdentifier?: Maybe<Scalars['String']['output']>;
  /** SSO Provider Name */
  ssoProvider?: Maybe<Scalars['String']['output']>;
  /** List of strategies associated with the organization */
  strategies?: Maybe<Array<Strategy>>;
  /** Organization Twitter handle */
  twitterUrl?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Organization */
export type OrganizationCorrelateArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['ID']['input'];
};


/** Organization */
export type OrganizationCorrelationPairsArgs = {
  idPairs?: InputMaybe<Array<CorrelationPairIdInput>>;
};

/** Autogenerated input type of OrganizationCreate */
export type OrganizationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Homepage URL for the new organization */
  homepageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Name for the new organization */
  name: Scalars['String']['input'];
};

/** Autogenerated return type of OrganizationCreate. */
export type OrganizationCreatePayload = ClassTypeInterface & {
  __typename?: 'OrganizationCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created organization */
  organization?: Maybe<Organization>;
  /** Strategy created for the newly created organization */
  strategy?: Maybe<Strategy>;
};

/** Autogenerated return type of OrganizationCrud. */
export type OrganizationCrudPayload = ClassTypeInterface & {
  __typename?: 'OrganizationCrudPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** The object that was affected */
  data: DomainObject;
  /** The operation that was performed */
  operation: CrudOperationEnum;
};

/** Autogenerated input type of OrganizationUpdate */
export type OrganizationUpdateInput = {
  /** Automatically import Google Sheet */
  autoImportGoogleSheet?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The time that daily Metric Source imports happen at */
  defaultImportTime?: InputMaybe<Scalars['String']['input']>;
  /** Organization homepage */
  homepageUrl?: InputMaybe<Scalars['String']['input']>;
  /** SSO x.509 IdP Certification */
  idpCert?: InputMaybe<Scalars['String']['input']>;
  /** SSO Entity ID */
  idpEntityId?: InputMaybe<Scalars['String']['input']>;
  /** SSO Service URL */
  idpSsoServiceUrl?: InputMaybe<Scalars['String']['input']>;
  /** SSO Issuer */
  issuer?: InputMaybe<Scalars['String']['input']>;
  /** Logo File Data Hash */
  logo?: InputMaybe<Scalars['JSON']['input']>;
  /** Organization name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Organization ID to update */
  organizationId: Scalars['ID']['input'];
  /** Organization primary color */
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  /** SSO Provider Name */
  ssoProvider?: InputMaybe<Scalars['String']['input']>;
  /** Organization twitter url */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OrganizationUpdate. */
export type OrganizationUpdatePayload = ClassTypeInterface & {
  __typename?: 'OrganizationUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated organization */
  organization?: Maybe<Organization>;
};

/** List of possible Period Unit values on Goals */
export enum PeriodUnitEnum {
  /** All - The Goal is for all time */
  All = 'all',
  /** Custom - The Goal is for a custom date range */
  Custom = 'custom',
  /** Month - The Goal is for a specific month */
  Month = 'month',
  /** Quarter - The Goal is for a specific quarter */
  Quarter = 'quarter',
  /** Year - The Goal is for a specific year */
  Year = 'year'
}

/** Pipeline names enum */
export enum PipelineEnum {
  /** Chat with a data scientist bot */
  DataScientistChat = 'data_scientist_chat',
  /** Generate a KPI tree using the KPI Tree Generator */
  KpiTreeGenerator = 'kpi_tree_generator',
  /** Generate a map using the Map Generator */
  MapGenerator = 'map_generator',
  /** Analyze the relationship between metrics using the Metric Relationship Analyzer */
  MetricRelationshipAnalyzer = 'metric_relationship_analyzer',
  /** Generate a report summary using the Report Summarizer */
  ReportSummarizer = 'report_summarizer'
}

/** Autogenerated input type of PipelineRun */
export type PipelineRunInput = {
  /** ID of the chat to run the pipeline on. If not given, a new chat will be created. */
  chatId?: InputMaybe<Scalars['ID']['input']>;
  /** Object to run the pipeline on */
  chattable: NodeObjectInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** File IDs to attach to the prompt */
  fileIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Pipeline to apply */
  pipelineId: PipelineEnum;
  /** Prompt to start the chat with */
  prompt?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PipelineRun. */
export type PipelineRunPayload = ClassTypeInterface & {
  __typename?: 'PipelineRunPayload';
  /** Chat created for the pipeline */
  chat?: Maybe<Chat>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
};

/** Playbook */
export type Playbook = ClassTypeInterface & {
  __typename?: 'Playbook';
  /** Playbook category */
  category?: Maybe<Scalars['String']['output']>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Playbook description */
  description?: Maybe<Scalars['String']['output']>;
  /** Friendly ID for the playbook */
  friendlyId?: Maybe<Scalars['String']['output']>;
  /** Playbook hero image URL */
  hero?: Maybe<Scalars['String']['output']>;
  /** Playbook ID */
  id: Scalars['ID']['output'];
  /** Playbook title */
  name: Scalars['String']['output'];
  /** Playbook creator organization */
  organization?: Maybe<Organization>;
  /** Playbook template */
  playbookTemplate: PlaybookTemplate;
  /** Playbook publication state */
  publicationState: PublicationStateEnum;
  /** Playbook description */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** Playbook creator */
  user?: Maybe<User>;
};

/** Autogenerated input type of PlaybookApply */
export type PlaybookApplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Playbook ID to apply */
  playbookId: Scalars['ID']['input'];
};

/** Autogenerated return type of PlaybookApply. */
export type PlaybookApplyPayload = ClassTypeInterface & {
  __typename?: 'PlaybookApplyPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Strategy */
  strategy?: Maybe<Strategy>;
};

/** Autogenerated input type of PlaybookApplyToStrategy */
export type PlaybookApplyToStrategyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Offset X for the new nodes */
  offsetX?: InputMaybe<Scalars['Int']['input']>;
  /** Offset Y for the new nodes */
  offsetY?: InputMaybe<Scalars['Int']['input']>;
  /** Playbook ID to apply */
  playbookId: Scalars['ID']['input'];
  /** Strategy ID to apply the playbook to */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of PlaybookApplyToStrategy. */
export type PlaybookApplyToStrategyPayload = ClassTypeInterface & {
  __typename?: 'PlaybookApplyToStrategyPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Strategy */
  strategy?: Maybe<Strategy>;
};

/** Autogenerated input type of PlaybookCreateFromStrategy */
export type PlaybookCreateFromStrategyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the Strategy that will be dumped into a Playbook */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of PlaybookCreateFromStrategy. */
export type PlaybookCreateFromStrategyPayload = ClassTypeInterface & {
  __typename?: 'PlaybookCreateFromStrategyPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Playbook */
  playbook?: Maybe<Playbook>;
};

/** Autogenerated input type of PlaybookSubmitForReview */
export type PlaybookSubmitForReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the playbook to submit for review */
  playbookId: Scalars['ID']['input'];
};

/** Autogenerated return type of PlaybookSubmitForReview. */
export type PlaybookSubmitForReviewPayload = ClassTypeInterface & {
  __typename?: 'PlaybookSubmitForReviewPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of errors */
  errors: Array<Error>;
  /** The updated Playbook */
  playbook?: Maybe<Playbook>;
};

/** Playbook template */
export type PlaybookTemplate = ClassTypeInterface & {
  __typename?: 'PlaybookTemplate';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Playbook template edges */
  edges: Array<Scalars['JSON']['output']>;
  /** Playbook template entities */
  entities: Scalars['JSON']['output'];
  /** Playbook template labels */
  labels: Scalars['JSON']['output'];
  /** Playbook template metrics */
  metrics: Scalars['JSON']['output'];
};

/** Position */
export type PositionInput = {
  /** X Coordinate */
  x: Scalars['Int']['input'];
  /** Y Coordinate */
  y: Scalars['Int']['input'];
};

/** Autogenerated input type of ProductInitiativesSuggest */
export type ProductInitiativesSuggestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the subject Node */
  nodeId: Scalars['ID']['input'];
};

/** Autogenerated return type of ProductInitiativesSuggest. */
export type ProductInitiativesSuggestPayload = ClassTypeInterface & {
  __typename?: 'ProductInitiativesSuggestPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The error, if any, returned by OpenAI */
  error?: Maybe<Scalars['String']['output']>;
  /** The suggestions, returned by OpenAI */
  result?: Maybe<Array<Scalars['String']['output']>>;
};

/** List of Playbook publication states */
export enum PublicationStateEnum {
  /** In review for publication */
  PendingReview = 'pending_review',
  /** Private to the Organization */
  Private = 'private',
  /** Published, and available publicly */
  Published = 'published'
}

/** Master list of queries */
export type Query = ClassTypeInterface & {
  __typename?: 'Query';
  /** All active accounts for an Organization */
  accounts?: Maybe<Array<Account>>;
  /** Get the event names for an Amplitude metric source */
  amplitudeMetricSourceEventNames?: Maybe<Array<Option>>;
  /** Returns a single Basic Card for an Organization */
  basicCard?: Maybe<BasicCard>;
  /** The upstream Entities of the passed BasicCard */
  basicCardEntities?: Maybe<EntityCollection>;
  /** Basic cards for the current organization */
  basicCards?: Maybe<BasicCardCollection>;
  /** A dummy field to expose the BroadcastEventsEnum type, for codegen */
  broadcastEventsEnum: BroadcastEventsEnum;
  /** All Card Types scoped to an Organization */
  cardTypes?: Maybe<Array<CardType>>;
  /** A single chat */
  chat?: Maybe<Chat>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Look up Contributor by searchable id */
  contributor?: Maybe<Contributor>;
  /** Autocomplete endpoint for Contributors */
  contributorsAutocomplete?: Maybe<Array<Contributor>>;
  /** Get the data for a specific Credential */
  credential?: Maybe<Credential>;
  /** Credentials for an Organization */
  credentials?: Maybe<Array<Credential>>;
  /** Work items for the current organization */
  entities?: Maybe<EntityCollection>;
  /** A single Entity by ID scoped to an Organization ID */
  entity?: Maybe<Entity>;
  /** The contained under an entity */
  entityEntities?: Maybe<EntityCollection>;
  /** Get the event data for the graph */
  eventsGraph?: Maybe<Scalars['JSON']['output']>;
  /** All of the User's Favorites */
  favorites: Array<Favorite>;
  /** Get a single Goal */
  goal?: Maybe<Goal>;
  /** Google Integration */
  googleIntegration?: Maybe<GoogleIntegration>;
  /** A single Google Sheet */
  googleSheet?: Maybe<GoogleSheet>;
  /** Get the Identity ID of a logged-out user */
  identityId?: Maybe<Scalars['ID']['output']>;
  /** Get the impactful events for the graph */
  impactfulEntities?: Maybe<Array<Entity>>;
  /** Return integration for an organization by name */
  integration?: Maybe<IntegrationUnion>;
  /** Return all Integrations for an organization */
  integrations?: Maybe<Array<IntegrationUnion>>;
  /** An unused invitation for an Organization */
  invitation?: Maybe<Invitation>;
  /** All unused invitations for an Organization */
  invitations?: Maybe<Array<Invitation>>;
  /** All labels scoped to an Organization */
  labels?: Maybe<Array<Label>>;
  /** Autocomplete endpoint for Labels */
  labelsAutocomplete?: Maybe<Array<Label>>;
  /** Get the Looker Look fields for the given Credential and Look */
  lookerApiLookFields?: Maybe<Array<Option>>;
  /** Get the Looker Look options for the given Credential */
  lookerApiLooks?: Maybe<Array<Option>>;
  /** Get the currently logged-in user */
  me?: Maybe<User>;
  /** Autocomplete endpoint for Members */
  membersAutocomplete?: Maybe<Array<User>>;
  /** Get the metrics data for a specific metric */
  metric?: Maybe<Metric>;
  /** A single metric data point */
  metricDataPoint?: Maybe<MetricDataPoint>;
  /** The metric data points for a specific metric */
  metricDataPoints?: Maybe<MetricDataPointCollection>;
  /** The entities for all of the passed Metric */
  metricEntities?: Maybe<EntityCollection>;
  /** Get all upstream events data for a metric graph */
  metricEventsGraph?: Maybe<Scalars['JSON']['output']>;
  /** A single MetricSource */
  metricSource?: Maybe<MetricSource>;
  /** MetricSourceActivities for the selected metric */
  metricSourceActivities?: Maybe<MetricSourceActivityCollection>;
  /** A single MetricSourceActivity */
  metricSourceActivity?: Maybe<MetricSourceActivity>;
  /** All the MetricSources for the current Organization */
  metricSources?: Maybe<Array<MetricSource>>;
  /** Metrics for the current organization */
  metrics?: Maybe<MetricCollection>;
  /** Autocomplete endpoint for Metrics */
  metricsAutocomplete?: Maybe<Array<Metric>>;
  /** Organization for current user */
  organization?: Maybe<Organization>;
  /** A single Playbook */
  playbook?: Maybe<Playbook>;
  /** All Playbooks scoped to the user's Organization */
  playbooks: Array<Playbook>;
  /** A single RecurringReportConfiguration */
  recurringReportConfiguration?: Maybe<RecurringReportConfiguration>;
  /** All recurring report configurations scoped to an strategy */
  recurringReportConfigurations?: Maybe<RecurringReportConfigurationCollection>;
  /** A single report */
  report?: Maybe<Report>;
  /** All reports scoped to an strategy */
  reports?: Maybe<ReportCollection>;
  /** Roadmap items for a strategy */
  roadmapItems?: Maybe<Array<RoadmapItem>>;
  /** Autocomplete endpoint for Slack Channels */
  slackChannelsAutocomplete?: Maybe<Array<SlackChannel>>;
  /** All sources for the current organization */
  sources?: Maybe<Sources>;
  /** Organization strategies */
  strategies?: Maybe<StrategyCollection>;
  /** A single organization strategy */
  strategy?: Maybe<Strategy>;
  /** The changelog for a specific strategy */
  strategyChangelog?: Maybe<EntityCollection>;
  /** An entity on the strategy specific changelog */
  strategyChangelogEntity?: Maybe<Entity>;
  /** Accounts for the current user */
  userAccounts?: Maybe<Array<Account>>;
};


/** Master list of queries */
export type QueryAccountsArgs = {
  includeDisabled?: InputMaybe<Scalars['CastableBoolean']['input']>;
};


/** Master list of queries */
export type QueryAmplitudeMetricSourceEventNamesArgs = {
  credentialId?: InputMaybe<Scalars['ID']['input']>;
  region: AmplitudeRegionEnum;
};


/** Master list of queries */
export type QueryBasicCardArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryBasicCardEntitiesArgs = {
  basicCardId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  workflowState?: InputMaybe<EntityWorkflowStateEnum>;
};


/** Master list of queries */
export type QueryBasicCardsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<BasicCardOrderingInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Master list of queries */
export type QueryChatArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryContributorArgs = {
  id: Scalars['String']['input'];
};


/** Master list of queries */
export type QueryContributorsAutocompleteArgs = {
  query: Scalars['String']['input'];
  requireEmail?: InputMaybe<Scalars['CastableBoolean']['input']>;
};


/** Master list of queries */
export type QueryCredentialArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryCredentialsArgs = {
  sourceName?: InputMaybe<Scalars['String']['input']>;
};


/** Master list of queries */
export type QueryEntitiesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<EntityOrderingInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Master list of queries */
export type QueryEntityArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryEntityEntitiesArgs = {
  entityId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  workflowState?: InputMaybe<EntityWorkflowStateEnum>;
};


/** Master list of queries */
export type QueryEventsGraphArgs = {
  filters?: InputMaybe<Scalars['JSON']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** Master list of queries */
export type QueryGoalArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryGoogleSheetArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryImpactfulEntitiesArgs = {
  filters?: InputMaybe<Scalars['JSON']['input']>;
  metricId: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryIntegrationArgs = {
  sourceName: Scalars['String']['input'];
};


/** Master list of queries */
export type QueryInvitationArgs = {
  inviteCode: Scalars['String']['input'];
};


/** Master list of queries */
export type QueryLabelsAutocompleteArgs = {
  query: Scalars['String']['input'];
};


/** Master list of queries */
export type QueryLookerApiLookFieldsArgs = {
  credentialId: Scalars['ID']['input'];
  lookId: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryLookerApiLooksArgs = {
  credentialId: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryMembersAutocompleteArgs = {
  query: Scalars['String']['input'];
};


/** Master list of queries */
export type QueryMetricArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryMetricDataPointArgs = {
  metricDataPointId: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryMetricDataPointsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  metricId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


/** Master list of queries */
export type QueryMetricEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  metricId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  workflowState?: InputMaybe<EntityWorkflowStateEnum>;
};


/** Master list of queries */
export type QueryMetricEventsGraphArgs = {
  filters?: InputMaybe<Scalars['JSON']['input']>;
  metricId: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryMetricSourceArgs = {
  metricId: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryMetricSourceActivitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  metricSourceId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


/** Master list of queries */
export type QueryMetricSourceActivityArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryMetricsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<MetricOrderingInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  strategyId?: InputMaybe<Scalars['ID']['input']>;
  unsourced?: InputMaybe<Scalars['CastableBoolean']['input']>;
};


/** Master list of queries */
export type QueryMetricsAutocompleteArgs = {
  query: Scalars['String']['input'];
};


/** Master list of queries */
export type QueryPlaybookArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryRecurringReportConfigurationArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryRecurringReportConfigurationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  strategyId: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryReportArgs = {
  id: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryReportsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  strategyId: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryRoadmapItemsArgs = {
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};


/** Master list of queries */
export type QuerySlackChannelsAutocompleteArgs = {
  query: Scalars['String']['input'];
};


/** Master list of queries */
export type QueryStrategiesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<StrategyOrderingInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


/** Master list of queries */
export type QueryStrategyArgs = {
  id: Scalars['ID']['input'];
  loadAsAnonymous?: InputMaybe<Scalars['CastableBoolean']['input']>;
};


/** Master list of queries */
export type QueryStrategyChangelogArgs = {
  containerNodeIds?: InputMaybe<Scalars['CommaSeparatedString']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  loadAsAnonymous?: InputMaybe<Scalars['CastableBoolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  strategyId: Scalars['ID']['input'];
};


/** Master list of queries */
export type QueryStrategyChangelogEntityArgs = {
  id: Scalars['ID']['input'];
  strategyId: Scalars['ID']['input'];
};

/** Recurring Report Configurations */
export type RecurringReportConfiguration = ClassTypeInterface & {
  __typename?: 'RecurringReportConfiguration';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Emails to send reports to */
  emails: Scalars['String']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Name of report */
  name?: Maybe<Scalars['String']['output']>;
  /** The recurrence rule for creation of new Reports */
  schedule?: Maybe<Scalars['ICalSchedule']['output']>;
  /** The displayable recurring rule */
  scheduleDisplay: Scalars['String']['output'];
  /** Send reports to email? */
  sendToEmail: Scalars['Boolean']['output'];
  /** Send reports to slack? */
  sendToSlack: Scalars['Boolean']['output'];
  /** Service to run */
  service: RecurringReportConfigurationServiceEnum;
  /** Slack Channel ID to send reports to */
  slackChannel?: Maybe<SlackChannel>;
  /** Associated Strategy */
  strategy: Strategy;
  /** Associated Strategy ID. Used for filtering the store in the frontend. */
  strategyId: Scalars['ID']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** RecurringReportConfigurationCollection type */
export type RecurringReportConfigurationCollection = {
  __typename?: 'RecurringReportConfigurationCollection';
  /** A collection of paginated RecurringReportConfigurationCollection */
  collection: Array<RecurringReportConfiguration>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Input Type for RecurringReportConfiguration */
export type RecurringReportConfigurationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Comma-separated list of emails to send reports to */
  emails?: InputMaybe<Scalars['String']['input']>;
  /** Name that will be given to created reports */
  name: Scalars['String']['input'];
  /** RRule schedule for reports to be created */
  schedule: Scalars['ICalSchedule']['input'];
  /** Send report to email? */
  sendToEmail?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Send report to slack? */
  sendToSlack?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Service to run */
  service: RecurringReportConfigurationServiceEnum;
  /** Slack Channel to send to */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
  /** Strategy ID */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of RecurringReportConfigurationCreate. */
export type RecurringReportConfigurationCreatePayload = ClassTypeInterface & {
  __typename?: 'RecurringReportConfigurationCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated RecurringReportConfiguration */
  recurringReportConfiguration?: Maybe<RecurringReportConfiguration>;
};

/** Autogenerated input type of RecurringReportConfigurationRun */
export type RecurringReportConfigurationRunInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Strategy ID */
  recurringReportConfigurationId: Scalars['ID']['input'];
};

/** Autogenerated return type of RecurringReportConfigurationRun. */
export type RecurringReportConfigurationRunPayload = ClassTypeInterface & {
  __typename?: 'RecurringReportConfigurationRunPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated RecurringReportConfiguration */
  recurringReportConfiguration?: Maybe<RecurringReportConfiguration>;
};

/** Service to run */
export enum RecurringReportConfigurationServiceEnum {
  /** Emit Report */
  EmitReport = 'emit_report',
  /** Send Changelog */
  SendChangelog = 'send_changelog',
  /** Send Goals */
  SendGoals = 'send_goals'
}

/** Input Type for RecurringReportConfiguration */
export type RecurringReportConfigurationUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Comma-separated list of emails to send reports to */
  emails?: InputMaybe<Scalars['String']['input']>;
  /** Name that will be given to created reports */
  name: Scalars['String']['input'];
  /** Strategy ID */
  recurringReportConfigurationId: Scalars['ID']['input'];
  /** RRule schedule for reports to be created */
  schedule: Scalars['ICalSchedule']['input'];
  /** Send report to email? */
  sendToEmail?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Send report to slack? */
  sendToSlack?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Service to run */
  service: RecurringReportConfigurationServiceEnum;
  /** Slack Channel to send to */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of RecurringReportConfigurationUpdate. */
export type RecurringReportConfigurationUpdatePayload = ClassTypeInterface & {
  __typename?: 'RecurringReportConfigurationUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated RecurringReportConfiguration */
  recurringReportConfiguration?: Maybe<RecurringReportConfiguration>;
};

/** Report */
export type Report = ClassTypeInterface & RfNodeInterface & {
  __typename?: 'Report';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Emails to send report to */
  emails: Scalars['String']['output'];
  /** Report period end date */
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Name of report */
  name?: Maybe<Scalars['String']['output']>;
  /** Strategy node id */
  rfId: Scalars['String']['output'];
  /** Send report to email? */
  sendToEmail: Scalars['Boolean']['output'];
  /** Send report to slack? */
  sendToSlack: Scalars['Boolean']['output'];
  /** Slack Channel attached to this Strategy */
  slackChannel?: Maybe<SlackChannel>;
  /** Slack Channel ID attached to this Strategy */
  slackChannelId?: Maybe<Scalars['ID']['output']>;
  /** Report period start date */
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Associated Strategy */
  strategy: Strategy;
  /** Strategy ID without going through join record */
  strategyId: Scalars['ID']['output'];
  /** Report summary */
  summary?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** ReportCollection type */
export type ReportCollection = {
  __typename?: 'ReportCollection';
  /** A collection of paginated ReportCollection */
  collection: Array<Report>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Autogenerated input type of ReportCreate */
export type ReportCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Report end date */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Report name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Report start date */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Strategy ID */
  strategyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of ReportCreate. */
export type ReportCreatePayload = ClassTypeInterface & {
  __typename?: 'ReportCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Report */
  report?: Maybe<Report>;
};

/** Input Type for Report */
export type ReportInput = {
  /** Emails to send report to */
  emails?: InputMaybe<Scalars['String']['input']>;
  /** Report end date */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Report ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Report name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Send report to email? */
  sendToEmail?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Send report to slack? */
  sendToSlack?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Strategy Slack Channel */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
  /** Report start date */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Report text summary */
  summary?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of ReportSend */
export type ReportSendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Report ID to update */
  reportId: Scalars['ID']['input'];
};

/** Autogenerated return type of ReportSend. */
export type ReportSendPayload = ClassTypeInterface & {
  __typename?: 'ReportSendPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Report */
  report?: Maybe<Report>;
};

/** Autogenerated input type of ReportUpdate */
export type ReportUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Emails to send report to */
  emails?: InputMaybe<Scalars['String']['input']>;
  /** Report end date */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Report name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Report ID to update */
  reportId: Scalars['ID']['input'];
  /** Send report to email? */
  sendToEmail?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Send report to slack? */
  sendToSlack?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Strategy Slack Channel */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
  /** Report start date */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** Autogenerated return type of ReportUpdate. */
export type ReportUpdatePayload = ClassTypeInterface & {
  __typename?: 'ReportUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Report */
  report?: Maybe<Report>;
};

/** An object that can be included as a React Flow node on a Strategy Map */
export type RfNodeInterface = {
  /** Strategy node id */
  rfId: Scalars['String']['output'];
};

/** Roadmap Item */
export type RoadmapItem = ClassTypeInterface & {
  __typename?: 'RoadmapItem';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** associated Roadmap Domain Object */
  domainObject?: Maybe<RoadmapItemDomainObject>;
  /** associated Roadmap Domain Object ID */
  domainObjectId: Scalars['ID']['output'];
  /** associated Roadmap Domain Object Type */
  domainObjectType: Scalars['String']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Position of the Roadmap Item in the list relative to other items */
  position?: Maybe<Scalars['Int']['output']>;
  /** associated Strategy */
  strategy?: Maybe<Strategy>;
  /** associated Strategy ID */
  strategyId: Scalars['ID']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of RoadmapItemCreate */
export type RoadmapItemCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Domain object id */
  object: RoadmapItemInput;
  /** RoadmapItem positions */
  positions: Array<RoadmapItemPosition>;
  /** Strategy ID */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of RoadmapItemCreate. */
export type RoadmapItemCreatePayload = ClassTypeInterface & {
  __typename?: 'RoadmapItemCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated RoadmapItem */
  roadmapItem?: Maybe<RoadmapItem>;
};

/** Autogenerated input type of RoadmapItemDelete */
export type RoadmapItemDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** RoadmapItem positions */
  positions: Array<RoadmapItemPosition>;
  /** Roadmap ID to delete */
  roadmapItemId: Scalars['ID']['input'];
};

/** Autogenerated return type of RoadmapItemDelete. */
export type RoadmapItemDeletePayload = ClassTypeInterface & {
  __typename?: 'RoadmapItemDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Destroyed RoadmapItem */
  roadmapItem: RoadmapItem;
};

/** Roadmap Item Domain Object Types */
export type RoadmapItemDomainObject = BasicCard | Entity | Metric | Strategy;

/** Input Type for Roadmap Items */
export type RoadmapItemInput =
  /** BasicCard */
  { basicCard: BasicCardInput; entity?: never; existing?: never; metric?: never; strategy?: never; }
  |  /** Entity */
  { basicCard?: never; entity: EntityInput; existing?: never; metric?: never; strategy?: never; }
  |  /** Existing domain object */
  { basicCard?: never; entity?: never; existing: ExistingDomainObjectInput; metric?: never; strategy?: never; }
  |  /** Metric */
  { basicCard?: never; entity?: never; existing?: never; metric: MetricInput; strategy?: never; }
  |  /** Strategy */
  { basicCard?: never; entity?: never; existing?: never; metric?: never; strategy: StrategyInput; };

/** Input Type for Roadmap Item Position */
export type RoadmapItemPosition = {
  /** Roadmap item ID */
  id: Scalars['ID']['input'];
  /** Position of the Roadmap Item in the list relative to other items */
  position: Scalars['Int']['input'];
};

/** Autogenerated input type of RoadmapItemUpdate */
export type RoadmapItemUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** RoadmapItem positions */
  positions: Array<RoadmapItemPosition>;
  /** Roadmap ID to update */
  roadmapItemId: Scalars['ID']['input'];
};

/** Autogenerated return type of RoadmapItemUpdate. */
export type RoadmapItemUpdatePayload = ClassTypeInterface & {
  __typename?: 'RoadmapItemUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated RoadmapItem */
  roadmapItem?: Maybe<RoadmapItem>;
};

/** List of possible Role values on User */
export enum RoleEnum {
  /** Admin - The User can make changes and manage integrations */
  Admin = 'admin',
  /** Editor - The User can make changes */
  Editor = 'editor',
  /** Viewer - The User can view but not make changes */
  Viewer = 'viewer'
}

/** List of possible Roll Up values on Metric */
export enum RollUpEnum {
  /** Average */
  Average = 'average',
  /** Amount Increased */
  Increase = 'increase',
  /** MoM, month over 3-month average, YoY */
  LastMonthAvailable = 'last_month_available',
  /** Sum */
  Sum = 'sum',
  /** Weighted Average */
  WeightedAverage = 'weighted_average'
}

/** Section */
export type Section = ClassTypeInterface & RfNodeInterface & {
  __typename?: 'Section';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Label name */
  name?: Maybe<Scalars['String']['output']>;
  /** Strategy node id */
  rfId: Scalars['String']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Input Type for Section */
export type SectionInput = {
  /** Section ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the section */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Segment filters */
export type SegmentFilter = ClassTypeInterface & {
  __typename?: 'SegmentFilter';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Segment Filter ID */
  id: Scalars['ID']['output'];
  /** Name / Category / key of this filter */
  segmentName: SegmentName;
  /** Value of this filter */
  segmentValue: SegmentValue;
};

/** Objects which may have Segment Filters attached to them */
export type SegmentFilterableInterface = {
  /** The ID of the filterable */
  filterableId: Scalars['ID']['output'];
};

/** Autogenerated input type of SegmentFiltersCreate */
export type SegmentFiltersCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the filterable to add the Segment Filter to */
  filterableId: Scalars['ID']['input'];
  /** Type of the filterable to add the Segment Filter to */
  filterableType: Scalars['String']['input'];
  /** IDs of the Segment Values to filter on */
  segmentValueIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of SegmentFiltersCreate. */
export type SegmentFiltersCreatePayload = ClassTypeInterface & {
  __typename?: 'SegmentFiltersCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Filterable object */
  filterable: SegmentFilterableInterface;
  /** Created Segment Filters */
  segmentFilters: Array<SegmentFilter>;
};

/** Autogenerated input type of SegmentFiltersDelete */
export type SegmentFiltersDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the filterable to add the Segment Filter to */
  filterableId: Scalars['ID']['input'];
  /** Type of the filterable to add the Segment Filter to */
  filterableType: Scalars['String']['input'];
  /** IDs of the Segment Filters to delete */
  segmentFilterIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of SegmentFiltersDelete. */
export type SegmentFiltersDeletePayload = ClassTypeInterface & {
  __typename?: 'SegmentFiltersDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Filterable object */
  filterable: SegmentFilterableInterface;
  /** Deleted Segment Filters */
  segmentFilters: Array<SegmentFilter>;
};

/** Segment names / categories / keys */
export type SegmentName = ClassTypeInterface & {
  __typename?: 'SegmentName';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Segment name ID */
  id: Scalars['ID']['output'];
  /** Name / Category / key */
  name: Scalars['String']['output'];
  /** The Organization */
  organization: Organization;
  /** Values in this category */
  segmentValues: Array<SegmentValue>;
};

/** Segment values (note that this does not include the key / category) */
export type SegmentValue = ClassTypeInterface & {
  __typename?: 'SegmentValue';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Segment name ID */
  id: Scalars['ID']['output'];
  /** Filters that use this value */
  segmentFilters: Array<SegmentFilter>;
  /** My segment category */
  segmentName: SegmentName;
  /** The actual value */
  value: Scalars['String']['output'];
};

/** List of possible segmented data types (i.e. date de-duplicating operations) */
export enum SegmentedDataTypeEnum {
  /** Average */
  Average = 'average',
  /** Sum */
  Sum = 'sum'
}

/** Sharing possible values */
export enum SharingTypesEnum {
  /** Private Access  - Only logged in users can access */
  PrivateAccess = 'private_access',
  /** Public Access - Any user can access */
  PublicAccess = 'public_access',
  /** Token Access - Any user with a the token can access */
  TokenAccess = 'token_access'
}

/** Input Type for Shrine Uploads */
export type ShrineInput = {
  /** The id of the file */
  id: Scalars['String']['input'];
  /** The metadata of the file */
  metadata: ShrineMetadataInput;
  /** The storage of the file */
  storage: Scalars['String']['input'];
};

/** Input Type for Shrine Metadata */
export type ShrineMetadataInput = {
  /** The name of the file */
  filename: Scalars['String']['input'];
  /** The height of the file */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The mime-type of the file */
  mimeType: Scalars['String']['input'];
  /** The size of the file */
  size: Scalars['Int']['input'];
  /** The width of the file */
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** A single slack channel */
export type SlackChannel = ClassTypeInterface & {
  __typename?: 'SlackChannel';
  /** Slack channel id */
  channelId: Scalars['String']['output'];
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Creator ID */
  creatorId?: Maybe<Scalars['String']['output']>;
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** True if the channel is archived */
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  /** True if the object is a channel */
  isChannel?: Maybe<Scalars['Boolean']['output']>;
  /** True if externally shared */
  isExtShared?: Maybe<Scalars['Boolean']['output']>;
  /** True if it is the general channel */
  isGeneral?: Maybe<Scalars['Boolean']['output']>;
  /** True if it is a group channel */
  isGroup?: Maybe<Scalars['Boolean']['output']>;
  /** True if it is a direct message */
  isIm?: Maybe<Scalars['Boolean']['output']>;
  /** True if it is a member message */
  isMember?: Maybe<Scalars['Boolean']['output']>;
  /** True if the organization is shared */
  isOrgShared?: Maybe<Scalars['Boolean']['output']>;
  /** True if the channel is private */
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  /** Slack channel label */
  label: Scalars['String']['output'];
  /** Slack channel name */
  name: Scalars['String']['output'];
  /** Slack channel name normalized */
  nameNormalized: Scalars['String']['output'];
  /** Slack channel member count */
  numMembers?: Maybe<Scalars['Int']['output']>;
  /** Associated Organization */
  organization: Organization;
  /** Slack channel purpose */
  purpose?: Maybe<Scalars['JSON']['output']>;
  /** Slack channel topic */
  topic?: Maybe<Scalars['JSON']['output']>;
  /** Slack channel type */
  type: Scalars['String']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Slack channel value */
  value: Scalars['ID']['output'];
};

/** Direction to sort a list of items */
export enum SortDirectionEnum {
  /** Sort in ascending order */
  Asc = 'asc',
  /** Sort in descending order */
  Desc = 'desc'
}

/** A single SourceIdentity object */
export type SourceIdentity = ClassTypeInterface & {
  __typename?: 'SourceIdentity';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Source user email address for lookup */
  email?: Maybe<Scalars['String']['output']>;
  /** ID of object */
  id: Scalars['ID']['output'];
  /** Name of source identity */
  name: Scalars['String']['output'];
  /** Associated Organization */
  organization: Organization;
  /** Associated user if present */
  searchableContributorId?: Maybe<Scalars['String']['output']>;
  /** ID in 3rd party service of user ID */
  sourceId?: Maybe<Scalars['String']['output']>;
  /** Name of 3rd party service user was discovered at */
  sourceName: Scalars['String']['output'];
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** Associated user */
  user?: Maybe<User>;
};

/** Collection of Sources */
export type Sources = ClassTypeInterface & {
  __typename?: 'Sources';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** List of Entity sources on the Organization */
  entity?: Maybe<Array<SourcesEntitySource>>;
  /** List of Metric sources on the Organization */
  metric?: Maybe<Array<SourcesMetricSource>>;
};

/** Entity shape for the Sources query */
export type SourcesEntitySource = ClassTypeInterface & {
  __typename?: 'SourcesEntitySource';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** List of Entity types provided by the source */
  entityTypes: Array<Scalars['String']['output']>;
  /** The name of the source */
  sourceName: Scalars['String']['output'];
};

/** Metric source shape for the Sources query */
export type SourcesMetricSource = ClassTypeInterface & {
  __typename?: 'SourcesMetricSource';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** The name of the source */
  sourceName: Scalars['String']['output'];
};

/** Strategy */
export type Strategy = ClassTypeInterface & CommentableInterface & DomainObjectInterface & FavoritableInterface & RfNodeInterface & SegmentFilterableInterface & {
  __typename?: 'Strategy';
  /** AI Context, passed to AI pipelines */
  aiContext?: Maybe<Scalars['String']['output']>;
  /** AI Context Files */
  aiContextVectorStoreFiles?: Maybe<Array<AiAssistantFile>>;
  /**
   * AI Conversation
   * @deprecated Uses of this should eventually use chats instead.
   */
  aiConversation?: Maybe<AiConversation>;
  /** Whether a change to the map is running in the background */
  backgroundChangeInProgress: Scalars['Boolean']['output'];
  /** The upstream entities for all of the passed Basic Cards */
  basicCardEntities?: Maybe<EntityCollection>;
  /** Associated Basic Cards */
  basicCards?: Maybe<Array<BasicCard>>;
  /** AI Chats */
  chats?: Maybe<Array<Chat>>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Comments for the Comment Thread */
  commentThreadComments?: Maybe<Array<Comment>>;
  /** Associated Comment Threads */
  commentThreads?: Maybe<Array<CommentThread>>;
  /** Comments left on the object */
  comments?: Maybe<Array<Comment>>;
  /** Number of comments left on the object */
  commentsCount: Scalars['Int']['output'];
  /** Confidence rating of the object */
  confidenceRating?: Maybe<ConfidenceRatingEnum>;
  /** True if the Strategy is connected to a Slack Channel */
  connected: Scalars['Boolean']['output'];
  /** IDs of Strategies contained by this Strategy */
  containeeIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** Object creation date */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Edges */
  edges?: Maybe<Array<Edge>>;
  /** Strategy end date */
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Associated entities */
  entities?: Maybe<Array<Entity>>;
  /** Entity finder */
  entity?: Maybe<Entity>;
  /** The contained entities passed in entity */
  entityEntities?: Maybe<EntityCollection>;
  /** Get all upstream events data for a entity graph */
  entityEventsGraph?: Maybe<Scalars['JSON']['output']>;
  /** Search for Strategies, Metrics, and Entities */
  existingObjectAutocomplete?: Maybe<Array<MultiObjectAutocompleteItem>>;
  /** The ID of the current user's Favorite of this favoritable, if favorited */
  favoriteId?: Maybe<Scalars['ID']['output']>;
  /** ID for used through the SegmentFilterable interface */
  filterableId: Scalars['ID']['output'];
  /** Object global id */
  gid: Scalars['ID']['output'];
  /** Object unique ID */
  id: Scalars['ID']['output'];
  /** Number of processes currently running that want to show an indicator in the frontend */
  indicatorCounter: Scalars['Int']['output'];
  /** Labels */
  labels?: Maybe<Array<Label>>;
  /** Associated map images */
  mapImages?: Maybe<Array<MapImage>>;
  /** Metric finder */
  metric?: Maybe<Metric>;
  /** List of segment names */
  metricDataPointSegmentNames?: Maybe<Array<SegmentName>>;
  /** List of segment values */
  metricDataPointSegmentValues?: Maybe<Array<SegmentValue>>;
  /** The entities for all of the passed Metric */
  metricEntities?: Maybe<EntityCollection>;
  /** Get all upstream events data for a metric graph */
  metricEventsGraph?: Maybe<Scalars['JSON']['output']>;
  /** Associated metrics */
  metrics?: Maybe<Array<Metric>>;
  /** Strategy name */
  name?: Maybe<Scalars['String']['output']>;
  /** Nodes */
  nodes?: Maybe<Array<Node>>;
  /** Associated notes */
  notes?: Maybe<Array<Note>>;
  /** Associated Organization */
  organization: Organization;
  /** Owner of this Strategy */
  owner?: Maybe<User>;
  /** Owner ID of this Strategy */
  ownerId?: Maybe<Scalars['ID']['output']>;
  /** Strategy date range */
  range?: Maybe<Scalars['String']['output']>;
  /** Associated Recurring Report Configurations */
  recurringReportConfigurations?: Maybe<Array<RecurringReportConfiguration>>;
  /** Associated Reports */
  reports?: Maybe<Array<Report>>;
  /** Strategy node id */
  rfId: Scalars['String']['output'];
  /** Associated sections */
  sections?: Maybe<Array<Section>>;
  /** Segment filters for the Strategy */
  segmentFilters?: Maybe<Array<SegmentFilter>>;
  /** Sharing status for the Strategy. */
  sharing: SharingTypesEnum;
  /** Show correlations for the Strategy. */
  showCorrelations: Scalars['Boolean']['output'];
  /** Whether to show the "Segment" button in the frontend */
  showSegmentCreate: Scalars['Boolean']['output'];
  /** Slack Channel attached to this Strategy */
  slackChannel?: Maybe<SlackChannel>;
  /** Slack Channel ID attached to this Strategy */
  slackChannelId?: Maybe<Scalars['ID']['output']>;
  /** Strategy start date */
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Status of the Strategy */
  status: DomainObjectStatusEnum;
  /** Associated strategies */
  strategies?: Maybe<Array<Strategy>>;
  /** The upstream entities for all of the passed Strategies */
  strategyEntities?: Maybe<EntityCollection>;
  /** Access token for when not public */
  token?: Maybe<Scalars['String']['output']>;
  /** Object last updated date */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


/** Strategy */
export type StrategyBasicCardEntitiesArgs = {
  basicCardId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  workflowState?: InputMaybe<EntityWorkflowStateEnum>;
};


/** Strategy */
export type StrategyCommentThreadCommentsArgs = {
  commentThreadId: Scalars['ID']['input'];
};


/** Strategy */
export type StrategyEntityArgs = {
  id: Scalars['ID']['input'];
  metricId?: InputMaybe<Scalars['ID']['input']>;
};


/** Strategy */
export type StrategyEntityEntitiesArgs = {
  entityId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  workflowState?: InputMaybe<EntityWorkflowStateEnum>;
};


/** Strategy */
export type StrategyEntityEventsGraphArgs = {
  entityId: Scalars['ID']['input'];
  filters?: InputMaybe<Scalars['JSON']['input']>;
};


/** Strategy */
export type StrategyExistingObjectAutocompleteArgs = {
  query: Scalars['String']['input'];
  scope?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


/** Strategy */
export type StrategyMetricArgs = {
  id: Scalars['ID']['input'];
};


/** Strategy */
export type StrategyMetricDataPointSegmentValuesArgs = {
  segmentNameId: Scalars['ID']['input'];
};


/** Strategy */
export type StrategyMetricEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  metricId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  workflowState?: InputMaybe<EntityWorkflowStateEnum>;
};


/** Strategy */
export type StrategyMetricEventsGraphArgs = {
  filters?: InputMaybe<Scalars['JSON']['input']>;
  metricId: Scalars['ID']['input'];
};


/** Strategy */
export type StrategyNodesArgs = {
  filters?: InputMaybe<NodeFiltersInput>;
};


/** Strategy */
export type StrategyStrategyEntitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  workflowState?: InputMaybe<EntityWorkflowStateEnum>;
};

/** StrategyCollection type */
export type StrategyCollection = {
  __typename?: 'StrategyCollection';
  /** A collection of paginated StrategyCollection */
  collection: Array<Strategy>;
  /** Pagination Metadata for navigating the Pagination */
  metadata: CollectionMetadata;
};

/** Input Type for Strategy */
export type StrategyCreateInput = {
  /** The AI context of the Strategy */
  aiContext?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Strategy description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Strategy end date */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Strategy ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Optional labels to set on the Strategy */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Strategy name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Strategy */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** Strategy date range */
  range?: InputMaybe<Scalars['String']['input']>;
  /** Strategy sharing type */
  sharing?: InputMaybe<SharingTypesEnum>;
  /** Should the Strategy show correlations */
  showCorrelations?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Strategy Slack Channel */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
  /** Strategy start date */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Strategy status */
  status?: InputMaybe<DomainObjectStatusEnum>;
};

/** Autogenerated return type of StrategyCreate. */
export type StrategyCreatePayload = ClassTypeInterface & {
  __typename?: 'StrategyCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Strategy */
  strategy?: Maybe<Strategy>;
};

/** Autogenerated input type of StrategyDelete */
export type StrategyDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Strategy ID to delete */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of StrategyDelete. */
export type StrategyDeletePayload = ClassTypeInterface & {
  __typename?: 'StrategyDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Deleted Strategy */
  strategy?: Maybe<Strategy>;
};

/** Autogenerated return type of StrategyFields. */
export type StrategyFieldsPayload = ClassTypeInterface & {
  __typename?: 'StrategyFieldsPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** The object */
  data: DomainObject;
  /** The operation that was performed */
  operation: CrudOperationEnum;
};

/** Input Type for Strategy */
export type StrategyInput = {
  /** The AI context of the Strategy */
  aiContext?: InputMaybe<Scalars['String']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Strategy description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Strategy end date */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Strategy ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Optional labels to set on the Strategy */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Strategy name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Strategy */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** Strategy date range */
  range?: InputMaybe<Scalars['String']['input']>;
  /** Strategy sharing type */
  sharing?: InputMaybe<SharingTypesEnum>;
  /** Should the Strategy show correlations */
  showCorrelations?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Strategy Slack Channel */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
  /** Strategy start date */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Strategy status */
  status?: InputMaybe<DomainObjectStatusEnum>;
};

/** Autogenerated input type of StrategyMetricsSuggest */
export type StrategyMetricsSuggestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Metric */
  metricId: Scalars['ID']['input'];
  /** The ID of the Strategy */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of StrategyMetricsSuggest. */
export type StrategyMetricsSuggestPayload = ClassTypeInterface & {
  __typename?: 'StrategyMetricsSuggestPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The error, if any, returned by OpenAI */
  error?: Maybe<Scalars['String']['output']>;
  /** The suggestions, returned by OpenAI */
  result?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StrategyNodeDelete */
export type StrategyNodeDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Node to destroy */
  nodes: Array<Scalars['ID']['input']>;
  /** The ID of the Strategy to associate */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of StrategyNodeDelete. */
export type StrategyNodeDeletePayload = ClassTypeInterface & {
  __typename?: 'StrategyNodeDeletePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Destroyed nodes */
  nodes: Array<Node>;
  /** Updated strategy */
  strategy: Strategy;
};

/** The map stats for a Strategy Node */
export type StrategyNodeStats = ClassTypeInterface & {
  __typename?: 'StrategyNodeStats';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Strategy stats for the underlying Metric */
  strategyStats?: Maybe<Array<StrategyStat>>;
};

/** Autogenerated input type of StrategyNodeUpdate */
export type StrategyNodeUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Array of nodes that either need updated */
  nodes?: InputMaybe<Array<NodeInput>>;
  /** Strategy ID to update */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of StrategyNodeUpdate. */
export type StrategyNodeUpdatePayload = ClassTypeInterface & {
  __typename?: 'StrategyNodeUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Nodes */
  nodes?: Maybe<Array<Node>>;
  /** Updated Strategy */
  strategy?: Maybe<Strategy>;
};

export type StrategyOrderingInput = {
  /** Sort by name */
  name?: InputMaybe<SortDirectionEnum>;
};

/** Autogenerated input type of StrategyPopulateFromPlaybookTemplate */
export type StrategyPopulateFromPlaybookTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Playbook template to apply */
  playbookTemplate: Scalars['JSON']['input'];
  /** Playbook ID to apply */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of StrategyPopulateFromPlaybookTemplate. */
export type StrategyPopulateFromPlaybookTemplatePayload = ClassTypeInterface & {
  __typename?: 'StrategyPopulateFromPlaybookTemplatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created Strategy */
  strategy?: Maybe<Strategy>;
};

/** Autogenerated input type of StrategyReviewSend */
export type StrategyReviewSendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Strategy ID to send */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of StrategyReviewSend. */
export type StrategyReviewSendPayload = ClassTypeInterface & {
  __typename?: 'StrategyReviewSendPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** True if the send succeeded */
  success: Scalars['Boolean']['output'];
};

/** Strategy stats for Metric */
export type StrategyStat = ClassTypeInterface & {
  __typename?: 'StrategyStat';
  /** Change in the total from the previous period */
  change?: Maybe<Scalars['Float']['output']>;
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** The User-facing displayable period */
  displayPeriod: Scalars['String']['output'];
  /** The period of time this Stat measures */
  period: Scalars['ISO8601Duration']['output'];
  /** Total value of the Stat's period */
  total: Scalars['Float']['output'];
};

/** List of Strategy types */
export enum StrategyTypeEnum {
  /** Diagnostic Node for Metrics */
  Diagnostic = 'diagnostic',
  /** Input Node for Metrics */
  Input = 'input',
  /** Kpi Node for Metrics */
  Kpi = 'kpi',
  /** North star Node for Metrics */
  NorthStar = 'north_star'
}

/** Input Type for Strategy attributes (i.e. no ID, which is used in DomainObjectUpdate, but nowhere else) */
export type StrategyUpdateInput = {
  /** The AI context of the Strategy */
  aiContext?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The confidence rating of the Work */
  confidenceRating?: InputMaybe<ConfidenceRatingEnum>;
  /** Strategy description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Strategy end date */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Optional labels to set on the Strategy */
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Strategy name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the owner of this Strategy */
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  /** Strategy date range */
  range?: InputMaybe<Scalars['String']['input']>;
  /** Strategy sharing type */
  sharing?: InputMaybe<SharingTypesEnum>;
  /** Should the Strategy show correlations */
  showCorrelations?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** Strategy Slack Channel */
  slackChannelId?: InputMaybe<Scalars['ID']['input']>;
  /** Strategy start date */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Strategy status */
  status?: InputMaybe<DomainObjectStatusEnum>;
  /** Strategy ID to update */
  strategyId: Scalars['ID']['input'];
};

/** Autogenerated return type of StrategyUpdate. */
export type StrategyUpdatePayload = ClassTypeInterface & {
  __typename?: 'StrategyUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated Strategy */
  strategy?: Maybe<Strategy>;
};

/** DoubleLoop subscriptions */
export type Subscription = {
  __typename?: 'Subscription';
  /** Subscription for when an object is deleted, within a Strategy */
  mapStructureChanged: MapStructureChangedPayload;
  /** Subscription for domain object crud, at the organization level */
  organizationCrud: OrganizationCrudPayload;
  /** Subscription for domain object fields that are Strategy-specific, like metric stats */
  strategyFields: StrategyFieldsPayload;
};


/** DoubleLoop subscriptions */
export type SubscriptionMapStructureChangedArgs = {
  strategyId: Scalars['ID']['input'];
};


/** DoubleLoop subscriptions */
export type SubscriptionOrganizationCrudArgs = {
  organizationId: Scalars['ID']['input'];
};


/** DoubleLoop subscriptions */
export type SubscriptionStrategyFieldsArgs = {
  strategyId: Scalars['ID']['input'];
};

/** List of Team types */
export enum TeamTypeEnum {
  /** Engineering Node for BasicCards */
  Engineering = 'engineering',
  /** Marketing Node for BasicCards */
  Marketing = 'marketing',
  /** Product Node for BasicCards */
  Product = 'product',
  /** Sales Node for BasicCards */
  Sales = 'sales',
  /** Support Node for BasicCards */
  Support = 'support'
}

/** CRUD operation wrapper for Types::AccountType */
export type TypesAccountTypeGraphqlCrudOperation = ClassTypeInterface & {
  __typename?: 'TypesAccountTypeGraphqlCrudOperation';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Data that the operation was performed on */
  data?: Maybe<Account>;
  /** Operation that was performed */
  operation: CrudOperationEnum;
};

/** CRUD operation wrapper for Types::CardTypeType */
export type TypesCardTypeTypeGraphqlCrudOperation = ClassTypeInterface & {
  __typename?: 'TypesCardTypeTypeGraphqlCrudOperation';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Data that the operation was performed on */
  data?: Maybe<CardType>;
  /** Operation that was performed */
  operation: CrudOperationEnum;
};

/** CRUD operation wrapper for Types::InvitationType */
export type TypesInvitationTypeGraphqlCrudOperation = ClassTypeInterface & {
  __typename?: 'TypesInvitationTypeGraphqlCrudOperation';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Data that the operation was performed on */
  data?: Maybe<Invitation>;
  /** Operation that was performed */
  operation: CrudOperationEnum;
};

/** CRUD operation wrapper for Types::MetricDataPointType */
export type TypesMetricDataPointTypeGraphqlCrudOperation = ClassTypeInterface & {
  __typename?: 'TypesMetricDataPointTypeGraphqlCrudOperation';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Data that the operation was performed on */
  data?: Maybe<MetricDataPoint>;
  /** Operation that was performed */
  operation: CrudOperationEnum;
};

/** CRUD operation wrapper for Types::MetricSourceType */
export type TypesMetricSourceTypeGraphqlCrudOperation = ClassTypeInterface & {
  __typename?: 'TypesMetricSourceTypeGraphqlCrudOperation';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Data that the operation was performed on */
  data?: Maybe<MetricSource>;
  /** Operation that was performed */
  operation: CrudOperationEnum;
};

/** CRUD operation wrapper for Types::Unions::DomainObjectArray */
export type TypesUnionsDomainObjectArrayGraphqlCrudOperation = ClassTypeInterface & {
  __typename?: 'TypesUnionsDomainObjectArrayGraphqlCrudOperation';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** Data that the operation was performed on */
  data?: Maybe<Array<DomainObject>>;
  /** Operation that was performed */
  operation: CrudOperationEnum;
};

/** Autogenerated input type of UploadTicketGenerate */
export type UploadTicketGenerateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UploadTicketGenerate. */
export type UploadTicketGeneratePayload = ClassTypeInterface & {
  __typename?: 'UploadTicketGeneratePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ticket to use */
  ticket: Scalars['String']['output'];
};

/** A single user object */
export type User = ClassTypeInterface & {
  __typename?: 'User';
  /** User authorization list */
  abilities?: Maybe<Scalars['JSON']['output']>;
  /** Number of accounts */
  accountsCount: Scalars['Int']['output'];
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** When the user was confirmed / verified at */
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** Object creation date */
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** If applicable, date and time user was disabled at */
  disabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** User email address */
  email: Scalars['String']['output'];
  /** ID of object */
  id: Scalars['ID']['output'];
  /** Users job title */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** Users name */
  name: Scalars['String']['output'];
  /** Whether a user is opted into platform analytics */
  optedInToAnalytics?: Maybe<Scalars['Boolean']['output']>;
  /** Associated Organization */
  organization?: Maybe<Organization>;
  /** Users role */
  role?: Maybe<RoleEnum>;
  /** Uniquely search ID to locate contribution records */
  searchableContributorId?: Maybe<Scalars['String']['output']>;
  /** If we retrieved the user from a Source such as Slack */
  sourceName: Scalars['String']['output'];
  /** Internal DL Admin flag */
  superAdmin: Scalars['Boolean']['output'];
  /** Users preferred timezone */
  timeZone: Scalars['String']['output'];
  /** Object last updated date */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of UserAuthenticate */
export type UserAuthenticateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The email account to use for login */
  email: Scalars['String']['input'];
  /** The password to use for login */
  password: Scalars['String']['input'];
};

/** Autogenerated return type of UserAuthenticate. */
export type UserAuthenticatePayload = ClassTypeInterface & {
  __typename?: 'UserAuthenticatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Path to redirect to */
  redirect?: Maybe<Scalars['String']['output']>;
  /** true/false if the login was successful */
  success: Scalars['Boolean']['output'];
  /** Logged in User */
  user?: Maybe<User>;
};

/** Autogenerated input type of UserConfirmEmail */
export type UserConfirmEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserConfirmEmail. */
export type UserConfirmEmailPayload = ClassTypeInterface & {
  __typename?: 'UserConfirmEmailPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** true/false if email was sent successfully */
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UserCreate */
export type UserCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email for new account */
  email: Scalars['String']['input'];
  /** Optional invite code to join an existing organization */
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  /** Name for new account */
  name: Scalars['String']['input'];
  /** Password for new account */
  password: Scalars['String']['input'];
  /** Password confirmation for new account */
  passwordConfirmation: Scalars['String']['input'];
  /** Optional time zone for new account */
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserCreate. */
export type UserCreatePayload = ClassTypeInterface & {
  __typename?: 'UserCreatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Newly created User */
  user?: Maybe<User>;
};

/** Autogenerated input type of UserEmailCapture */
export type UserEmailCaptureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email for new account */
  email: Scalars['String']['input'];
  /** Job title for the new account */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserEmailCapture. */
export type UserEmailCapturePayload = ClassTypeInterface & {
  __typename?: 'UserEmailCapturePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Whether or not the user was created properly */
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UserResetPassword */
export type UserResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Password to set on the user */
  password: Scalars['String']['input'];
  /** Password reset token for the user */
  passwordResetToken: Scalars['String']['input'];
};

/** Autogenerated return type of UserResetPassword. */
export type UserResetPasswordPayload = ClassTypeInterface & {
  __typename?: 'UserResetPasswordPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** If the reset password was successful */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UserResetPasswordRequest */
export type UserResetPasswordRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email for new account */
  email: Scalars['String']['input'];
};

/** Autogenerated return type of UserResetPasswordRequest. */
export type UserResetPasswordRequestPayload = ClassTypeInterface & {
  __typename?: 'UserResetPasswordRequestPayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Success */
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UserUpdate */
export type UserUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** User email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User job title */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** User name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** User boolean flag for opting into analytics */
  optedInToAnalytics?: InputMaybe<Scalars['CastableBoolean']['input']>;
  /** User time zone */
  timeZone?: InputMaybe<Scalars['String']['input']>;
  /** User ID to update */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UserUpdate. */
export type UserUpdatePayload = ClassTypeInterface & {
  __typename?: 'UserUpdatePayload';
  /** The class type of the field */
  classType: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Array of Errors */
  errors: Array<Error>;
  /** Updated User */
  user?: Maybe<User>;
};
